.filteration-row {
    .input-group {
        .btn-clear {
            color: #51859a;
            border: none;
            background-color: transparent;
            width: 100%;
            height: 50px;
            padding: 0;
            border: 1px solid #51859a;
            text-align: center;
            border-radius: 9px;
            &:focus {
                outline: 0;
            }
            &:active {
                box-shadow: none;
            }
        }
    }
    .clear-row {
        @media (min-width: $screen-md-min) {
            padding-left: 7.5px;
            .rtl & {
                padding-left: 15px;
                padding-right: 7.5px;
            }
        }
    }
}

.aside-content {
    .rtl & {
        @media(min-width: $screen-md-min) {
            width: 285px!important;
        }
        @media(max-width: 1024px) {
            width: 242.5px!important;
        }
        @media(max-width: $screen-sm-max) {
            width: 100%!important;
        }
    }
    .for-inq {
        @media (max-width: $screen-xs-max) {
            padding: 30px 25px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            margin: auto auto 0 auto;
        }
        h4 {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
        img {
            // display: block;
            max-width: 100%;
            height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    .most-download {
        margin-top: 30px;
        .most-download-box {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: $screen-sm-max) {
                margin-bottom: 0;
            }
            .most-download-media {
                position: relative;
                cursor: pointer;
                .most-download-img {
                    display: block;
                    max-width: 100%;
                }
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform(translate(-50%, -50%));
                    overflow: visible;
                    .firefox & {
                        top: 70%;
                        left: 70%;
                    }
                }
            }
        }
        .most-download-title {
            margin-top: 0;
            font-size: 32px;
            font-weight: $weight-medium;
            color: #6a676a;
            @media (max-width: $screen-sm-max) {
                margin-bottom: 25px;
            }
        }
        .block-options {
            margin-left: 3px;
            .rtl & {
                margin-left: 0;
                margin-right: 3px;
            }
            @media (max-width: $screen-sm-max) {
                display: block;
            }
            .svg-wraper {
                margin-left: -3px;
                display: inline-block;
                padding: 0 30px;
                border-right: 2px solid $light-grey;
                .rtl & {
                    margin-left: 0;
                    border-right: none;
                }
                &:nth-child(1) {
                    border-right: 2px solid #dcdcdc;
                    border-left: none;
                    padding-left: 0;
                    .rtl & {
                        border-left: 2px solid #dcdcdc;
                        border-right: none;
                        padding-left: 30px;
                        padding-right: 0;
                    }
                    @media (max-width: $screen-sm-max) {
                        display: none;
                    }
                }
                &:nth-child(2) {
                    border-right: none;
                    border-left: none;
                    @media (max-width: $screen-sm-max) {
                        padding-left: 4px;
                        .rtl & {
                            padding-right: 0;
                            padding-left: 30px;
                        }
                    }
                }
                &:nth-child(3) {
                    height: 31px;
                    vertical-align: bottom;
                    border-right: none;
                    border-left: 2px solid #dcdcdc;
                    padding-right: 0;
                    .rtl & {
                        border-left: none;
                        border-right: 2px solid #dcdcdc;
                        padding-right: 30px;
                        padding-left: 0;
                    }
                }
                svg {
                    margin-top: 28%;
                }
                .social_share {
                    margin-top: 4px;
                    display: inline-block;
                }
            }
        }
        .most-download-text {
            margin: 0;
            font-size: 24px;
            font-weight: $weight-regular;
            color: $dark-smokey-grey;
            @media (max-width: $screen-sm-max) {
                margin-bottom: 15px;
                ine-height: 1.1;
            }
        }
        .url {
            display: inline-block;
            margin: 10px 0 15px 0;
            color: #51859a;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.ajax-load-more-wrap {
    &.more-border-top {
        border-top: 1px solid $light-grey;
        margin-top: 5px;
        padding-top: 50px;
    }
    .alm-listing.alm-ajax {
        margin-left: 0;
        margin-right: 0;
    }
    .alm-reveal {
        width: 100%;
    }
    .alm-btn-wrap {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    .more-button {
        width: 108px;
        height: 54px;
        color: $med-grey;
        border: 1px solid $med-grey;
        font-weight: $weight-medium;
        // @extend .font-xs;
        @include font-size($font-xs, $font-xs-resp);
        display: block;
        margin: 50px auto 5px;
        outline: none;
        background: none;
    }
    button.done {
        display: none;
    }
}

.show-more-text {
    text-align: center;
    margin-top: 50px;
    p {
        font-size: 16px;
        font-weight: 400;
    }
    h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
    }
    .p1-style {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
    }
}

.modal.most-download-modal {
    padding-right: 0!important;
    .modal-dialog {
        padding: 50px;
        margin-top: 5%;
        box-shadow: 0px 1px 10px 0px;
        background-color: $white;
        @media (max-width: $screen-sm-max) {
            margin-top: 15%;
        }
        @media (max-width: $screen-xs-max) {
            padding: 35px 15px;
            margin-top: 30%;
        }
        .modal-content {
            margin: auto;
            border: none;
            box-shadow: none;
            .close {
                opacity: 1;
                position: absolute;
                top: -40px;
                right: -30px;
                left: auto;
                &:hover {
                    opacity: 1;
                }
                &:focus {
                    outline: 0;
                }
                span {
                    font-size: 35px;
                    font-weight: 500;
                    color: $dark-moderate-blue;
                    &:focus {
                        outline: 0;
                    }
                }
                @media (max-width: $screen-xs-max) {
                    right: -5px
                }
            }
            .modal-body {
                padding: 0;
                .embed-video {
                    padding-bottom: 56.25%;
                }
                .embed-responsive .embed-responsive-item,
                .embed-responsive embed,
                .embed-responsive iframe,
                .embed-responsive object,
                .embed-responsive video {
                    top: 0!important;
                }
                .item {
                    .media-img {
                        // position: absolute;
                        top: 0;
                        z-index: 1;
                    }
                    .details {
                        h3 {
                            font-weight: 500;
                            font-size: 20px;
                            padding-bottom: 25px;
                            margin: 25px 0 20px;
                            color: $dark-moderate-blue;
                            border-bottom: 1px solid $light-grey;
                            @media (max-width: $screen-xs-max) {
                                font-size: 18px;
                            }
                        }
                        p {
                            font-size: 16px;
                            margin-bottom: 0;
                            margin-top: 20px;
                        }
                    }
                    img {
                        max-width: 100%;
                    }
                    .block-options {
                        padding-top: 0;
                        padding-bottom: 25px;
                        border-bottom: 1px solid $light-grey;
                        @media (max-width: $screen-sm-max) {
                            display: block;
                        }
                        .svg-wraper {
                            &:nth-child(1) {
                                border-right: 2px solid #dcdcdc;
                                border-left: none;
                                .rtl & {
                                    border-left: 2px solid #dcdcdc;
                                    border-right: none;
                                }
                                @media (max-width: $screen-sm-max) {
                                    display: none;
                                }
                            }
                            &:nth-child(2) {
                                border-right: none;
                                border-left: none;
                                @media (max-width: $screen-sm-max) {
                                    padding-left: 4px;
                                    .rtl & {
                                        padding-right: 0;
                                        padding-left: 30px;
                                    }
                                }
                            }
                            &:nth-child(3) {
                                height: 34px;
                                vertical-align: bottom;
                                border-right: none;
                                border-left: 2px solid #dcdcdc;
                                .rtl & {
                                    border-left: none;
                                    border-right: 2px solid #dcdcdc;
                                }
                            }
                            .social_share {
                                margin-top: 13px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    h4 {
        font-size: 32px;
        font-weight: 100;
        line-height: 1.2;
    }
    img {
        // display: block;
        max-width: 100%;
        height: 100%;
        // width: 100%;
        &.alignleft {
            float: left;
            padding-right: 15px;
        }
        &.alignright {
            float: right;
            padding-left: 15px;
        }
        &.aligncenter {
            display: block;
            margin: auto;
        }
        &.alignnone {
            float: none;
        }
    }
    figure {
        &.alignleft {
            float: left;
            padding-right: 15px;
        }
        &.alignright {
            float: right;
            padding-left: 15px;
        }
        &.aligncenter {
            display: block;
            margin: auto;
        }
        &.alignnone {
            float: none;
        }
    }
    ol {
        padding: 0;
        margin: 20px 0;
        counter-reset: olcounter;
        li {
            // list-style-type: lower-roman;
            list-style-position: inside;
            list-style: none; //
            color: $dark-smokey-grey;
            line-height: 1.2;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 25px;
            width: 100%;
            position: relative;
            &:before {
                content: counter(olcounter, lower-roman)".";
                counter-increment: olcounter;
                display: block;
                position: absolute;
                left: 0;
                .rtl & {
                    margin-left: 20px;
                    margin-right: 0;
                    left: auto;
                    right: 0;
                }
            }
            // &:before {
            //  content: "";
            //  width: 10px;
            //  display: inline-block;
            // }
            ol {
                padding: 0 0 0 15px;
                margin: 20px 0;
                li {
                    // list-style-type: lower-alpha;
                }
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
        &[type=a] {
            li {
                &::before {
                    content: counter(olcounter, lower-alpha)".";
                }
            }
        }
    }
    ul {
        margin: 20px 0;
        padding: 0px;
        li {
            background: url(../images/bullet-icon.png) no-repeat 0 8px;
            list-style: none;
            margin-bottom: 3px;
            color: $dark-smokey-grey;
            line-height: 1.5;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 25px;
            width: 100%;
            position: relative;
            &:last-child {
                margin-bottom: 0px;
            }
            .rtl & {
                background-position: 100% 8px;
            }
            ul {
                padding: 0 0 0 15px;
                margin: 20px 0;
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
    }
    a {
        font-weight: $weight-medium;
        color: $dark-moderate-blue;
    }
    .p1-style {
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
    }
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.video-wide.media-overview-video.media-listing {
    display: block;
}

.modal {
    .media-img {
        svg {
            &:focus,
            &:hover {
                cursor: pointer;
            }
            .firefox & {
                top: 60%;
                left: 60%;
            }
        }
    }
}

.media-left {
    a {
        opacity: 1;
    }
}

.checked-text {
    display: none;
    float: right;
    font-size: 20px;
    font-weight: 400;
    margin-top: 5px;
    color: $dark-moderate-blue;
    .rtl & {
        float: left;
    }
    img {
        margin-right: 10px;
        .rtl & {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

.media-listing {
    &.awards {
        .alm-reveal {
            display: block;
        }
    }
}

.related-media {
    .related-resp-slider {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        @media(max-width: $screen-xs-max) {
            .slick-list {
                height: 200px;
            }
            .media-img {
                img {
                    height: 160px;
                }
            }
            .block-options {
                position: absolute;
            }
        }
    }
}