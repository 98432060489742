  .contact-form {
      padding: 44px 0 54px;
      background-color: #dfe0e2;
      .wpcf7 {
          @extend .col-xs-12;
          @extend .col-md-10;
          body:not(.rtl) & {
              @extend .col-md-offset-2;
              @extend .col-md-pull-2;
          }
          .rtl & {
              // @extend .col-md-push-2;
              direction: rtl;
          }
          float: none;
          form {
              @extend .row;
              display: flex;
              flex-wrap: wrap;
              br {
                  display: none;
              }
              >p {
                  @extend .col-xs-12;
                  @extend .col-sm-6;
                  display: flex;
                  flex-direction: column;
                  &:nth-of-type(7) {
                      @extend .col-sm-12;
                  }
                  &:nth-of-type(8) {
                      flex-direction: row;
                      // @media (min-width: $screen-xs-min) {
                      margin-top: 29px;
                      // }
                      img {
                          height: 51px;
                          width: 40%;
                          float: left;
                          padding: 0px;
                          display: block;
                          .rtl & {
                              float: right;
                          }
                      }
                      // span {
                      // width: 60%;
                      // float: left;
                      // padding: 0;
                      // display: block;
                      // .rtl & {
                      //     float: right;
                      // }
                      // }
                  }
                  &:last-child {
                      .ajax-loader {
                          display: none !important;
                      }
                  }
                  label {
                      font-weight: $weight-bold;
                      @include font-size($font-sm, $font-sm-resp);
                      color: $salt-box-grey;
                      margin-bottom: 9px;
                      margin-top: 10px;
                  }
                  .select2 {
                      .rtl & {
                          direction: rtl;
                      }
                  }
                  input,
                  select,
                  textarea,
                  .select2-selection {
                      padding-left: 25px;
                      width: 100%;
                      border: none;
                      outline: none;
                      border-radius: 0;
                      box-shadow: none;
                      background-color: $white;
                      @include font-size(11px, $font-sm-resp);
                      .rtl & {
                          padding-left: 0;
                          direction: rtl;
                          padding-right: 25px;
                      }
                  }
                  input,
                  select,
                  .select2-selection {
                      height: 51px;
                      position: relative;
                  }
                  .select2-selection {
                      padding-top: 11px;
                  }
                  textarea {
                      padding-top: 20px;
                      height: 152px;
                      resize: none;
                  }
                  input[type=submit] {
                      background-color: $moderate-blue;
                      border-radius: 10px;
                      color: $white;
                      font-weight: $weight-bold;
                      font-size: 20px;
                      line-height: 0;
                      // @media (min-width: $screen-xs-min) {
                      margin-top: 29px;
                      // }
                  }
                  .select2-selection__rendered {
                      padding: 0;
                  }
                  .select2-selection__arrow {
                      @extend .select-arrow
                  }
              }
              .wpcf7-not-valid-tip {
                  line-height: 3;
              }
              .wpcf7-form-control-wrap.message {
                  .wpcf7-not-valid-tip {
                      line-height: 2.4;
                  }
              }
          }
      }
      .wpcf7-response-output,
      .ajax-loader {
          display: none !important;
      }
      .select-arrow {
          position: absolute;
          width: 6px;
          height: 12px;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 25px;
          background: url($lightGrey-arrow) no-repeat;
          background-size: auto 100%;
          .rtl & {
              right: auto;
              left: 25px;
              @include transform(rotate(180deg));
          }
          .select2-container--open & {
              @include transform(rotate(90deg));
              .rtl & {
                  @include transform(rotate(90deg));
              }
          }
          b {
              display: none;
          }
      }
      .contact-response {
          width: 100%;
          display: none;
          @include font-size($font-md, $font-md-resp);
          text-align: center;
          margin: 60px auto 245px;
      }
      .temp-container {
          position: relative;
          display: block;
          width: 100%;
          input.temp {
              padding-right: 45px;
          }
          .remove {
              position: absolute;
              right: 25px;
              height: 17px;
              cursor: pointer;
              top: 50%;
              @include transform(translateY(-50%));
          }
          .rtl & {
              // padding-left: 30px;
              padding-right: 0;
              .remove {
                  right: auto;
                  left: 25px;
              }
          }
      }
       ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $dark-smokey-grey;
      }
       ::-moz-placeholder {
          /* Firefox 19+ */
          color: $dark-smokey-grey;
      }
       :-ms-input-placeholder {
          /* IE 10+ */
          color: $dark-smokey-grey;
      }
       :-moz-placeholder {
          /* Firefox 18- */
          color: $dark-smokey-grey;
      }
      div.wpcf7 .screen-reader-response {
          clip: auto;
          height: auto;
          width: auto;
          font-size: 24px;
          text-align: center;
          position: static;
          // @media (max-width: $screen-sm-max) {
          //   margin: 0 15px;
          // }
          ul {
              display: none;
          }
      }
      .screen-reader-response {
          display: none;
      }
  }
  
  .contact-form-white {
      padding: 44px 0 54px;
      background-color: $white;
      .wpcf7 {
          @extend .col-xs-12;
          @extend .col-md-10;
          body:not(.rtl) & {
              @extend .col-md-offset-2;
              @extend .col-md-pull-2;
          }
          .rtl & {
              // @extend .col-md-push-2;
              direction: rtl;
          }
          float: none;
          form {
              @extend .row;
              display: flex;
              flex-wrap: wrap;
              br {
                  display: none;
              }
              >p {
                  @extend .col-xs-12;
                  @extend .col-sm-6;
                  display: flex;
                  flex-direction: column;
                  &:nth-of-type(9) {
                      @extend .col-sm-12;
                  }
                  &:nth-of-type(10) {
                      flex-direction: row;
                      // @media (min-width: $screen-xs-min) {
                      margin-top: 29px;
                      // }
                      img {
                          height: 51px;
                          width: 40%;
                          float: left;
                          padding: 0px;
                          display: block;
                          .rtl & {
                              float: right;
                          }
                      }
                      // span {
                      // width: 60%;
                      // float: left;
                      // padding: 0;
                      // display: block;
                      // .rtl & {
                      //     float: right;
                      // }
                      // }
                  }
                  &:last-child {
                      .ajax-loader {
                          display: none !important;
                      }
                  }
                  label {
                      font-weight: $weight-bold;
                      @include font-size($font-sm, $font-sm-resp);
                      color: $salt-box-grey;
                      margin-bottom: 9px;
                      margin-top: 10px;
                  }
                  .select2 {
                      .rtl & {
                          direction: rtl;
                      }
                  }
                  input,
                  select,
                  textarea,
                  .select2-selection {
                      padding-left: 25px;
                      width: 100%;
                      border: 1px solid $light-grey;
                      outline: none;
                      border-radius: 0;
                      box-shadow: none;
                      background-color: $white;
                      @include font-size($font-sm, $font-sm-resp);
                      .rtl & {
                          padding-left: 0;
                          direction: rtl;
                          padding-right: 25px;
                      }
                  }
                  input,
                  select,
                  .select2-selection {
                      height: 51px;
                      position: relative;
                  }
                  .select2-selection {
                      padding-top: 11px;
                  }
                  textarea {
                      padding-top: 20px;
                      height: 152px;
                      resize: none;
                  }
                  input[type=submit] {
                      background-color: $moderate-blue;
                      border-radius: 10px;
                      color: $white;
                      font-weight: $weight-bold;
                      font-size: 20px;
                      line-height: 0;
                      // @media (min-width: $screen-xs-min) {
                      margin-top: 29px;
                      // }
                  }
                  .select2-selection__rendered {
                      padding: 0;
                  }
                  .select2-selection__arrow {
                      @extend .select-arrow
                  }
              }
              .wpcf7-not-valid-tip {
                  line-height: 3;
              }
              .wpcf7-form-control-wrap.message {
                  .wpcf7-not-valid-tip {
                      line-height: 2.4;
                  }
              }
          }
      }
      .wpcf7-response-output,
      .ajax-loader {
          display: none !important;
      }
      .select-arrow {
          position: absolute;
          width: 6px;
          height: 12px;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 25px;
          background: url($lightGrey-arrow) no-repeat;
          background-size: auto 100%;
          .rtl & {
              right: auto;
              left: 25px;
              @include transform(rotate(180deg));
          }
          .select2-container--open & {
              @include transform(rotate(90deg));
              .rtl & {
                  @include transform(rotate(90deg));
              }
          }
          b {
              display: none;
          }
      }
      .contact-response {
          width: 100%;
          display: none;
          @include font-size($font-md, $font-md-resp);
          text-align: center;
          margin: 60px auto 245px;
      }
      .temp-container {
          position: relative;
          display: block;
          width: 100%;
          input.temp {
              padding-right: 45px;
          }
          .remove {
              position: absolute;
              right: 25px;
              height: 17px;
              cursor: pointer;
              top: 50%;
              @include transform(translateY(-50%));
          }
          .rtl & {
              padding-left: 30px;
              padding-right: 0;
              .remove {
                  right: auto;
                  left: 0px;
              }
          }
      }
       ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $dark-smokey-grey;
      }
       ::-moz-placeholder {
          /* Firefox 19+ */
          color: $dark-smokey-grey;
      }
       :-ms-input-placeholder {
          /* IE 10+ */
          color: $dark-smokey-grey;
      }
       :-moz-placeholder {
          /* Firefox 18- */
          color: $dark-smokey-grey;
      }
      div.wpcf7 .screen-reader-response {
          clip: auto;
          height: auto;
          width: auto;
          font-size: 24px;
          text-align: center;
          position: static;
          // @media (max-width: $screen-sm-max) {
          //   margin: 0 15px;
          // }
          ul {
              display: none;
          }
      }
  }
  
  @media (min-width: 768px) {
      .contact-form-white .wpcf7 form>p:nth-of-type(10),
      .contact-form .wpcf7 form>p:nth-of-type(7) {
          width: 50%;
      }
  }
  
  .select2.select2-container.select2-container--default.select2-container--focus {
      width: 100%;
  }
  
  .contact-form .wpcf7 form>p:nth-of-type(8) {
      width: 100%;
      margin-top: 0px;
      display: block;
  }
  
  .wpcf7-form-control.wpcf7-captchac.wpcf7-captcha-captcha-1 {
      height: 51px;
      width: 40%;
      float: left;
      padding: 0px;
      display: block;
  }
  
  .contact-form .wpcf7 form>p:nth-of-type(9) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      margin-top: 29px;
  }