.article-container {
    padding-top: 50px;
    padding-bottom: 40px;
    position: relative;
    @media (max-width: $screen-sm-max) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .article-content {
        h1 {
            font-weight: $weight-light;
            // @extend .font-hg;
            @include font-size($font-hg, $font-hg-resp);
            color: $salt-box-grey;
            margin-bottom: 20px;
            line-height: 1;
            @media (max-width: $screen-xs-max) {
                font-size: 30px;
            }
            .ja_lang & {
                font-weight: $weight-regular;
                line-height: 1.1;
                margin-bottom: 50px;
            }
            .ch_lang & {
                margin-bottom: 50px;
                // font-weight: $weight-thin;
            }
            .rtl & {
                margin-bottom: 38px;
            }
        }
        .date {
            @include font-size($font-sm, $font-sm-resp);
            color: $salt-box-grey;
            @media (max-width: $screen-xs-max) {
                font-size: 12px;
            }
            .date-location,
            .date-number {
                display: block;
            }
            .separator {
                margin: 0 10px;
            }
        }
        .options {
            text-align: right;
            @media (max-width: $screen-xs-max) {
                text-align: center;
                margin: 30px auto 20px -20px;
                width: auto;
                display: inline-block;
                .rtl & {
                    margin: 30px -20px 20px auto;
                }
            }
            .rtl & {
                text-align: left;
                @media (max-width: $screen-xs-max) {
                    text-align: center;
                }
            }
            .article-option {
                display: inline-block;
                float: right;
                .rtl & {
                    float: left;
                }
                &.action-option {
                    padding-top: 3px;
                    height: 25px;
                    padding-right: 20px;
                    padding-left: 20px;
                    border-right: 1px solid $light-grey;
                    &.message {
                        padding-top: 5px;
                    }
                    &.download {
                        padding-top: 4px;
                    }
                    @media (max-width: $screen-sm-max) {
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    @media (max-width: $screen-xs-max) {
                        &:first-child {
                            padding-left: 0;
                            .rtl & {
                                padding-right: 0;
                                padding-left: 20px;
                            }
                        }
                    }
                    .rtl & {
                        // padding-right:0px;
                        // margin-right: 0px;
                        border-right: none;
                        // padding-left:15px;
                        // margin-left: 20px;
                        border-left: 1px solid $light-grey;
                    }
                }
                &.share-option {
                    padding-top: 5px;
                    margin-left: 15px;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 15px;
                    }
                }
            }
            .print-icon {
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }
        }
        .cover-img {
            margin: 50px auto;
            @media (max-width: $screen-sm-max) {
                margin: 20px 0;
            }
            img {
                max-width: 100%;
            }
            &.cover-fix {
                .ie & {
                    display: inline;
                }
                .safari & {
                    display: inline;
                }
            }
        }
        .article-text {
            color: $dark-smokey-grey;
            h3 {
                // @extend .font-size-lg;
                @include font-size($font-lg, $font-lg-resp);
                font-weight: $weight-regular;
                line-height: 1.1;
            }
            h4 {
                // @extend .font-size-lg;
                @include font-size($font-lg, 21px);
                font-weight: $weight-light;
                line-height: 1.2;
                // .ch_lang & {
                //     font-weight: $weight-thin;
                // }
            }
            h5 {
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
                font-weight: $weight-regular;
                margin: 0 auto 30px auto;
            }
            p {
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                margin: 0 0 20px auto;
                // text-align: justify;
                // display: inline-block;
                &:last-child {
                    margin-bottom: 0px;
                }
                .ja_lang & {
                    line-height: 1.5;
                }
            }
            a {
                color: $dark-moderate-blue;
            }
            img {
                // display: block;
                max-width: 100%;
                height: 100%;
                // width: 100%;
                &.alignleft {
                    float: left;
                    padding-right: 15px;
                }
                &.alignright {
                    float: right;
                    padding-left: 15px;
                }
                &.aligncenter {
                    display: block;
                    margin: auto;
                }
                &.alignnone {
                    float: none;
                }
            }
            figure {
                &.alignleft {
                    float: left;
                    padding-right: 15px;
                }
                &.alignright {
                    float: right;
                    padding-left: 15px;
                }
                &.aligncenter {
                    display: block;
                    margin: auto;
                }
                &.alignnone {
                    float: none;
                }
            }
            ol {
                padding: 0;
                margin: 20px 0;
                counter-reset: olcounter;
                li {
                    // list-style-type: lower-roman;
                    list-style-position: inside;
                    list-style: none; //
                    color: $dark-smokey-grey;
                    line-height: 1.2;
                    @include font-size($font-sm, $font-sm-resp);
                    font-weight: $weight-regular;
                    display: block;
                    padding: 0 25px;
                    width: 100%;
                    position: relative;
                    &:before {
                        content: counter(olcounter, lower-roman)".";
                        counter-increment: olcounter;
                        display: block;
                        position: absolute;
                        left: 0;
                        .rtl & {
                            margin-left: 20px;
                            margin-right: 0;
                            left: auto;
                            right: 0;
                        }
                    }
                    // &:before {
                    //  content: "";
                    //  width: 10px;
                    //  display: inline-block;
                    // }
                    ol {
                        padding: 0 0 0 15px;
                        margin: 20px 0;
                        li {
                            // list-style-type: lower-alpha;
                        }
                        .rtl & {
                            padding: 0 15px 0 0;
                        }
                    }
                }
                &[type=a] {
                    li {
                        &::before {
                            content: counter(olcounter, lower-alpha)".";
                        }
                    }
                }
            }
            ul {
                margin: 20px 0;
                padding: 0px;
                li {
                    background: url(../images/bullet-icon.png) no-repeat 0 8px;
                    list-style: none;
                    margin-bottom: 3px;
                    color: $dark-smokey-grey;
                    line-height: 1.5;
                    @include font-size($font-sm, $font-sm-resp);
                    font-weight: $weight-regular;
                    display: block;
                    padding: 0 25px;
                    width: 100%;
                    position: relative;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    .rtl & {
                        background-position: 100% 8px;
                    }
                    ul {
                        padding: 0 0 0 15px;
                        margin: 20px 0;
                        .rtl & {
                            padding: 0 15px 0 0;
                        }
                    }
                }
            }
        }
    }
    .related-media {
        >h3 {
            margin: 50px 0 25px;
        }
        .media-block {
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
            .block-options {
                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }
            .details {
                position: relative;
                h3 {
                    font-size: $font-sm;
                    @media (max-width: $screen-sm-max) {
                        font-size: 20px;
                    }
                    @media (max-width: $screen-xs-max) {
                        margin: 0;
                        color: $white;
                        position: absolute;
                        bottom: 15px;
                        z-index: 10;
                        left: 10px;
                        line-height: 0.8;
                        right: 10px;
                        .rtl & {
                            line-height: 1;
                        }
                        a {
                            font-size: 15px;
                            color: $white;
                        }
                    }
                }
            }
            @media (max-width: $screen-xs-max) {
                &::after {
                    content: "";
                    top: 50%;
                    bottom: 0;
                    left: 0px;
                    right: 0px;
                    display: block;
                    position: absolute;
                    z-index: 1;
                    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
                }
            }
            @media (max-width: $screen-xs-max) {
                &::after {
                    left: 0px;
                    right: 0px;
                }
            }
            &:focus {
                outline: 0;
            }
        }
    }
    .press-kit {
        @media (min-width: $screen-md-min) {
            margin-top: 30px;
        }
    }
    .for-inq {
        @media (max-width: $screen-xs-max) {
            order: 2;
        }
    }
    .related-articles {
        @media (max-width: $screen-xs-max) {
            order: 1;
        }
        >h3 {
            margin: 50px 0 20px;
            // @media (max-width: $screen-xs-max) {
            //     margin: 500 20px;
            // }
        }
        .article-img {
            width: 100%;
            overflow: hidden;
            @media (max-width: $screen-xs-max) {
                &:not(.no-img)::after {
                    content: "";
                    top: 50%;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: block;
                    position: absolute;
                    z-index: 1;
                    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
                }
            }
            @media (max-width: $screen-xs-max) {
                &:not(.no-img)::after {
                    left: 0;
                    right: 0;
                }
            }
            img {
                width: 100%;
                @media (max-width: $screen-sm-max) {
                    height: 184px;
                }
                @media (max-width: $screen-xs-max) {
                    height: 200px;
                }
            }
        }
        .related-article-box {
            // position: relative;
            margin-bottom: 25px;
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
            p {
                color: $dark-smokey-grey;
                margin-top: 18px;
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
                line-height: 1.2;
                @media (max-width: $screen-xs-max) {
                    position: absolute;
                    z-index: 10;
                    bottom: 15px;
                    left: 10px;
                    line-height: 1;
                    right: 10px;
                    font-size: 15px;
                    color: $white;
                    a {
                        font-size: 15px;
                        color: $white;
                    }
                }
                @media (max-width: $screen-xs-max) {
                    left: 10px;
                    right: 10px;
                }
            }
            &.no-img {
                p {
                    @media (max-width: $screen-xs-max) {
                        position: relative;
                        z-index: 10;
                        bottom: 0px;
                        left: 0px;
                        line-height: 1.2;
                        right: 0px;
                        font-size: 15px;
                        padding: 20px;
                        color: $dark-smokey-grey;
                        background: $white;
                        a {
                            font-size: 15px;
                            color: $dark-smokey-grey;
                        }
                    }
                }
                .article-img {
                    &:after {
                        background: $white;
                    }
                }
            }
            a.read-more {
                color: $dark-moderate-blue;
                font-weight: $weight-medium;
                // .ch_lang & {
                //     font-weight: 400;
                // }
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }
            &:focus {
                outline: 0;
            }
        }
        // .related-resp-slider {
        // @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        //     display: flex;
        //     flex-wrap: wrap;
        //     .related-article-box {
        //         display: flex;
        //         flex-direction: column;
        //     }
        // }
        // }
    }
}