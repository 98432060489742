.related-resp-slider {
    @media (max-width: $screen-sm-max) {
        .slick-arrow {
            position: absolute;
            top: 35%;
            margin: auto;
            z-index: 20;
            cursor: pointer;
            @include transform(translateY(-50%));
            &.map-slider-arrow-prev {
                left: -10px;
                .rtl & {
                    right: -10px;
                    left: auto;
                    @include transform(translateY(-50%) rotate(180deg));
                }
            }
            &.map-slider-arrow-next {
                right: -10px;
                .rtl & {
                    left: -10px;
                    right: auto;
                    @include transform(translateY(-50%) rotate(180deg));
                }
            }
        }
    }
    @media (max-width: 480px) {
        margin: 0 20px;
        .slick-arrow {
            top: 40%;
            &.map-slider-arrow-prev {
                left: -20px;
                .rtl & {
                    right: -20px;
                }
            }
            &.map-slider-arrow-next {
                right: -20px;
                .rtl & {
                    left: -20px;
                }
            }
        }
    }
}