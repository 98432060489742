.media-listing-container {
    padding: 54px 0;
    position: relative;
    .alm-btn-wrap {
        border-top: 1px solid $light-grey;
    }
    .alm-listing {
        @media (max-width: $screen-xs-max) {
            width: auto;
        }
        .alm-reveal {
            display: flex;
            flex-wrap: wrap;
            // .safari & {
            //     display: block;
            // }
        }
    }
}

.media-block {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: $screen-xs-max) {
        padding: 0px 5px;
    }
    .media-img {
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            @media (max-width: $screen-xs-max) {
                height: 200px;
            }
            // position: relative;
            // left: 50%;
            // @include transform(translateX(-50%));
        }
    }
    .details {
        h3 {
            font-size: $font-sm;
            color: $dark-smokey-grey;
            font-weight: $weight-regular;
            line-height: 1.2;
            @media (max-width: $screen-sm-max) {
                font-size: $font-sm-resp;
            }
        }
        .block-options {
            padding-top: 10px;
            @media (max-width: $screen-sm-max) {
                display: none;
            }
            .svg-wraper {
                margin-left: -3px;
                display: inline-block;
                padding: 0 30px;
                border-right: 2px solid $light-grey;
                max-height: 35px;
                .rtl & {
                    margin-left: 0;
                    border-right: none;
                }
                &:last-child {
                    padding-right: 0;
                    border-right: none;
                    .rtl & {
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 30px;
                        border-right: 2px solid $light-grey;
                    }
                }
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    .rtl & {
                        padding-right: 0;
                        padding-left: 30px;
                    }
                }
                svg {
                    margin-top: 15%;
                }
            }
        }
    }
}