// Font family >>
@include font-face("Glyphicons Halflings", "#{$icon-font-path}glyphicons-halflings-regular");
// Karbon Fonts >>
@include font-face("Karbon", "#{$icon-font-path}Karbon-Light", 100);
@include font-face("Karbon", "#{$icon-font-path}Karbon-Thin", 200);
@include font-face("Karbon", "#{$icon-font-path}Karbon-Regular", normal);
@include font-face("Karbon", "#{$icon-font-path}Karbon-Medium", 500);
@include font-face("Karbon", "#{$icon-font-path}Karbon-Semibold", 700);
@include font-face("Karbon", "#{$icon-font-path}Karbon-Semibold", bold);
// Luma Fonts >>
@include font-face("Luma", "#{$icon-font-path}Luma-Light", 100);
@include font-face("Luma", "#{$icon-font-path}Luma-Thin", 200);
@include font-face("Luma", "#{$icon-font-path}Luma-Regular", normal);
@include font-face("Luma", "#{$icon-font-path}Luma-SemiBold", 500);
@include font-face("Luma", "#{$icon-font-path}Luma-SemiBold", 700);
@include font-face("Luma", "#{$icon-font-path}Luma-SemiBold", bold);
// MFYueHei Fonts (chinese) >>
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-UltLight", 100);
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-ExLight", 200);
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-Regular", normal);
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-Regular", 500);
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-Regular", 700);
@include font-face("MFYueHei", "#{$icon-font-path}MFYueHei-Regular", bold);
// AxisStd Fonts (Japanese) >>
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-UltraLight", 100);
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-ExtraLight", 200);
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-Light", normal);
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-Light", 500);
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-Light", 700);
@include font-face("AxisStd", "#{$icon-font-path}AxisStd-Light", bold);
// Typography Variables >>
// Font Sizes
$font-xs: 14px;
$font-sm: 16px;
$font-md: 24px;
$font-lg: 32px;
$font-xl: 40px;
$font-hg: 64px;
// Font Sizes Resposive
$font-xs-resp: 12px;
$font-sm-resp: 15px;
$font-md-resp: 18px;
$font-lg-resp: 26px;
$font-xl-resp: 33px;
$font-hg-resp: 52px;
// Font Weights
$weight-light: 100;
$weight-thin: 200;
$weight-regular: normal;
$weight-medium: 500;
$weight-bold: bold;
// Classes
/// Arabic font >>
.luma {
    font-family: "Luma";
}

/// Japanese font >>
.axisStd {
    font-family: "AxisStd";
}

/// Chinese font >>
.mfyueHei {
    font-family: "MFYueHei";
}

.section-heading {
    font-size: $font-lg;
    font-weight: $weight-medium;
}

.lead-paragraph {
    font-size: $font-md;
    font-weight: $weight-regular;
}

.default-paragraph {
    font-size: $font-sm;
    font-weight: $weight-regular;
}

.text-section-url {
    font-size: $font-sm;
    font-weight: $weight-medium;
}