/// Generic section properties >>>
.investment-section {
    position: relative;
    width: 100%;
    /// Generic transparent button >>>
    overflow: hidden;
    .btn-transparent {
        width: 35%;
        min-width: 235px;
        height: 39px;
        padding: 9px 60px;
        color: $white;
        border-color: $white;
        border-radius: 0;
        background-color: transparent;
        font-weight: $weight-medium;
        // .ch_lang & {
        //     font-weight: 400;
        // }
        &:focus {
            outline: 0;
        }
        @media only screen and (max-width: 1300px) {
            width: 45%;
        }
    }
    img {
        @media (max-width: $screen-xs-max) {
            left: 0;
            @include transform(translateX(-50%));
        }
    }
    .investment-wraper {
        padding-top: 90px;
        padding-bottom: 90px;
        color: $white;
        @media (max-width: $screen-xs-max) {
            // height: 430px;
            padding: 45px 12px;
        }
        h3 {
            @extend .clear-margin;
            font-weight: $weight-medium;
            font-size: $font-lg;
            .ja_lang & {
                font-weight: $weight-light;
            }
            // .ch_lang & {
            //     font-weight: $weight-thin;
            // }
        }
        p {
            margin: 0 0 45px;
            // padding-right: 60px;
            position: relative;
            font-weight: $weight-regular;
            font-size: $font-sm;
            line-height: 1.2;
            @media (max-width: $screen-xs-max) {
                padding: 25px 0px;
                margin: 0 0 25px;
            }
            .rtl & {
                padding-right: 0;
                padding-left: 0;
            }
            &:after {
                background-color: $white;
                position: absolute;
                content: "";
                height: 1px;
                bottom: 0;
            }
            .ch_lang & {
                line-height: 1.45;
            }
        }
    }
    .no-opacity {
        opacity: 0;
    }
}

/// Visible main section >>>
.main-investment-section {
    @include transition(opacity 1.5s ease);
    position: relative;
    z-index: 10;
    opacity: 1;
    img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        // animation: zoombackground 13s ease 0s infinite alternate; 
    }
    p {
        padding: 30px 40px;
        @media (max-width: $screen-xs-max) {
            padding: 25px 0;
        }
        &:after {
            width: 15%;
            left: 42%;
        }
    }
    @media only screen and (max-width: $screen-sm-max) {
        .btn-transparent {
            width: 60%;
        }
    }
    /// Custom media query >>>
    @media only screen and (max-width: $screen-xs-max) {
        .btn-transparent {
            width: 100%;
            margin: 10px 0;
        }
    }
}

/// Hidden section >>>
.details-investment-section {
    // display: none;
    opacity: 1;
    z-index: 5;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    @include transition(opacity 1.5s ease);
    @media only screen and (max-width: $screen-sm-max) {
        text-align: center;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 100%;
        min-height: 100%;
        @media (max-width: $screen-xs-max) {
            left: 50%;
            @include transform(translateX(-50%));
        }
    }
    .investment-wraper {
        @media (max-width: $screen-xs-max) {
            padding: 70px 12px 45px;
        }
    }
    p {
        padding: 30px 0;
        @media (max-width: $screen-xs-max) {
            padding: 25px 0;
        }
        &:after {
            width: 15%;
            left: 0;
            .rtl & {
                right: 0;
            }
        }
        @media only screen and (max-width: $screen-sm-max) {
            padding: 30px;
            &:after {
                width: 15%;
                left: 42%;
                .rtl & {
                    right: 43%;
                }
            }
        }
    }
    .btn-transparent {
        @media only screen and (max-width: $screen-xs-max) {
            width: 80%;
        }
    }
    /// Go back link >>>
    .btn-back {
        position: absolute;
        top: 8%;
        left: 6%;
        font-size: $font-xs;
        font-weight: $weight-medium;
        color: $white;
        z-index: 10;
        .rtl & {
            right: 6%;
            left: auto;
            svg {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
                margin-left: 12px;
            }
        }
        // .ch_lang & {
        //     font-weight: 400;
        // }
        span {
            margin: 0 0 0 12px;
            .rtl & {
                margin: 0 12px 0 0;
            }
        }
    }
}