.bar-slider {
    background-color: $off-white;
    padding-top: 70px;
    padding-bottom: 70px;
    color: $dark-smokey-grey;
    @media (max-width: $screen-sm-max) {
        padding: 30px 15px 50px;
    }
    @media (max-width: $screen-xs-max) {
        padding: 20px 12px 40px;
    }
    .description {
        padding: 0 15px;
        @media (min-width: $screen-md-min) {
            // margin-left: 20%;
            // margin-top: 30px;
            margin-bottom: 20px;
            .rtl & {
                margin-left: 0;
                // margin-right: 20%;
            }
        }
        @media (max-width: $screen-sm-max) {
            width: 90%;
            max-width: 750px;
            margin: 10px 0 30px;
        }
        h2 {
            font-size: $font-lg;
            font-weight: $weight-medium;
            margin-bottom: 20px;
            margin-top: 0;
            .ja_lang & {
                font-weight: $weight-regular;
            }
            // .ch_lang & {
            //     font-weight: 400;
            // }
        }
        p {
            font-size: $font-md;
            font-weight: $weight-regular;
            line-height: 1.2;
            margin-bottom: 20px;
        }
        a {
            font-size: $font-sm;
            font-weight: $weight-bold;
            color: $light-moderate-blue;
        }
    }
    // p.item-year {
    //   font-size: $font-xl;
    //   font-weight: $weight-light;
    //   margin-top: 0;
    // }
    img {
        width: 100%;
        min-height: 100%;
        .safari & {
            min-height: 0;
        }
        @media (max-width: $screen-xs-max) {
            min-height: 0;
            max-height: 100%;
            height: 250px;
        }
    }
    .item-desc {
        background-color: #fff;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        max-height: 62px;
        @include transition(2s);
        p {
            font-size: $font-xs;
            line-height: 1;
            margin: 24px 20px;
        }
    }
    .year {
        position: relative;
        top: -30px;
        right: 8px;
        width: 30px;
        font-size: $font-sm;
        font-weight: $weight-bold;
        text-align: center;
        .rtl & {
            left: 8px;
            right: auto;
        }
    }
    .prev,
    .next {
        position: absolute;
        top: 50%;
        cursor: pointer;
        @include transform(translateY(-50%));
    }
    .prev {
        left: -30%;
        .rtl & {
            left: auto;
            right: -30%;
            @include transform(rotate(180deg));
        }
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
    .next {
        right: 25px;
        .rtl & {
            right: auto;
            left: 25px;
            @include transform(rotate(180deg));
        }
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
    .disabled {
        @include opacity(0.2);
        cursor: auto;
    }
}

.timeline-slider {
    width: 100%;
    padding: 0px 7.5px;
    .slidee-item {
        margin: 0 7.5px;
        position: relative;
        @media (max-width: $screen-xs-max) {
            padding: 0
        }
        &:focus {
            outline: 0;
        }
        &:hover {
            .item-desc {
                max-height: 100%;
                overflow: visible;
            }
        }
        img {
            height: 400px;
            width: 100%;
            // min-height: 100%;
            @media (max-width: $screen-sm-max) {
                height: 350px;
            }
            @media (max-width: 320px) {
                height: 250px;
            }
        }
        p.item-year {
            font-size: 40px;
            font-weight: 300;
            @media(max-width: $screen-xs-max) {
                font-size: 24px;
            }
            .ch_lang & {
                font-size: 30px;
                // font-weight: $weight-thin;
                letter-spacing: -2px;
            }
        }
        .item-desc {
            background-color: #fff;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: auto;
            max-height: 62px;
            @include transition(2s);
        }
    }
    // .slidee-item:not(.slick-current) {
    //     @include opacity(0.3);
    //     @include transition(0.3s)
    // }
    .general-news-arrow {
        cursor: pointer;
        position: absolute;
        z-index: 9999;
        top: 50%;
        .rtl & {
            @include transform (rotate(180deg));
        }
    }
    .news-slider-arrow-next {
        right: -3.5%;
        .rtl & {
            right: auto;
            left: -3.5%;
        }
        @media (max-width: $screen-sm-max) {
            right: -4%;
            .rtl & {
                right: auto;
                left: -4%;
            }
        }
        @media (max-width: $screen-xs-max) {
            display: none!important;
        }
    }
    .news-slider-arrow-prev {
        left: -3.5%;
        .rtl & {
            right: -3.5%;
            left: auto;
        }
        @media (max-width: $screen-sm-max) {
            left: -4%;
            .rtl & {
                right: -4%;
                left: auto;
            }
        }
        @media (max-width: $screen-xs-max) {
            display: none!important;
        }
    }
    .slick-dots {
        bottom: -60px;
        @media (max-width: $screen-xs-max) {
            display: none!important;
        }
        li {
            margin: 0 10px;
            .dot-year,
            .dot-year-hover,
            .dot-year-arrow,
            .dot-year-last {
                position: absolute;
                left: -5px;
                top: -25px;
                font-size: 18px;
                font-weight: 700;
                color: $moderate-blue;
                .ch_lang & {
                    top: -20px;
                    font-size: 14px;
                    letter-spacing: 0px;
                }
            }
            .dot-year-hover {
                color: #929292;
                .ch_lang & {
                    top: -20px;
                    font-size: 14px;
                    letter-spacing: 0px;
                }
            }
            button {
                &:before {
                    font-size: 24px;
                    color: #929292;
                    opacity: 1;
                }
                &:hover {
                    &:before {
                        font-size: 64px;
                        color: #929292;
                        .ch_lang & {
                            top: -3px;
                        }
                    }
                }
                &:active {
                    &:before {
                        font-size: 64px;
                        color: $moderate-blue;
                        .ch_lang & {
                            top: -3px;
                        }
                    }
                }
                &:focus {
                    &:before {
                        font-size: 64px;
                        color: $moderate-blue;
                        .ch_lang & {
                            top: -3px;
                        }
                    }
                }
            }
        }
        .slick-active {
            button {
                &:before {
                    font-size: 64px;
                    color: $moderate-blue;
                    .ch_lang & {
                        top: -3px;
                    }
                }
                &:hover {
                    &:before {
                        color: $moderate-blue;
                        .ch_lang & {
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}