.slick-slider {
    .slick-prev {
        left: 100px;
        top: auto;
        bottom: 0;
        height: 20px;
        width: 55px;
        @media (max-width: $screen-xs-max) {
            left: 0;
        }
    }
    .slick-next {
        right: 100px;
        top: auto;
        bottom: 0;
        height: 20px;
        width: 55px;
        @media (max-width: $screen-xs-max) {
            right: 0;
        }
    }
    .slick-next:before,
    .slick-prev:before {
        font-size: 55px;
        opacity: 1;
        color: #7d7a76;
        line-height: 0.3;
        @media (max-width: $screen-xs-max) {
            font-size: 40px;
        }
    }
    .slick-item-icon {
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.header-slider {
    .slick-track {
        display: flex;
    }
    @media (max-width: $screen-sm-max) {
        .embed-responsive.embed-responsive-16by9 {
            display: none;
        }
    }
    .slick-slide {
        position: relative;
        overflow: hidden;
        display: flex;
        background-size: cover;
        background-position: center;
        height: 615px;
        // @media (max-width: 1440px) {
        // height: auto;
        // .firefox &,
        // .ie &,
        // .safari &
        // }
        @media (max-width: 1024px) {
            height: 550px;
        }
        @media (max-width: $screen-sm-max) {
            height: 465px;
        }
        .slide-wrapper {
            position: absolute;
            top: 0px;
            left: 0;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            animation: zoombackground 16s ease 3s infinite alternate;
        }
        // @media (min-width: $screen-lg-min) and ( min-height: 1024px) {
        //     height: 800px;
        // }
        // @media (min-width: 1600px) {
        //     height: 800px;
        // }
        .rtl & {
            float: right;
        }
        >img {
            display: block;
            // animation: zoombackground 16s ease 3s infinite alternate;
            @media (min-width: $screen-md-min) {
                min-width: 100%;
                min-height: 100%;
                .firefox & {
                    max-height: 100%;
                }
            }
            &.coverImg {
                display: none;
                @media (max-width: $screen-sm-max) {
                    display: block;
                }
            }
            @media (max-width: $screen-sm-max) {
                position: relative;
                left: 0;
                // transform: translateX(-50%);
                width: 100%;
                min-width: auto;
                height: 465px;
                .rtl & {
                    left: auto;
                    // right: 50%;
                    // transform: translateX(50%);
                }
            }
            @media (max-width: $screen-xs-max) {
                width: auto;
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // @include transform(translate(-50%, -50%));
                // animation: initial;
            }
        }
        >.header-video-slide {
            top: 50%;
            @include transform(translateY(-50%));
            width: 100%;
            // .firefox &,
            // .safari & {
            //     @media (min-width: $screen-md-min) {
            //         position: absolute;
            //         // min-height: 130%;
            //         // min-height: 600px;
            //     }
            //     @media (min-width: 1400px) {
            //         // min-height: 136%;
            //     }
            // }
        }
        &::after {
            content: "";
            z-index: 10;
            position: absolute;
            top: -2px;
            left: -2px;
            width: 100%;
            height: 100%;
            background: url(../images/header-slider-overlay.svg) no-repeat;
            background-size: 100% auto;
            .rtl & {
                left: auto;
                right: -2px;
                @include transform(rotateY(180deg));
            }
        }
        .slide-content-container {
            position: absolute;
            top: 125px;
            bottom: 45px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 11;
            @media (max-width: $screen-xs-max) {
                top: 80px;
                margin: auto 12px;
            }
            .slide-content {
                position: absolute;
                // top: 50%;
                // transform: translateY(-50%);
                bottom: 110px;
                padding-right: 10%;
                color: $white;
                @media (max-width: 1024px) {
                    bottom: 55px;
                }
                .rtl & {
                    padding-right: 0;
                    padding-left: 10%;
                }
                .slide-title-home {
                    @include font-size(64px, $font-xl-resp);
                    font-weight: 400;
                    line-height: 1;
                    .rtl & {
                        font-weight: 400;
                    }
                    .ja_lang & {
                        // font-weight: $weight-thin;
                        line-height: 1.25;
                    }
                    .ch_lang & {
                        line-height: 1.25;
                        @include font-size(64px, $font-xl-resp);
                    }
                    // @media(max-width: $screen-xs-max) {
                    //     font-weight: 400;
                    // }
                }
                .read-article {
                    @include font-size($font-sm, $font-sm-resp);
                    font-weight: $weight-bold;
                    padding: 25px 0px;
                    display: inline-block;
                    border-bottom: 1px solid $white;
                    a {
                        &:hover {
                            color: $white;
                            @include opacity(0.7);
                        }
                    }
                    @media (max-width: $screen-sm-max) {
                        padding: 20px 0;
                    }
                }
            }
            .slider-counter {
                margin-top: 40px;
                @media (max-width: $screen-sm-max) {
                    margin-top: 20px;
                }
                .arrow-next,
                .arrow-prev {
                    cursor: pointer;
                    display: inline-block;
                    width: 17px;
                    height: 15px;
                    @media (max-width: $screen-sm-max) {
                        width: 11px;
                        height: 9px;
                    }
                    @media (max-width: $screen-sm-max) {
                        @include transform (translateY(3px));
                    }
                    .rtl & {
                        @include transform(rotate(180deg));
                        @media (max-width: $screen-sm-max) {
                            @include transform(rotate(180deg) translateY(-8px));
                        }
                    }
                }
                .arrow-next {
                    text-align: right;
                    .rtl & {
                        text-align: left;
                    }
                }
                .counter {
                    color: $white;
                    // @extend .font-md;
                    @include font-size($font-md, $font-md-resp);
                    font-weight: $weight-regular;
                    margin: 0 10px;
                    .curr {
                        font-weight: $weight-medium;
                    }
                    .slash {
                        margin: 0px 12px;
                    }
                    .ch_lang & {
                        font-size: 18px;
                        @media (max-width: $screen-sm-max) {
                            font-size: 14px;
                        }
                    }
                    // .rtl & {
                    //     margin: 0px 10px 0 20px;
                    // }
                }
                // .rtl & {
                //     text-align: right;
                //     direction: ltr;
                // }
            }
        }
    }
    .embed-responsive .embed-responsive-item,
    .embed-responsive embed,
    .embed-responsive iframe,
    .embed-responsive object,
    .embed-responsive video {
        top: auto!important;
        .firefox & {
            top: 0 !important;
        }
    }
}