.business-slider-background {
    background-color: $pattens-blue;
}

.business-slider-container {
    padding: 0 0 50px 0;
    .business-header {
        @extend .container;
        padding-top: 50px;
        color: $dark-grey;
        font-size: $font-lg;
        font-weight: $weight-medium;
        margin-top: 0;
        margin-bottom: 0;
    }
    .business-slider {
        position: relative;
        padding: 35px 45px 0 45px;
        .slick-slide {
            text-align: center;
            padding: 0 15px;
            a {
                &:focus {
                    outline: 0;
                }
            }
            &:focus {
                outline: 0;
            }
            .item-img {
                height: 48px;
                margin: auto auto 15px auto;
                opacity: 0.9;
                filter: alpha(opacity=90); // IE 5-7
                &:hover {
                    opacity: 1;
                    filter: alpha(opacity=100); // IE 5-7
                }
                &:focus {
                    outline: 0;
                }
                @media only screen and (max-width: $screen-xs-max) {
                    height: 40px;
                }
            }
            .item-name {
                color: $light-blue;
                font-size: $font-sm;
                opacity: 0.9;
                filter: alpha(opacity=90); // IE 5-7
                &:hover {
                    opacity: 1;
                    filter: alpha(opacity=100); // IE 5-7
                }
                &:focus {
                    outline: 0;
                }
                @media only screen and (max-width: $screen-xs-max) {
                    font-size: 13px;
                }
            }
        }
        .slick-dots {
            width: unset;
        }
        .general-business-arrow {
            top: 50%;
            @include transform(translateY(-50%));
            cursor: pointer;
        }
        .business-slider-arrow-next {
            position: absolute;
            right: 0;
        }
        .business-slider-arrow-prev {
            position: absolute;
            left: 0;
        }
    }
}