.working-container {
    padding-top: 40px;
    padding-bottom: 30px;
    .working-main-title {
        padding-bottom: 40px;
        h2 {
            color: $dark-smokey-grey;
            margin: 0 0 $card-heading-spacing;
            line-height: 1.2;
            @extend .section-heading;
            @include font-size(32px, $font-lg-resp);
            .ja_lang & {
                font-weight: $weight-regular;
                margin: 0 0 33px;
            }
            .ch_lang & {
                margin: 0 0 33px;
            }
            .ie & {
                margin-bottom: 0;
            }
            @media (max-width: $screen-xs-max) {
                margin-top: 20px;
            }
        }
        h4 {
            margin-top: 0;
            @include font-size($font-md, 21px);
            font-weight: 100;
            line-height: 28px;
            // .ch_lang & {
            //     font-weight: $weight-thin;
            // }
        }
        p {
            @extend .default-paragraph;
            @include font-size($font-sm, $font-sm-resp);
            margin: 0 0 $card-paragraph-spacing;
            line-height: 20px;
            color: $dark-smokey-grey;
            &.lead {
                line-height: 1.2;
                @extend .lead-paragraph;
                @include font-size($font-md, $font-md-resp);
            }
            .ja_lang & {
                line-height: 1.25;
            }
        }
        .find-more,
        a {
            color: $dark-moderate-blue;
            display: inline-block;
            margin: 0;
            // white-space: nowrap;
            @extend .text-section-url;
            @include font-size($font-sm, $font-sm-resp);
            cursor: pointer;
        }
        .find-more {
            margin: 10px 0 26px 0;
        }
        .more-url {
            display: inline-block;
            margin: 10px 0 26px 0;
            color: $dark-moderate-blue;
            white-space: nowrap;
            @extend .text-section-url;
            @include font-size($font-sm, $font-sm-resp);
            cursor: pointer;
            .read-less {
                display: none;
            }
        }
        .large_text {
            display: none;
        }
        // .working-title {
        //     // font-size: $font-lg;
        //     // font-weight: $weight-medium;
        //     // color: $dark-smokey-grey;
        //     // margin: 0;
        //     color: $dark-smokey-grey;
        //     margin: 0;
        //     line-height: 1.2;
        //     @include font-size($font-lg, $font-lg-resp);
        //     font-weight: $weight-medium;
        // }
    }
    .working-wraper {
        padding-right: 0;
        .working-content {
            overflow: hidden;
            position: relative;
            &:hover {
                @media (min-width: $screen-md-min) {
                    @include transition(ease transform .5s);
                    &:hover {
                        .working-content-media {
                            @include filter(blur(2px));
                        }
                    }
                }
                .working-content-info {
                    @include transform(translateY(15px));
                    .read-more {
                        @include opacity(1);
                    }
                }
            }
            .working-content-media {
                position: relative;
                width: 100%;
                height: 100%;
            }
            .working-content-info {
                color: $white-smoke;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0 20px 40px;
                @include transition(0.5s);
                @include transform(translateY(37px));
                background: transparent;
                /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Firefox 3.6 to 15 */
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* Standard syntax */
                .rtl & {
                    right: 0;
                    left: 0;
                }
                .working-date {
                    display: block;
                    font-size: $font-sm;
                    margin: 0 0 5px 0;
                }
                .working-name {
                    font-size: $font-md;
                }
                .read-more {
                    @include opacity(0);
                    @include transition(0.5s);
                    font-weight: $weight-bold;
                    @include font-size($font-sm, $font-sm-resp);
                    display: block;
                    &::after {
                        content: "";
                        width: 5px;
                        height: 11px;
                        background: url($white-arrow) no-repeat;
                        background-size: contain;
                        vertical-align: middle;
                        display: inline-block;
                        margin-left: 12px;
                        .rtl & {
                            margin-left: 0;
                            margin-right: 12px;
                            @include transform(rotate(180deg));
                        }
                    }
                }
                a {
                    &:hover,
                    &:focus {
                        color: unset;
                    }
                }
            }
        }
        @media only screen and (max-width: $screen-sm-max) {
            margin: 0 0 30px 0;
            padding-right: 15px;
            &:last-child {
                margin: 0;
                padding: 0;
            }
        }
    }
    .working-content-sm {
        padding-left: 0;
        &:hover {
            .working-content-info-sm {
                .read-more {
                    @include opacity(1);
                }
            }
        }
        .working-content-media {
            width: 100%;
            @media (max-width: $screen-xs-max) {
                max-width: 100%;
            }
        }
        .working-content-info-sm {
            margin: 25px 0 0 0;
            color: $dark-smokey-grey;
            .working-date {
                display: block;
                font-size: $font-xs;
                margin: 0 0 7px;
            }
            .working-name {
                font-size: $font-md;
            }
        }
        .read-more {
            @include opacity(0);
            @include transition(0.5s);
            font-weight: $weight-bold;
            @include font-size($font-sm, $font-sm-resp);
            display: block;
            &::after {
                content: "";
                width: 5px;
                height: 11px;
                background: url($grey-arrow) no-repeat;
                background-size: contain;
                vertical-align: middle;
                display: inline-block;
                margin-left: 12px;
                .rtl & {
                    margin-left: 0;
                    margin-right: 12px;
                    @include transform(rotate(180deg));
                }
            }
        }
        a {
            &:hover {
                color: unset;
            }
        }
        @media only screen and (max-width: $screen-sm-max) {
            padding-left: 15px;
        }
    }
}