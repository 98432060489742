footer.main-footer {
    .newsletter {
        background-color: $white-smoke;
        form {
            margin-left: -1px;
            .rtl & {
                margin-left: 0px;
                margin-right: -1px;
            }
        }
        .newsletter-form {
            font-size: $font-xs;
            .form-control {
                .rtl & {
                    float: right;
                }
            }
            .footer-input {
                width: auto;
                border-top: none;
                border-bottom: none;
                border-left-color: $white-smoke;
                border-right-color: $white-smoke;
                &:focus {
                    box-shadow: none;
                    padding: 6px 12px;
                }
                .rtl & {
                    &:first-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
                .ja_lang.firefox &,
                .ch_lang.firefox &,
                .ch_lang.safari &,
                .ch_lang.ie & {
                    width: 178px;
                }
            }
            .footer-btn {
                @extend .button-side-padding;
                background-color: $dark-moderate-blue;
                border-color: $dark-moderate-blue;
                color: $white;
                font-weight: $weight-bold;
                outline: 0;
                min-width: 80px;
                .rtl & {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            // select2 in Footer
            .select2.select2-container.select2-container--default,
            .select2.select2-container.select2-container--default.select2-container--below.select2-container--open,
            .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
            .select2.select2-container.select2-container--default.select2-container--above.select2-container--open,
            .select2.select2-container.select2-container--default.select2-container--above.select2-container--focus {
                width: 156px!important;
                &:focus {
                    outline: 0!important;
                }
                @media (max-width: $screen-sm-max) {
                    height: 34px!important;
                    width: 100%!important;
                    margin-top: 25px!important;
                    margin-bottom: 0px!important;
                }
                @media (max-width: $screen-xs-max) {
                    height: 34px!important;
                    width: 100%!important;
                    margin-top: 12px!important;
                    margin-bottom: 0px!important;
                }
            }
            .select2-selection__rendered {
                color: #A5A5A5!important;
                line-height: 34px!important;
                @media (max-width: $screen-sm-max) {
                    padding: 10px 15px!important;
                    font-size: 14px!important;
                    line-height: 15px!important;
                }
                @media (max-width: $screen-xs-max) {
                    padding: 10px 15px!important;
                    font-size: 14px!important;
                    line-height: 15px!important;
                }
            }
            .select2-selection.select2-selection--single {
                border: none!important;
                border-radius: 0!important;
                height: 33px!important;
                width: 156px!important;
                &:focus {
                    outline: 0!important;
                }
                @media (max-width: $screen-sm-max) {
                    height: 34px!important;
                    width: 100%!important;
                }
                @media (max-width: $screen-xs-max) {
                    height: 34px!important;
                    width: 100%!important;
                }
            }
            .select2-selection__arrow {
                // top: 0!important;
                right: 5px!important;
                height: 30px!important;
                .rtl & {
                    left: 5px!important;
                    right: auto!important;
                }
                &:before {
                    content: ""!important;
                    background: url('../images/right_arrow.png') no-repeat center center!important;
                    width: 16px!important;
                    height: 15px!important;
                    background-size: contain!important;
                    vertical-align: middle!important;
                    display: inline-block!important;
                }
                @media (max-width: $screen-sm-max) {
                    top: 8px!important;
                    right: 10px!important;
                    .rtl & {
                        left: 10px!important;
                        // right: 0!important;
                    }
                }
                @media (max-width: $screen-xs-max) {
                    top: 5px!important;
                }
                b {
                    display: none!important;
                }
                &.arrow_rotate {
                    &:before {
                        @include transform(rotate(-90deg));
                        .rtl & {
                            @include transform(rotate(90deg));
                        }
                        @media (max-width: $screen-xs-max) {
                            @include transform(rotate(90deg));
                        }
                    }
                }
            }
        }
        // media query for Tablets >>
        @media only screen and (max-width: $screen-sm-max) {
            margin-top: 30px;
            .form-header {
                margin: 10px 0;
            }
            .input-group {
                width: 100%;
                .footer-input {
                    font-size: 14px;
                    padding: 20px 15px;
                    text-align: left;
                    margin: 25px 0 0 0;
                    border-radius: 0;
                    height: 34px;
                    width: 50%;
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        .rtl & {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }
                    }
                    // &:nth-child(2) {
                    // 	// border-top-left-radius: 0;
                    // 	// border-bottom-left-radius: 0;
                    // 	.rtl & {
                    // 		border-top-left-radius: 7px;
                    // 		border-bottom-left-radius: 7px;
                    // 		border-top-right-radius: 0;
                    // 		border-bottom-right-radius: 0;
                    //    }
                    // }
                }
                .block-input {
                    width: 100%;
                }
                .input-group-btn {
                    display: inline-block;
                    width: 100%;
                    .footer-btn {
                        font-size: 14px;
                        margin: 25px 0 0 0;
                        height: 34px;
                        width: 100%;
                        border-radius: 4px;
                        &:first-child {
                            border-left: 0;
                        }
                    }
                    &:not(:first-child):not(:last-child) {
                        border-radius: 4px;
                    }
                }
                .form-control {
                    &:not(:first-child):not(:last-child) {
                        border-radius: 4px;
                        &:nth-child(2) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            .rtl & {
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                                border-top-left-radius: 4px;
                                border-bottom-left-radius: 4px;
                            }
                        }
                    }
                }
            }
            // .input-group-addon:not(:first-child):not(:last-child),
            // .input-group-btn:not(:first-child):not(:last-child),
            // .input-group .form-control:not(:first-child):not(:last-child) {
            // 	border-radius: 7px;
            // }
        }
        // media query for Mobiles >>
        @media only screen and (max-width: $screen-xs-max) {
            margin-top: 20px;
            .form-header {
                margin: 5px 0;
            }
            .input-group {
                .footer-input {
                    margin: 12px 0 0 0;
                    font-size: $font-xs-resp;
                    height: 43px;
                }
                .input-group-btn {
                    display: inline-block;
                    width: 100%;
                    .footer-btn {
                        margin: 12px 0 0 0;
                        font-size: $font-xs-resp;
                        height: 43px;
                    }
                }
            }
        }
    }
}