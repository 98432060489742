.in-the-news-details {
    position: relative;
    .listing-container {
        position: relative;
        padding: 24px 0 54px;
    }
    // .article-content {
    //     h1 {
    //         margin-bottom: 0;
    //     }
    // }
    .box-header {
        h3 {
            color: #4e869f;
            font-size: 20px;
            font-weight: 500;
            background-color: $lighter-grey;
            padding: 15px 20px;
            margin: 0 0 20px 0;
            .publish-date {
                @media(max-width: $screen-xs-max) {
                    display: block;
                }
            }
        }
    }
    .news-url {
        color: $dark-moderate-blue;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
    }
    .flex-item {
        padding: 0;
    }
    .aside-content {
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
    .modal {
        padding-right: 0!important;
        .modal-dialog {
            padding: 50px;
            margin-top: 5%;
            box-shadow: 0px 1px 10px 0px;
            background-color: $white;
            @media (max-width: $screen-sm-max) {
                margin-top: 15%;
            }
            @media (max-width: $screen-xs-max) {
                margin-top: 30%;
                padding: 35px 15px;
            }
            .modal-content {
                margin: auto;
                border: none;
                box-shadow: none;
                .close {
                    opacity: 1;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                    left: auto;
                    &:hover {
                        opacity: 1;
                    }
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 35px;
                        font-weight: 500;
                        color: $dark-moderate-blue;
                        &:focus {
                            outline: 0;
                        }
                    }
                }
                .modal-body {
                    padding: 0;
                    .carousel {
                        .carousel-inner {
                            position: relative;
                            .item {
                                .media-img {
                                    // position: absolute;
                                    top: 0;
                                    z-index: 1;
                                    &:focus,
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                                .details {
                                    h3 {
                                        font-weight: 500;
                                        font-size: 20px;
                                        padding-bottom: 25px;
                                        margin: 25px 0 20px;
                                        color: $dark-moderate-blue;
                                        border-bottom: 1px solid $light-grey;
                                        @media (max-width: $screen-xs-max) {
                                            font-size: 18px;
                                        }
                                    }
                                    p {
                                        font-size: 16px;
                                        margin-bottom: 0;
                                        margin-top: 20px;
                                    }
                                }
                                img {
                                    max-width: 100%;
                                }
                                .block-options {
                                    padding-top: 0;
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid $light-grey;
                                    @media (max-width: $screen-sm-max) {
                                        display: none;
                                    }
                                    .svg-wraper {
                                        margin-left: -3px;
                                        display: inline-block;
                                        padding: 0 30px;
                                        border-right: 2px solid $light-grey;
                                        max-height: 35px;
                                        .rtl & {
                                            margin-left: 0;
                                            border-right: none;
                                        }
                                        &:last-child {
                                            padding-right: 0;
                                            border-right: none;
                                            .rtl & {
                                                margin-left: 0;
                                                padding-left: 0;
                                                padding-right: 30px;
                                                border-right: 2px solid $light-grey;
                                            }
                                        }
                                        &:first-child {
                                            margin-left: 0;
                                            padding-left: 0;
                                            .rtl & {
                                                padding-right: 0;
                                                padding-left: 30px;
                                            }
                                        }
                                        svg {
                                            margin-top: 15%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .arrow-media {
                position: absolute;
                top: 175px;
                @media (max-width: $screen-xs-max) {
                    top: 120px;
                }
                .slider-arrow {
                    fill: #88888a;
                }
            }
            .arrow-media-right {
                right: 15px;
                left: auto;
                .rtl & {
                    left: 15px;
                    right: auto;
                }
            }
            .arrow-media-left {
                left: 15px;
                right: auto;
                .rtl & {
                    right: 15px;
                    left: auto;
                }
            }
        }
        h4 {
            font-size: 32px;
            font-weight: 100;
            line-height: 1.2;
        }
        img {
            // display: block;
            max-width: 100%;
            // height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
}