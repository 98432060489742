.media-listing {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: $screen-xs-max) {
        padding: 0px 5px;
    }
    div.flex-item {
        @media(min-width: $screen-md-min) {
            min-height: 1200px;
        }
    }
    .alm-listing {
        @media (max-width: $screen-xs-max) {
            width: auto;
        }
        .alm-reveal {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .filteration-row {
        .input-group {
             ::-webkit-input-placeholder {
                @include font-size(14px, $font-sm-resp);
                color: $dark-grey;
                @media (max-width: $screen-sm-max) {
                    font-size: 14px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: $font-xs-resp;
                }
                @media (max-width: $screen-md-custom) {
                    .rtl & {
                        font-size: $font-xs-resp;
                    }
                }
            }
             ::-moz-placeholder {
                @include font-size(14px, $font-sm-resp);
                color: $dark-grey;
                @media (max-width: $screen-sm-max) {
                    font-size: 14px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: $font-xs-resp;
                }
                @media (max-width: $screen-md-custom) {
                    .rtl & {
                        font-size: $font-xs-resp;
                    }
                }
            }
             :-ms-input-placeholder {
                @include font-size(14px, $font-sm-resp);
                color: $dark-grey;
                @media (max-width: $screen-sm-max) {
                    font-size: 14px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: $font-xs-resp;
                }
                @media (max-width: $screen-md-custom) {
                    .rtl & {
                        font-size: $font-xs-resp;
                    }
                }
            }
             :-moz-placeholder {
                @include font-size(14px, $font-sm-resp);
                color: $dark-grey;
                @media (max-width: $screen-sm-max) {
                    font-size: 14px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: $font-xs-resp;
                }
                @media (max-width: $screen-md-custom) {
                    .rtl & {
                        font-size: $font-xs-resp;
                    }
                }
            }
        }
        .select2-container {
            .select2-selection.select2-selection--single {
                .select2-selection__rendered {
                    font-size: 14px;
                    padding-left: 14px;
                    @media (min-width: $screen-md-min) {
                        max-width: 80%;
                    }
                    .rtl & {
                        padding-left: 0px;
                        padding-right: 14px;
                    }
                }
                .select2-selection__arrow {
                    padding: 14px;
                    right: 5px;
                    left: auto;
                    .rtl & {
                        top: -2px;
                        left: -12px;
                        right: auto;
                    }
                }
            }
        }
    }
    .listing-container {
        position: relative;
        padding: 54px 0;
    }
    .media-img {
        width: 100%;
        overflow: hidden;
        position: relative;
        img {
            min-width: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        a {
            display: block;
            &:hover {
                @include opacity(0.9);
            }
        }
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
            overflow: visible;
            &:focus,
            &:hover {
                cursor: pointer;
            }
            .firefox & {
                top: 65%;
                left: 65%;
            }
        }
        img {
            width: 100%;
            @media (max-width: $screen-xs-max) {
                height: 100%;
            }
        }
        &:focus,
        &:hover {
            // cursor: pointer;
        }
    }
    .details {
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin: 25px 0 10px;
            color: $dark-moderate-blue;
            @media (max-width: $screen-xs-max) {
                font-size: 18px;
            }
        }
        p {
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 40px;
        }
        img {
            max-width: 100%;
        }
        .block-options {
            padding-top: 10px;
            @media (max-width: $screen-sm-max) {
                display: block;
            }
            .svg-wraper {
                margin-left: -3px;
                display: inline-block;
                padding: 0 30px;
                border-right: 2px solid $light-grey;
                max-height: 35px;
                .rtl & {
                    margin-left: 0;
                    border-right: none;
                }
                &:last-child {
                    padding-right: 0;
                    border-right: none;
                    .rtl & {
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 30px;
                        border-right: 2px solid $light-grey;
                    }
                }
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    .rtl & {
                        padding-right: 0;
                        padding-left: 30px;
                    }
                    @media (max-width: $screen-sm-max) {
                        display: none;
                    }
                }
                &:nth-child(2) {
                    @media (max-width: $screen-sm-max) {
                        padding-left: 4px;
                        .rtl & {
                            padding-right: 0;
                            padding-left: 30px;
                        }
                    }
                }
                svg {
                    margin-top: 15%;
                }
                .social_share {
                    display: inline-block;
                    img {
                        margin-top: -12px;
                    }
                }
            }
        }
    }
    .modal {
        padding-right: 0!important;
        .modal-dialog {
            padding: 50px;
            margin-top: 5%;
            box-shadow: 0px 1px 10px 0px;
            background-color: $white;
            @media (max-width: $screen-sm-max) {
                margin-top: 15%;
            }
            @media (max-width: $screen-xs-max) {
                padding: 35px 15px;
                margin-top: 30%;
            }
            .modal-content {
                margin: auto;
                border: none;
                box-shadow: none;
                .close {
                    opacity: 1;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                    left: auto;
                    &:hover {
                        opacity: 1;
                    }
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 35px;
                        font-weight: 500;
                        color: $dark-moderate-blue;
                        &:focus {
                            outline: 0;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        right: -5px
                    }
                }
                .modal-body {
                    padding: 0;
                    .carousel {
                        .carousel-inner {
                            position: relative;
                            .item {
                                .media-img {
                                    // position: absolute;
                                    top: 0;
                                    z-index: 1;
                                    svg {
                                        &:focus,
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                                .details {
                                    h3 {
                                        font-weight: 500;
                                        font-size: 20px;
                                        padding-bottom: 25px;
                                        margin: 25px 0 20px;
                                        color: $dark-moderate-blue;
                                        border-bottom: 1px solid $light-grey;
                                        @media (max-width: $screen-xs-max) {
                                            font-size: 18px;
                                        }
                                    }
                                    p {
                                        font-size: 16px;
                                        margin-bottom: 0;
                                        margin-top: 20px;
                                    }
                                }
                                img {
                                    max-width: 100%;
                                }
                                .block-options {
                                    padding-top: 0;
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid $light-grey;
                                    @media (max-width: $screen-sm-max) {
                                        display: block;
                                    }
                                    .svg-wraper {
                                        &:nth-child(1) {
                                            border-right: 2px solid #dcdcdc;
                                            border-left: none;
                                            .rtl & {
                                                border-left: 2px solid #dcdcdc;
                                                border-right: none;
                                            }
                                            @media (max-width: $screen-sm-max) {
                                                display: none;
                                            }
                                        }
                                        &:nth-child(2) {
                                            border-right: none;
                                            border-left: none;
                                            @media (max-width: $screen-sm-max) {
                                                padding-left: 4px;
                                                .rtl & {
                                                    padding-right: 0;
                                                    padding-left: 30px;
                                                }
                                            }
                                        }
                                        &:nth-child(3) {
                                            height: 31px;
                                            vertical-align: bottom;
                                            border-right: none;
                                            border-left: 2px solid #dcdcdc;
                                            .rtl & {
                                                border-left: none;
                                                border-right: 2px solid #dcdcdc;
                                            }
                                        }
                                        .social_share {
                                            margin-top: 11px;
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .arrow-media {
                position: absolute;
                top: 175px;
                @media (max-width: $screen-xs-max) {
                    top: 120px;
                }
                .slider-arrow {
                    fill: #88888a;
                }
            }
            .arrow-media-right {
                right: 15px;
                left: auto;
                @media (max-width: $screen-xs-max) {
                    right: 10px;
                }
                .rtl & {
                    left: 15px;
                    right: auto;
                    @include transform(rotate(180deg));
                    @media (max-width: $screen-xs-max) {
                        left: 10px;
                    }
                }
            }
            .arrow-media-left {
                left: 15px;
                right: auto;
                @media (max-width: $screen-xs-max) {
                    left: 10px;
                }
                .rtl & {
                    right: 15px;
                    left: auto;
                    @include transform(rotate(180deg));
                    @media (max-width: $screen-xs-max) {
                        right: 10px;
                    }
                }
            }
        }
        h4 {
            font-size: 32px;
            font-weight: 100;
            line-height: 1.2;
        }
        img {
            // display: block;
            max-width: 100%;
            // height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
}

.modal-backdrop {
    background-color: $white;
}