#cookie-law-info-bar{
    padding-top: 20px;
    padding-bottom: 20px;
    
    .cli-plugin-button{
        border: none;
        text-shadow: none;
        box-shadow: none;
        border-radius: 4px;
        padding: 12px 25px;
        &:hover{
            background: $white !important;
        }
    }
    @media (max-width: $screen-sm-max) {
        .cli-plugin-button{margin-top: 30px;}  
    }
}