.search-box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: $white;
    padding-top: 20px;
    padding-left: 130px;
    // margin-left: 30px;
    display: none;
    .rtl & {
        // padding-left:0;
        // margin-left: 0;
        padding-left: 70px;
        padding-right: 30px;
        // margin-right: 30px;   
    }
    @media (max-width: $screen-sm-max) {
        top: 50px;
        padding: 80px 100px 0;
        margin: 0;
        .rtl & {
            padding: 80px 100px 0;
            margin: 0;
        }
    }
    @media (max-width: $screen-xs-max) {
        top: 50px;
        padding: 50px 20px 0;
        margin: 0;
        .rtl & {
            padding: 50px 20px 0;
            margin: 0;
        }
    }
    // Search Input >
    .search-input {
        border: none;
        outline: none;
        box-shadow: none;
        background: none;
        border-radius: 0;
        height: 30px;
        line-height: 1;
        // border-left: 1px solid $dark-smokey-grey;
        color: $dark-smokey-grey;
        font-size: $font-md;
        padding: 0px 10px;
        .rtl & {
            // border-left: none;
            // border-right: 1px solid $dark-smokey-grey;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        @media (max-width: $screen-sm-max) {
            border-left: none;
            border-bottom: 1px solid $dark-smokey-grey;
            padding: 0px 0;
            height: auto;
            &:focus {
                color: $moderate-blue;
                border-color: $moderate-blue;
            }
        }
    }
    // Close >
    .search-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        text-transform: uppercase;
        font-size: $font-xs;
        font-weight: $weight-medium;
        color: $moderate-blue;
        cursor: pointer;
        line-height: 0;
        .rtl & {
            line-height: 0.7;
        }
        // .ch_lang & {
        //     font-weight: 400;
        // }
        svg {
            margin-right: 14px;
            .rtl & {
                float: left;
            }
        }
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
    // Close >
    .search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        line-height: 0;
        .rtl & {
            // right: auto;
            // left:0;
        }
        @media (max-width: $screen-sm-max) {
            right: 100px;
            top: 92px;
            .rtl & {
                left: 100px;
                right: auto;
            }
        }
        @media (max-width: $screen-xs-max) {
            top: 61px;
            left: auto;
            right: 20px;
            .rtl & {
                left: 20px;
                right: auto;
                top: 68px;
            }
        }
    }
    // Place Holder >
     ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        opacity: 0.3;
    }
     ::-moz-placeholder {
        /* Firefox 19+ */
        opacity: 0.3;
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        opacity: 0.3;
    }
     :-moz-placeholder {
        /* Firefox 18- */
        opacity: 0.3;
    }
}