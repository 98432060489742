@keyframes fadeOutUp {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    display: none;
  }
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoombackground {
  0% {
    transform: scale(1) translate3d(-2px, -3px, 0);
    -webkit-transform: scale(1) translate3d(-2px, -3px, 0);
  }
  100% {
    transform: scale(1.1) translate3d(-10px, 10px, 0);
    -webkit-transform: scale(1.1) translate3d(-10px, 10px, 0);
  }
}

@keyframes image {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}