// media blocks
.media-overview-blocks {
    .flex-item {
        padding: 0;
    }
    .overview-holder {
        display: flex;
        flex-wrap: wrap;
        // .safari & {
        //     display: block;
        // }
    }
    aside.flex-item {
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
    }
    .media-listing-container {
        padding: 54px 0 34px 0;
        .media-block {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            .media-img {
                img {
                    @media (max-width: $screen-xs-max) {
                        height: auto;
                    }
                }
            }
            h3 {
                margin-top: 15px;
                margin-bottom: 0;
                font-size: 32px;
                font-weight: $weight-light;
                @media (max-width: $screen-xs-max) {
                    margin-top: 8px;
                    font-size: 18px;
                }
            }
        }
    }
    aside {
        @media (max-width: $screen-xs-max) {
            padding: 0;
            margin-top: 15px;
        }
    }
    .aside-content {
        .for-inq {
            @media (max-width: $screen-xs-max) {
                margin: 0 5px;
            }
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
}

// media video
.media-overview-video {
    &.media-listing {
        margin-bottom: 0;
    }
    .vwide-cover {
        .video-options {
            .video-title {
                width: 45%;
                font-size: 32px;
                font-weight: $weight-light;
                margin-top: 10px;
                color: $white;
                line-height: 1.2;
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    font-size: 24px;
                }
            }
        }
        .video-details {
            font-size: $font-sm;
            font-weight: $weight-medium;
            display: inline-block;
            margin-top: 20px;
            color: $white;
            &:focus {
                outline: 0;
            }
            .caret {
                background: url($white-arrow) no-repeat;
                width: 5px;
                height: 9px;
                border: none;
                margin-left: 15px;
                background-size: auto 100%;
                @include transform-origin(center 3px);
                @include opacity(0.7);
                .rtl & {
                    margin-left: 0;
                    margin-right: 15px;
                    margin-top: 5px;
                    @include transform(rotate(180deg));
                }
            }
        }
    }
    .modal {
        padding-right: 0!important;
        .modal-dialog {
            padding: 50px;
            margin-top: 5%;
            box-shadow: 0px 1px 10px 0px;
            background-color: $white;
            @media (max-width: $screen-sm-max) {
                margin-top: 15%;
            }
            @media (max-width: $screen-xs-max) {
                padding: 35px 15px;
                margin-top: 30%;
            }
            .modal-content {
                margin: auto;
                border: none;
                box-shadow: none;
                .close {
                    opacity: 1;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                    left: auto;
                    &:hover {
                        opacity: 1;
                    }
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 35px;
                        font-weight: 500;
                        color: $dark-moderate-blue;
                        &:focus {
                            outline: 0;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        right: -5px
                    }
                }
                .modal-body {
                    padding: 0;
                    .embed-video {
                        padding-bottom: 56.25%;
                    }
                    .embed-responsive .embed-responsive-item,
                    .embed-responsive embed,
                    .embed-responsive iframe,
                    .embed-responsive object,
                    .embed-responsive video {
                        top: 0!important;
                    }
                    .item {
                        .media-img {
                            // position: absolute;
                            top: 0;
                            z-index: 1;
                            svg {
                                &:focus,
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                        .details {
                            h3 {
                                font-weight: 500;
                                font-size: 20px;
                                padding-bottom: 25px;
                                margin: 25px 0 20px;
                                color: $dark-moderate-blue;
                                border-bottom: 1px solid $light-grey;
                                @media (max-width: $screen-xs-max) {
                                    font-size: 18px;
                                }
                            }
                            p {
                                font-size: 16px;
                                margin-bottom: 0;
                                margin-top: 20px;
                            }
                            img {
                                max-width: 100%;
                            }
                            .block-options {
                                padding-top: 0;
                                padding-bottom: 20px;
                                border-bottom: 1px solid $light-grey;
                                @media (max-width: $screen-sm-max) {
                                    display: block;
                                }
                                .svg-wraper {
                                    &:nth-child(1) {
                                        border-right: 2px solid #dcdcdc;
                                        border-left: none;
                                        .rtl & {
                                            border-left: 2px solid #dcdcdc;
                                            border-right: none;
                                        }
                                    }
                                    &:nth-child(2) {
                                        border-right: none;
                                        border-left: none;
                                    }
                                    &:nth-child(3) {
                                        height: 31px;
                                        vertical-align: bottom;
                                        border-right: none;
                                        border-left: 2px solid #dcdcdc;
                                        .rtl & {
                                            border-left: none;
                                            border-right: 2px solid #dcdcdc;
                                        }
                                    }
                                    .social_share {
                                        margin-top: 11px;
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        h4 {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
        img {
            // display: block;
            max-width: 100%;
            // height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
    }
}

// media card
.media-card {
    .big-box {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        overflow: hidden;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        @media (max-width: $screen-xs-max) {
            left: 0px;
            right: 0px;
        }
        .img-small-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            @include transition (0.5s opacity);
            p {
                position: absolute;
                bottom: 55px;
                top: auto;
                font-size: 24px;
                margin-top: 0;
                margin-bottom: 0;
                line-height: 1.2;
                color: #fefefe;
            }
            a {
                position: absolute;
                bottom: 20px;
                top: auto;
                margin-top: 5px;
                display: inline-block;
                font-size: 16px;
                color: $white;
                @media (max-width: 1024px) {
                    margin-top: 5px;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 9px;
                    border: none;
                    margin-left: 10px;
                    background: url($white-arrow) no-repeat;
                    background-size: auto 100%;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 10px;
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
        &:hover {
            .img-small-overlay {
                @include transition (0.5s opacity);
                opacity: 1;
                z-index: 9999;
            }
        }
        .ie & {
            &:hover {
                .img-box {
                    position: absolute;
                }
                .img-small-overlay {
                    position: relative;
                }
            }
        }
    }
    .small-box {
        .img-small-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            @include transition (0.5s opacity);
            p {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 0;
                line-height: 1.2;
                color: #fefefe;
            }
            a {
                margin-top: 5px;
                display: inline-block;
                // position: relative;
                color: $white;
                @media (max-width: 1024px) {
                    margin-top: 5px;
                }
                &:after {
                    @include transition(0.4s);
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 9px;
                    border: none;
                    margin-left: 10px;
                    background: url($white-arrow) no-repeat;
                    background-size: auto 100%;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 10px;
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
        &:hover {
            .img-box {
                .svg-social {
                    display: none;
                }
            }
            .img-small-overlay {
                @include transition (0.5s opacity);
                opacity: 1;
                z-index: 9999;
            }
        }
        .ie & {
            &:hover {
                .img-box {
                    position: absolute;
                }
                .img-small-overlay {
                    position: relative;
                }
            }
        }
    }
    .column-reverse {
        .two-vertical {
            display: flex;
            flex-direction: column;
            @media (min-width: $screen-sm-min) {
                flex-direction: column-reverse;
            }
        }
    }
    .img-box {
        a {
            width: 100%;
            height: 100%;
            z-index: 99;
            // position: relative;
            display: inline-block;
            opacity: 1;
            &:after {
                content: "";
                z-index: 10;
                position: absolute;
                top: -18px;
                left: -2px;
                width: 100%;
                height: 100%;
                background: url(../images/header-slider-overlay.svg) no-repeat;
                background-size: 140% auto;
            }
        }
        .svg-social {
            width: 35px;
            height: 35px;
            min-width: inherit;
            margin-top: 15px;
            margin-left: 15px;
            position: absolute;
            top: 15px;
            bottom: auto;
            left: 15px;
            z-index: 999;
            right: auto;
            fill-opacity: 0.8;
            .rtl & {
                left: auto;
                right: -15px;
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
    .text-box {
        p {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 0;
            line-height: 1.2;
        }
        .svg-social {
            position: absolute;
            top: 15px;
            width: 45px;
            height: 35px;
        }
        a {
            margin-top: 5px;
            display: inline-block;
            position: relative;
            @media (max-width: 1024px) {
                margin-top: 5px;
            }
            &:after {
                content: "";
                display: inline-block;
                width: 5px;
                height: 9px;
                border: none;
                margin-left: 10px;
                background: url($lightGrey-arrow) no-repeat;
                background-size: auto 100%;
                .rtl & {
                    margin-left: 0;
                    margin-right: 10px;
                    @include transform(rotate(180deg));
                }
            }
        }
    }
    .small-box.text-box {
        p {
            font-size: 16px;
        }
    }
    .big-col {
        .linkedin-icon {
            fill-opacity: 0.3;
            position: absolute;
            top: 30px;
            left: 25px;
            right: auto;
            bottom: auto;
            .rtl & {
                right: 25px;
                left: auto;
            }
            @media (max-width: $screen-xs-max) {
                left: 10px;
                right: auto;
                .rtl & {
                    right: 10px;
                    left: auto;
                }
            }
        }
    }
    .small-box {
        // p {
        //     margin-top: 60px;
        //     @media (max-width: 1024px) {
        //         margin-top: 60px;
        //     }
        //     @media (max-width: $screen-sm-max) {
        //         margin-top: 50px;
        //     }
        //     @media (max-width: $screen-xs-max) {
        //         margin-top: 45px;
        //     }
        // }
        .gplus-icon {
            position: absolute;
            top: 20px;
            fill-opacity: 0.3;
            @media (max-width: $screen-sm-max) {
                top: 10px;
            }
        }
        .find-more {
            margin-top: 5px;
            @media (max-width: 1024px) {
                margin-top: 5px;
            }
            &:after {
                background: url($lightGrey-arrow) no-repeat;
                width: 5px;
                height: 9px;
                border: none;
                margin-left: 15px;
                background-size: auto 100%;
                @include transform-origin(center 3px);
                @include opacity(0.7);
                .rtl & {
                    margin-left: 0;
                    margin-right: 15px;
                    margin-top: 5px;
                    @include transform(rotate(180deg));
                }
            }
        }
    }
}