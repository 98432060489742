.awards {
    .listing-container {
        position: relative;
        padding: 54px 0;
    }
    .flex-item {
        padding: 0;
    }
    .table-scroll {
        @media(min-width: $screen-md-min) {
            min-height: 1000px;
        }
        @media(max-width: $screen-xs-max) {
            overflow-x: scroll;
        }
        .table-container {
            padding: 0;
            @media(max-width: $screen-xs-max) {
                // overflow-x: scroll;
                width: 575px;
            }
            .table-header {
                display: flex;
                background-color: $dark-moderate-blue;
                border-bottom: 1px solid $white;
                .header-cell {
                    color: $white;
                    font-size: 16px;
                    padding: 15px;
                    border-right: 0.5px solid $white;
                    border-left: 0.5px solid $white;
                    &:first-child {
                        border-left: none;
                    }
                    &:last-child {
                        border-right: none;
                    }
                    p {
                        font-size: 16px;
                        font-weight: 400;
                        margin: 0 0 20px auto;
                        margin-bottom: 0;
                    }
                    h4 {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 28px;
                    }
                    img {
                        // display: block;
                        max-width: 100%;
                        height: 100%;
                        // width: 100%;
                        &.alignleft {
                            float: left;
                            padding-right: 15px;
                        }
                        &.alignright {
                            float: right;
                            padding-left: 15px;
                        }
                        &.aligncenter {
                            display: block;
                            margin: auto;
                        }
                        &.alignnone {
                            float: none;
                        }
                    }
                    figure {
                        &.alignleft {
                            float: left;
                            padding-right: 15px;
                        }
                        &.alignright {
                            float: right;
                            padding-left: 15px;
                        }
                        &.aligncenter {
                            display: block;
                            margin: auto;
                        }
                        &.alignnone {
                            float: none;
                        }
                    }
                    ol {
                        padding: 0;
                        margin: 20px 0;
                        counter-reset: olcounter;
                        li {
                            // list-style-type: lower-roman;
                            list-style-position: inside;
                            list-style: none; //
                            color: $dark-smokey-grey;
                            line-height: 1.2;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:before {
                                content: counter(olcounter, lower-roman)".";
                                counter-increment: olcounter;
                                display: block;
                                position: absolute;
                                left: 0;
                                .rtl & {
                                    margin-left: 20px;
                                    margin-right: 0;
                                    left: auto;
                                    right: 0;
                                }
                            }
                            // &:before {
                            //  content: "";
                            //  width: 10px;
                            //  display: inline-block;
                            // }
                            ol {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                li {
                                    // list-style-type: lower-alpha;
                                }
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                        &[type=a] {
                            li {
                                &::before {
                                    content: counter(olcounter, lower-alpha)".";
                                }
                            }
                        }
                    }
                    ul {
                        margin: 20px 0;
                        padding: 0px;
                        li {
                            background: url(../images/bullet-icon.png) no-repeat 0 8px;
                            list-style: none;
                            margin-bottom: 3px;
                            color: $dark-smokey-grey;
                            line-height: 1.5;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            .rtl & {
                                background-position: 100% 8px;
                            }
                            ul {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                    }
                    a {
                        font-weight: $weight-medium;
                        color: $dark-moderate-blue;
                    }
                    .p1-style {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 28px;
                    }
                }
            }
            .table-row {
                display: flex;
                display: -webkit-flex;
                display: -webkit-box;
                display: -ms-flexbox;
                -ms-flex-flow: row wrap;
                flex-flow: row wrap;
                -webkit-flex-flow: row wrap;
                -webkit-box-align: stretch;
                -ms-flex-align: stretch;
                align-items: stretch;
                -webkit-align-items: stretch;
                background-color: $lighter-grey;
                border-bottom: 1px solid $white;
                .row-cell {
                    font-size: 16px;
                    padding: 15px;
                    color: $dark-smokey-grey;
                    border-right: 0.5px solid $white;
                    border-left: 0.5px solid $white;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    // @media(max-width: $screen-sm-max) {
                    //     display: block;
                    // }
                    &:first-child {
                        border-left: none;
                    }
                    &:last-child {
                        border-right: none;
                    }
                    p {
                        font-size: 16px;
                        font-weight: 400;
                        margin: 0;
                    }
                    h4 {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 28px;
                    }
                    img {
                        // display: block;
                        max-width: 100%;
                        height: 100%;
                        // width: 100%;
                        &.alignleft {
                            float: left;
                            padding-right: 15px;
                        }
                        &.alignright {
                            float: right;
                            padding-left: 15px;
                        }
                        &.aligncenter {
                            display: block;
                            margin: auto;
                        }
                        &.alignnone {
                            float: none;
                        }
                    }
                    figure {
                        &.alignleft {
                            float: left;
                            padding-right: 15px;
                        }
                        &.alignright {
                            float: right;
                            padding-left: 15px;
                        }
                        &.aligncenter {
                            display: block;
                            margin: auto;
                        }
                        &.alignnone {
                            float: none;
                        }
                    }
                    ol {
                        padding: 0;
                        margin: 20px 0;
                        counter-reset: olcounter;
                        li {
                            // list-style-type: lower-roman;
                            list-style-position: inside;
                            list-style: none; //
                            color: $dark-smokey-grey;
                            line-height: 1.2;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:before {
                                content: counter(olcounter, lower-roman)".";
                                counter-increment: olcounter;
                                display: block;
                                position: absolute;
                                left: 0;
                                .rtl & {
                                    margin-left: 20px;
                                    margin-right: 0;
                                    left: auto;
                                    right: 0;
                                }
                            }
                            // &:before {
                            //  content: "";
                            //  width: 10px;
                            //  display: inline-block;
                            // }
                            ol {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                li {
                                    // list-style-type: lower-alpha;
                                }
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                        &[type=a] {
                            li {
                                &::before {
                                    content: counter(olcounter, lower-alpha)".";
                                }
                            }
                        }
                    }
                    ul {
                        margin: 20px 0;
                        padding: 0px;
                        li {
                            background: url(../images/bullet-icon.png) no-repeat 0 8px;
                            list-style: none;
                            margin-bottom: 3px;
                            color: $dark-smokey-grey;
                            line-height: 1.5;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            .rtl & {
                                background-position: 100% 8px;
                            }
                            ul {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                    }
                    a {
                        font-weight: $weight-medium;
                        color: $dark-moderate-blue;
                    }
                    .p1-style {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 28px;
                    }
                }
            }
            ul.alm-listing.alm-ajax.container {
                margin: 0;
            }
        }
    }
    .aside-content {
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
    input.form-control {
        box-shadow: none !important;
        .ie & {
            width: 90%;
        }
    }
}