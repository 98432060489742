$card-section-spacing: 50px;
$card-heading-spacing: 30px;
$card-paragraph-spacing: 20px;
// $card-text-url-spacing: 10px;
.card-section {
    padding-top: $card-section-spacing;
    padding-bottom: $card-section-spacing;
    // Card Text >>
    .card-text {
        margin-bottom: 35px;
        @media (max-width: $screen-xs-max) {
            margin-bottom: 0;
        }
        .ie & {
            margin-bottom: 0;
        }
        // .small_text {
        //   // margin: 0 5px;
        // }
        @media (max-width: $screen-sm-max) {
            // padding: 0 7.5px $card-heading-spacing;
        }
        @media (max-width: $screen-xs-max) {
            // padding: 0 0 $card-heading-spacing;
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    // li {
                    //   // list-style-type: lower-alpha;
                    // }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0;
                }
                .rtl & {
                    background: url(../images/bullet-icon.png) no-repeat 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        h1 {
            color: $dark-smokey-grey;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            .ch_lang & {
                line-height: 1.4;
            }
            .ja_lang & {
                line-height: 1.25;
            }
        }
        h4,
        div.p1-style {
            margin-top: 0;
            @include font-size($font-md, 21px);
            font-weight: 100;
            line-height: 28px;
            // .ch_lang & {
            //     font-weight: $weight-thin;
            // }
        }
        h2 {
            color: $dark-smokey-grey;
            margin: 0 0 $card-heading-spacing;
            line-height: 1.2;
            @extend .section-heading;
            // @extend .font-size-lg;
            @include font-size(32px, $font-lg-resp);
            .ja_lang & {
                font-weight: $weight-regular;
                margin: 0 0 33px;
            }
            .ch_lang & {
                margin: 0 0 33px;
            }
            .ie & {
                margin-bottom: 0;
            }
            @media (max-width: $screen-xs-max) {
                margin-top: 20px;
            }
        }
        p {
            @extend .default-paragraph;
            @include font-size($font-sm, $font-sm-resp);
            margin: 0 0 $card-paragraph-spacing;
            line-height: 20px;
            color: $dark-smokey-grey;
            &.lead {
                line-height: 1.2;
                @extend .lead-paragraph;
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
            }
            .ja_lang & {
                line-height: 1.25;
            }
        }
        h5 {
            margin: 0 0 $card-paragraph-spacing;
            color: $dark-smokey-grey;
            line-height: 1.2;
            font-weight: $weight-regular;
            @extend .lead-paragraph;
            // @extend .font-md;
            @include font-size($font-md, $font-md-resp);
        }
        // img {
        // }
        .find-more,
        a {
            color: $dark-moderate-blue;
            display: inline-block;
            margin: 0;
            // white-space: nowrap;
            @extend .text-section-url;
            @include font-size($font-sm, $font-sm-resp);
            cursor: pointer;
        }
        .find-more {
            margin: 10px 0 26px 0;
        }
        .more-url {
            display: inline-block;
            margin: 10px 0 26px 0;
            color: $dark-moderate-blue;
            white-space: nowrap;
            @extend .text-section-url;
            @include font-size($font-sm, $font-sm-resp);
            cursor: pointer;
            .read-less {
                display: none;
            }
        }
        .text2 {
            display: none !important;
        }
        .card-text-logo {
            text-align: center;
            img {
                display: block;
                margin: 60px auto 25px;
                max-width: 100%;
                @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                    padding: 0 7px;
                }
            }
            @media (max-width: $screen-xs-max) {
                margin-top: 0;
                padding: 0;
            }
        }
        &.whiteText {
            p {
                // color: $white;
                color: $dark-smokey-grey;
                line-height: 20px;
            }
        }
        &.vertical-text {
            h2 {
                color: $dark-smokey-grey;
                margin: 5px 0 10px;
                line-height: 1;
            }
            p {
                margin: 0 0 20px;
                color: $dark-smokey-grey;
                line-height: 20px;
            }
        }
        // a {
        //     &::after {
        //         background: url($blue-arrow) no-repeat;
        //         height: 9px;
        //         border: none;
        //         width: 5px;
        //         margin-left: 15px;
        //         background-size: auto 100%;
        //         -webkit-transform-origin: center 3px;
        //         transform-origin: center 3px;
        //     }
        // }
    }
    // Card >>
    .card-boxes {
        @media (max-width: $screen-sm-max) {
            padding-left: 0;
            padding-right: 0;
        }
        @media (max-width: $screen-xs-max) {
            padding: 0;
        }
        .main-card-row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            display: -webkit-flex;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            -webkit-align-items: stretch;
            margin: auto -10px;
            .card-column {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px;
                overflow: hidden;
                @media (max-width: $screen-xs-max) {
                    padding: 8px;
                }
                .card-box {
                    position: absolute;
                    top: 10px;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    display: flex;
                    flex-direction: column;
                    // color: $white;
                    overflow: hidden;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    h4,
                    p1,
                    .p1-style {
                        margin-top: 0px;
                        font-size: $font-md;
                        font-weight: $weight-light;
                        line-height: 28px;
                        // .ch_lang & {
                        //     font-weight: $weight-thin;
                        // }
                        @media (max-width: $screen-xs-max) {
                            font-size: 21px;
                        }
                    }
                    h2 {
                        @media (min-width: $screen-md-min) {
                            font-size: 32px;
                        }
                        .ie & {
                            margin-top: 0;
                        }
                    }
                    ol {
                        padding: 0;
                        margin: 0 0 20px 0;
                        counter-reset: olcounter;
                        li {
                            // list-style-type: lower-roman;
                            list-style-position: inside;
                            list-style: none;
                            margin-bottom: 3px;
                            color: $dark-smokey-grey;
                            line-height: 1.5;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:before {
                                content: counter(olcounter, lower-roman)".";
                                counter-increment: olcounter;
                                display: block;
                                position: absolute;
                                left: 0;
                                .rtl & {
                                    margin-left: 20px;
                                    margin-right: 0;
                                    left: auto;
                                    right: 0;
                                }
                            }
                            // &:before {
                            //  content: "";
                            //  width: 10px;
                            //  display: inline-block;
                            // }
                            ol {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                // li {
                                //   // list-style-type: lower-alpha;
                                // }
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                        &[type=a] {
                            li {
                                &::before {
                                    content: counter(olcounter, lower-alpha)".";
                                }
                            }
                        }
                    }
                    ul {
                        margin: 0 0 20px 0;
                        padding: 0;
                        li {
                            background: url(../images/bullet-icon.png) no-repeat 0 8px;
                            list-style: none;
                            margin-bottom: 3px;
                            color: $dark-smokey-grey;
                            line-height: 1.5;
                            @include font-size($font-sm, $font-sm-resp);
                            font-weight: $weight-regular;
                            display: block;
                            padding: 0 25px;
                            width: 100%;
                            position: relative;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .rtl & {
                                background: url(../images/bullet-icon.png) no-repeat 100% 8px;
                            }
                            ul {
                                padding: 0 0 0 15px;
                                margin: 20px 0;
                                .rtl & {
                                    padding: 0 15px 0 0;
                                }
                            }
                        }
                    }
                    &.center-content {
                        justify-content: center;
                        // padding: 0 8px;
                    }
                    @media (max-width: $screen-sm-max) {
                        top: 8px;
                        bottom: 10px;
                        left: 7px;
                        right: 9px;
                        background-size: cover;
                        // background-position: 50%;
                        background-repeat: no-repeat;
                        .rtl & {
                            left: 9px;
                            right: 7px;
                        }
                        @media (max-width: $screen-xs-max) {
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            .rtl & {
                                left: 0px;
                                right: 0px;
                            }
                        }
                    }
                    &.bg-padding {
                        padding: 30px;
                        @media (max-width: $screen-xs-max) {
                            padding: 15px;
                        }
                    }
                    &.blueText {
                        color: $light-moderate-blue;
                        a svg {
                            fill: $light-moderate-blue;
                        }
                    }
                    &.greyText {
                        color: $dark-smokey-grey;
                    }
                }
                .flex-row {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    flex: 1;
                }
                .small-box-horz {
                    // min-height: 100%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
                &.vertical-container {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                // Card Column Media Queries >
                &.big-col {
                    height: 358px;
                    @media (max-width: $screen-sm-max) {
                        height: 330px;
                        &.auto-resp {
                            height: auto;
                            .text-box {
                                position: relative;
                                left: 0px;
                            }
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        height: 350px;
                        &.auto-resp {
                            height: auto;
                            .text-box {
                                position: relative;
                                // left: -8px;
                            }
                        }
                    }
                }
                &.small-col {
                    height: 358px;
                    @media (max-width: $screen-sm-max) {
                        height: 330px;
                    }
                    @media (max-width: $screen-xs-max) {
                        height: 140px;
                    }
                }
                &.col-xs-12 {
                    @media (max-width: $screen-xs-max) {
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }
                    }
                }
                .small-box-horz {
                    @media (max-width: $screen-xs-max) {
                        height: 140px;
                        &:first-child>.small-box {
                            right: 6px;
                            left: 0;
                            .rtl & {
                                right: 0;
                                left: 6px;
                                // padding: 0 8px;
                            }
                        }
                        &:last-child>.small-box {
                            left: 6px;
                            right: 0;
                            .rtl & {
                                left: 0;
                                right: 6px;
                            }
                        }
                    }
                }
            }
        }
        .two-vertical {
            min-height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: -10px;
            margin-right: -10px;
            @media (max-width: $screen-sm-max) {
                margin-left: -8px;
                margin-right: -8px;
            }
            @media (max-width: $screen-xs-max) {
                &:not(.stay-vertical) {
                    flex-direction: row;
                }
                &.stay-vertical {
                    height: 280px;
                }
            }
            .column-vertical {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media (max-width: $screen-xs-max) {
                    &:first-child > .small-box {
                        right: 6px;
                        .rtl & {
                            right: 0px;
                            left: 6px;
                            // padding: 0 8px;
                        }
                    }
                    &:last-child>.small-box {
                        left: 6px;
                        .rtl & {
                            left: 0px;
                            right: 6px;
                        }
                    }
                    &.stay-vertical {
                        // display: block;
                        // flex: none;
                        flex-direction: row;
                        height: 140px;
                        &:first-child {
                            margin-bottom: 12px;
                            @media (max-width: $screen-xs-max) {
                                margin-bottom: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
    a.chevron {
        &::after {
            content: "";
            width: 5px;
            height: 11px;
            background: url($grey-arrow) no-repeat;
            background-size: contain;
            vertical-align: middle;
            display: inline-block;
            margin-left: 12px;
        }
    }
    a,
    .more-url,
    .find-more {
        font-weight: $weight-bold;
    }
}

.statis-box {
    text-align: center;
    color: $white-smoke;
    padding: 50px 12px 12px;
    @media (min-width: $screen-md-max) {
        @include transition(all 1.5s ease-in-out);
    }
    @media (max-width: $screen-xs-max) {
        padding: 45px 12px 12px;
    }
    .icon {
        display: block;
        position: absolute;
        width: 60%;
        left: 50%;
        top: 50%;
        @include transform(translateY(-50%) translateX(-50%));
        // @include transition(all 1.5s ease-in-out);
        .rtl.ie & {
            right: -16px;
        }
        @media(max-width: $screen-sm-max) {
            top: 15px;
            left: 15px;
            .rtl & {
                left: auto;
                right: 15px;
            }
        }
    }
    .big {
        // font-size: $font-hg;
        font-weight: $weight-thin;
        line-height: 1;
        @include transition(all 1.5s ease-in-out);
        opacity: 0;
        @media (max-width: $screen-xs-max) {
            line-height: 24px;
        }
        .rtl & {
            line-height: 1.2;
            font-size: 20px;
        }
    }
    p {
        @include font-size($font-xs, 13px);
        @include transition(all 1.5s ease-in-out);
        line-height: 16px!important;
        opacity: 0;
        @media (max-width: $screen-xs-max) {
            line-height: 14px!important;
        }
        .rtl & {
            font-size: 12px;
        }
    }
    &.hovered {
        .icon {
            .ie & {
                top: 75%;
            }
            .safari & {
                top: 70px;
                left: 85px;
                right: auto;
            }
            .safari.rtl & {
                left: auto;
                right: 85px;
            }
        }
        .big {
            // opacity: 1;
            @include animation(opacityIn 0.5s 1);
            @include animation-delay(1.2s);
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            .ch_lang & {
                margin-bottom: 6px;
            }
        }
        p {
            // opacity: 1;
            @include animation(opacityIn 0.5s 1);
            @include animation-delay(1.2s);
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            .ja_lang & {
                font-weight: $weight-thin;
            }
        }
    }
    @media (max-width: $screen-sm-max) {
        .icon {
            display: block;
            position: absolute;
            height: 30px;
            width: auto;
            top: 10px;
            left: 15px;
            @include transform(translateY(0) translateY(0%));
            .rtl & {
                left: auto;
                right: 15px;
            }
        }
        .big {
            opacity: 1;
        }
        p {
            opacity: 1;
        }
    }
    a {
        font-weight: $weight-bold;
    }
}

.info-box {
    text-align: center;
    color: $white-smoke;
    padding: 90px 35px 35px;
    @media (max-width: $screen-xs-max) {
        padding: 65px 35px 35px;
    }
    .icon {
        display: block;
        position: absolute;
        height: 45px;
        top: 25px;
        left: 25px;
        .rtl & {
            left: auto;
            right: 25px;
        }
        @media (max-width: $screen-sm-max) {
            top: 20px;
            left: 20px;
            .rtl & {
                left: auto;
                right: 20px;
            }
        }
        @media (max-width: $screen-xs-max) {
            position: static;
            width: 50px;
            margin-top: 15px;
            margin-bottom: 5px;
            margin-left: -20px;
            .rtl & {
                margin-left: 0px;
                margin-right: -20px;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 20px, ;
        font-weight: 400;
        margin-bottom: 20px;
        // .rtl & {
        //     line-height: 37.5px;
        // }
    }
    h4,
    p1,
    .p1-style {
        margin-top: 0px;
        @include font-size($font-md, 21px);
        font-weight: 100;
        line-height: 28px;
        // .ch_lang & {
        //     font-weight: $weight-thin;
        // }
    }
    a {
        font-weight: $weight-bold;
    }
}

.img-box {
    overflow: hidden;
    display: flex;
    position: relative;
    &:not(.card-box) {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        // @media (max-width: $screen-sm-max) {
        //   background-size: contain;
        // }
        // @media (max-width: $screen-xs-max) {
        //   background-size: contain;
        // }
    }
    @media (max-width: $screen-xs-max) {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
    }
    img {
        // min-height: 100%;
        // height: 100%;
        // width: 100%;
        min-width: 100%;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        @media (max-width: $screen-sm-max) {
            // max-height: 100%;
            min-width: 100%;
            .ch_lang &,
            .ja_lang & {
                max-height: 100%;
            }
        }
    }
}

.text-box {
    justify-content: center;
    &.toTop {
        justify-content: flex-start;
    }
    h1,
    h2,
    h3 {
        margin-top: 0px;
        @include font-size(32px, 26px);
    }
    h2 {
        color: $dark-smokey-grey;
    }
    h3 {
        // @extend .font-size-lg;
        @include font-size($font-lg, $font-lg-resp);
    }
    p {
        @include font-size($font-sm, $font-sm-resp);
        margin-bottom: 20px;
        font-weight: $weight-regular;
        line-height: 20px;
        color: $dark-smokey-grey;
        &:last-child {
            margin-bottom: 0;
        }
        &.lead,
        h5 {
            // @extend .font-md;
            @include font-size($font-md, $font-md-resp);
        }
        @media (min-width: $screen-xs-max) {
            .ch_lang & {
                line-height: 1.3;
            }
        }
        .rtl & {
            line-height: 1.1;
        }
    }
    .find-more {
        display: inline-block;
        @include font-size($font-sm, $font-sm-resp);
        font-weight: $weight-bold;
        margin-top: 20px;
        svg {
            margin-left: 10px;
            vertical-align: middle;
            width: 5px;
            height: 11px;
            .rtl & {
                margin-left: 0px;
                margin-right: 10px;
                width: 11px;
                @include transform(rotate(180deg));
            }
            .firefox.rtl & {
                @include transform(rotate(90deg));
                @include transform-origin(center);
            }
        }
        // &::after {
        //     content: "";
        //     width: 5px;
        //     height: 5px;
        //     display: inline-block;
        //     background: url($grey-arrow) no-repeat;
        // } 
    }
    a {
        font-weight: $weight-bold;
    }
}

.quote-box {
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $dark-smokey-grey;
        margin-bottom: 0;
    }
    h2 {
        margin-top: 0px;
        font-size: $font-lg;
        color: $dark-smokey-grey;
        line-height: 1.2;
        @media (max-width: $screen-xs-max) {
            font-size: 26px;
            margin-top: 20px;
        }
    }
    h4,
    p1,
    .p1-style {
        // h4 in cards 
        @include font-size($font-md, 21px);
        font-weight: 100;
        line-height: 28px;
        margin-top: 0;
        // @extend .font-md;
        // @include font-size($font-md, $font-md-resp);
        // font-weight: $weight-light;
        // line-height: 1.2;
        // margin-right: 20px;
        .rtl & {
            // margin-right: 0px;
            // margin-left: 20px;
            line-height: 1.1;
        }
        .ch_lang & {
            // font-weight: $weight-thin;
            line-height: 1.4;
        }
        .ja_lang & {
            font-weight: $weight-thin;
            line-height: 1.25;
        }
    }
    h4,
    p1,
    .p1-style {
        margin-top: 0px;
    }
    h5 {
        line-height: 1.2;
    }
    .byName {
        margin-top: 10px;
        line-height: 1.2;
        @include font-size($font-sm, $font-sm-resp);
        font-weight: $weight-bold;
        span {
            display: block;
        }
    }
    &.cloudy {
        p {
            // color: $cloud;
            line-height: 20px;
            color: $dark-smokey-grey;
        }
    }
    a {
        font-weight: $weight-bold;
    }
}

.download-box {
    color: $white-smoke;
    .download-box-bg {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
    }
    .box-content {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 10px;
        a {
            font-weight: $weight-bold;
            &:focus,
            &:hover {
                color: $white;
            }
        }
    }
    .big {
        @include font-size($font-sm, $font-sm-resp);
        font-weight: $weight-bold;
        line-height: 1;
        margin-bottom: 5px;
    }
    p {
        @include font-size($font-sm, $font-sm-resp);
        @include opacity(0.7);
        line-height: 1.1!important;
        // color: $dark-smokey-grey;
    }
}

.vertical-card {
    padding-right: 0;
    padding-left: 0;
    .card-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }
    .card-boxes {
        padding-right: 0;
        padding-left: 0;
    }
    @media (max-width: $screen-sm-max) {
        // .card-boxes {
        //   .main-card-row {
        //     .card-column {
        //       // padding-right: 0;
        //       // padding-left: 0;
        //     }
        //   }
        // }
        .card-wrapper {
            padding: 0 34px;
        }
        .card-boxes {
            padding: 0 20px;
        }
    }
    .card-text {
        padding: 10px 0 0 0;
    }
    a {
        font-weight: $weight-bold;
    }
}