$screen-md: 1024px;
.map-container {
    position: relative;
    background-color: $off-white;
    padding: 50px 0;
    .map-row {
        margin-bottom: 20px;
        @media only screen and (max-width: $screen-xs-max) {
            margin: 0;
            .slick-track {
                display: table;
                .slick-slide {
                    float: none;
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }
        .slick-slide {
            &:focus {
                outline: 0;
            }
        }
        .map-text {
            font-size: $font-sm;
            margin: 40px 0 0 40px;
            color: $dark-smokey-grey;
            .rtl & {
                text-align: right;
                margin: 40px 40px 0 0;
            }
        }
        .map-location {
            position: relative;
            color: $dark-smokey-grey;
            @media only screen and (max-width: $screen-xs-max) {
                display: table;
                margin: 0 auto;
                float: none;
                width: auto;
            }
            .location-header {
                @media only screen and (max-width: $screen-xs-max) {
                    // text-align: center; 
                }
                .location-pin {
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    padding-top: 3px;
                    background-color: #51859a;
                    color: $white;
                    text-align: center;
                    border-radius: 50%;
                    font-size: $font-sm;
                    font-weight: $weight-bold;
                    .rtl & {
                        right: 16px;
                    }
                    .ch_lang & {
                        font-size: 14px;
                    }
                }
                .location-name {
                    margin: 0 0 10px 40px;
                    display: inline-block;
                    font-weight: $weight-regular;
                    .rtl & {
                        margin: 0 40px 10px 0;
                    }
                    @media only screen and (max-width: $screen-xs-max) {
                        font-size: $font-md-resp;
                        margin: 0 25px 15px 40px;
                    }
                }
            }
            .location-details {
                margin: 0 0 0 40px;
                font-size: $font-sm;
                .rtl & {
                    margin: 0 40px 0 0;
                }
                .ch_lang & {
                    font-size: 14px;
                }
                .location-desc {
                    margin: 0;
                    font-weight: $weight-bold;
                    font-size: $font-sm;
                    // @media only screen and (max-width: $screen-xs-max) {
                    //   font-size: $font-sm;  
                    // }
                }
                // .location-address {
                //   letter-spacing: -1px;
                // }
                p {
                    margin: 0;
                }
            }
        }
    }
    .general-map-arrow {
        position: absolute;
        top: 50%;
        bottom: auto;
        cursor: pointer;
        z-index: 20;
        @media only screen and (max-width: $screen-xs-max) {
            top: 40%;
        }
    }
    .map-slider-arrow-next {
        right: -10px;
        left: auto;
        @media only screen and (max-width: $screen-md) {
            right: -3px;
        }
        @media only screen and (max-width: $screen-sm-max) {
            right: 0;
        }
        @media only screen and (max-width: $screen-xs-max) {
            right: 27px;
        }
    }
    .map-slider-arrow-prev {
        right: auto;
        left: -30px;
        @media only screen and (max-width: $screen-md) {
            left: -3px;
        }
        @media only screen and (max-width: $screen-sm-max) {
            left: 0;
        }
        @media only screen and (max-width: $screen-xs-max) {
            left: 27px;
        }
    }
}

.location-marker.mapboxgl-marker {
    background: #4E88A4;
    color: #fff;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    padding: 2px;
    text-align: center;
}