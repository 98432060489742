.latest-news-container {
    padding: 25px 35px 35px 35px;
    position: relative;
    top: -60px;
    background-color: #e3eaf0;
    @media (max-width: $screen-xs-max) {
        padding: 25px 25px 35px 25px;
    }
    .slider-header {
        margin-top: 0;
        margin-bottom: 25px;
        display: inline-block;
        color: $dark-smokey-grey;
    }
    .latest-news-slider {
        margin: 0 -5px;
        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
        .news-slide {
            position: relative;
            overflow: hidden;
            padding: 0 7.5px;
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
            &:focus {
                outline: 0;
            }
            &:hover {
                .block-details {
                    @include transform(translateY(0));
                    .read-more {
                        @include opacity(1);
                    }
                }
            }
            img {
                width: 100%;
            }
            .block-details {
                position: absolute;
                width: 95%;
                bottom: 0px;
                left: 7px;
                right: 7px;
                padding: 0 20px 20px;
                color: $white;
                @include transition(0.5s);
                @include transform(translateY(37px));
                background: transparent;
                /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Firefox 3.6 to 15 */
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* Standard syntax */
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    left: auto;
                    right: auto;
                }
                .rtl & {
                    @media (max-width: $screen-sm-max) {
                        direction: rtl;
                    }
                }
            }
            .date {
                line-height: 2em;
                display: inline-block;
                font-size: 16px;
                // .rtl & {
                //     float: right;
                // }
            }
            p {
                line-height: 1.1;
                font-size: 24px;
            }
            .read-more {
                @include opacity(0);
                @include transition(0.5s);
                font-weight: $weight-bold;
                @include font-size($font-sm, $font-sm-resp);
                display: inline-block;
                &::after {
                    content: "";
                    width: 5px;
                    height: 11px;
                    background: url($white-arrow) no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 12px;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 12px;
                        @include transform(rotate(180deg));
                    }
                }
            }
            a {
                &:hover {
                    color: unset;
                }
            }
            &:hover {
                img {
                    @include filter(blur(2px));
                }
            }
        }
        .slick-dots {
            top: -50px;
            right: 4px;
            left: auto;
            bottom: auto;
            width: auto;
            .rtl & {
                right: auto;
                left: 4px;
            }
            li {
                margin: 0 0 0 10px;
                .rtl & {
                    margin: 0 10px 0 0;
                }
                button {
                    &:before {
                        font-size: 60px;
                    }
                    &:hover,
                    &:focus {
                        &:before {
                            color: #000;
                            @include opacity(0.25);
                        }
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            color: #4e88a4;
                        }
                        &:hover,
                        &:focus {
                            &:before {
                                color: #4e88a4;
                                @include opacity(0.75);
                            }
                        }
                    }
                }
            }
        }
        .general-news-arrow {
            position: absolute;
            bottom: 50%;
            top: auto;
            cursor: pointer;
            .rtl & {
                @include transform(rotate(180deg));
            }
        }
        .news-slider-arrow-next {
            right: -24.5px;
            left: auto;
            .rtl & {
                left: -24.5px;
                right: auto;
            }
        }
        .news-slider-arrow-prev {
            left: -25px;
            right: auto;
            .rtl & {
                right: -25px;
                left: auto;
            }
        }
    }
    .all-news-link {
        font-weight: $weight-medium;
        color: $moderate-blue;
        float: right;
        margin: 25px 0 0 0;
        svg {
            margin: 0 0 0 10px;
            .rtl & {
                margin: 0 10px 0 0;
                @include transform(rotate(180deg));
            }
            .rtl.firefox & {
                @include transform(rotate(90deg));
                overflow: visible;
                margin-bottom: -9px;
            }
        }
        .rtl & {
            float: left;
        }
    }
}