// Links Overrides >
$link-hover-decoration: none;
$link-color: inherit;
$link-hover-color: $dark-smokey-grey;

// Fonts Overrides >
$font-family-base: "karbon"; 
$font-size-base: 14px;
$font-color-base: $dark-smokey-grey;

// Grid
$grid-float-breakpoint: 992px;
 

.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
  }
}