.ceo-people,
.other-people {
    padding: 80px 0 40px;
    @media(max-width: $screen-sm-max) {
        padding: 60px 0 35px;
    }
    .people-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .col-xs-6:nth-child(odd) {
            @media (max-width: $screen-xs-max) {
                padding-right: 5px;
                .rtl & {
                    padding-right: 15px;
                    padding-left: 5px;
                }
            }
        }
        .col-xs-6:nth-child(even) {
            @media (max-width: $screen-xs-max) {
                padding-left: 5px;
                .rtl & {
                    padding-left: 15px;
                    padding-right: 5px;
                }
            }
        }
        .person {
            margin-bottom: 40px;
            @media (max-width: $screen-sm-max) {
                margin-bottom: 25px;
            }
            h2 {
                font-size: 24px;
                font-weight: $weight-regular;
                color: $dark-smokey-grey;
                margin-top: 30px;
                @media(max-width: $screen-xs-max) {
                    font-size: 18px;
                    margin-top: 20px;
                }
            }
            p {
                font-size: 16px;
                font-weight: $weight-regular;
                color: $dark-smokey-grey;
                margin-bottom: 0;
                @media(max-width: $screen-xs-max) {
                    font-size: 14px;
                    font-weight: $weight-medium;
                }
            }
            a {
                font-size: 16px;
                font-weight: $weight-medium;
                color: $dark-moderate-blue;
                margin-top: 15px;
                display: inline-block;
                @media(max-width: $screen-xs-max) {
                    margin-top: 10px;
                }
            }
        }
    }
}