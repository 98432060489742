$slide-hover-move: 15px;
// Columns Spaces >>
.clear-padding-left {
    padding-left: 0;
}

.clear-padding-right {
    padding-right: 0;
}

// Columns Spaces >>
// Main Slider Container >>
.main-slider-container {
    background-color: #d1cbc2;
    padding: 50px 0;
    // position: relative;
    // top: -60px;
    .slick-list {
        @media (max-width: $screen-sm-max) {
            padding: 0!important;
            .rtl & {
                margin-right: -2px;
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        padding: 25px 12px;
    }
    // Slider Arrows >>
    .general-main-arrow {
        position: absolute;
        top: 46%;
        @media (max-width: $screen-sm-max) {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .main-slider-arrow-next {
        right: -4.5%;
        .rtl & {
            right: -24%;
        }
        @media (max-width: $screen-md-max) {
            right: -2%;
            .rtl & {
                right: -22%;
            }
        }
        @media (max-width: $screen-sm-max) {
            right: -4%;
            .rtl & {
                right: -39%;
            }
        }
        @media (max-width: $screen-xs-max) {
            right: -7%;
            .rtl & {
                right: -7%;
            }
        }
        @media (max-width: 320px) {
            right: -8.5%;
            .rtl & {
                right: -8.5%;
            }
        }
    }
    .main-slider-arrow-prev {
        left: -25%;
        right: auto;
        .rtl & {
            left: -4%;
        }
        @media (max-width: $screen-md-max) {
            left: -22%;
            .rtl & {
                left: -2%;
            }
        }
        @media (max-width: $screen-sm-max) {
            left: -200px;
            .rtl & {
                left: -30px;
            }
        }
        @media (max-width: $screen-xs-max) {
            left: -7%;
            .rtl & {
                left: -7%;
            }
        }
        @media (max-width: 320px) {
            left: -8.5%;
            .rtl & {
                left: -8.5%;
            }
        }
    }
    // Slider Arrows >>
    .rows-wraper {
        position: relative;
    }
    // Static Right Row >>
    .right-side-row {
        position: absolute;
        top: 0;
        left: 0px;
        right: 0;
        bottom: 0;
        z-index: 12;
        padding: 0;
        // @media (max-width: $screen-sm-max) {
        //     width: 165px;
        //     height: 312px;
        //     top: 0;
        // }
        @media (max-width: $screen-xs-max) {
            position: relative;
            width: 100%;
            height: auto;
            margin-top: 8px;
        }
        .vertical-row {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 0;
            @media (max-width: $screen-xs-max) {
                position: relative;
            }
            .vertical-column {
                // @media (max-width: $screen-xs-max) {
                //     height: 155px;
                // }
                img {
                    width: 100%;
                    height: 100%;
                    // .safari & {
                    //     height: inherit;
                    // }
                    @media (max-width: $screen-xs-max) {
                        position: relative;
                    }
                }
            }
        }
        .boxes-holder {
            padding: 0;
            padding-right: 10px;
            .rtl & {
                padding-right: 0px;
                padding-left: 10px;
            }
            @media (max-width: $screen-sm-max) {
                padding-right: 0;
                .rtl & {
                    padding-left: 0;
                }
            }
            @media (width: 800px) {
                padding-right: 0;
            }
            @media (max-width: $screen-xs-max) {
                flex-direction: row;
                padding: 0;
                .rtl & {
                    padding: 0;
                }
            }
        }
        .download-block {
            position: relative;
            overflow: hidden;
            @media (min-width: $screen-md-min) {
                padding: 0 0 20px;
            }
            @media (max-width: $screen-sm-max) {
                padding: 0 0 12px;
            }
            @media (max-width: $screen-xs-max) {
                padding: 0;
                height: 146px;
                // flex-direction: column;
                margin: 0 4px 0 0;
                .rtl & {
                    margin: 0 0 0 4px;
                }
            }
            .block-details {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 10px;
                color: $white;
                @media (min-width: $screen-md-min) {
                    padding: inherit;
                }
                @media (max-width: $screen-sm-max) {
                    padding: 0 0 5px;
                    left: 10px;
                    right: 10px;
                    bottom: 10px;
                }
                @media (max-width: $screen-xs-max) {
                    padding: 0px;
                }
                p {
                    line-height: 20px;
                    font-size: 16px;
                    margin: 0 0 4px;
                    @media (max-width: $screen-xs-max) {
                        line-height: 14px;
                    }
                }
            }
            img {
                width: 100%;
                height: 100%;
                @media (min-width: $screen-sm-min) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: inherit;
                }
            }
            h3 {
                @include font-size($font-sm, $font-xs);
                margin: 0 0 5px 0;
                font-weight: $weight-bold;
                line-height: 1;
                .ja_lang & {
                    font-weight: $weight-thin;
                    line-height: 1.3;
                }
            }
            a {
                &:focus {
                    color: $white;
                }
                p {
                    @include font-size($font-sm, $font-xs);
                    @include opacity(0.7);
                    line-height: 1.1;
                    margin-bottom: 0;
                    .ch_lang & {
                        // font-weight: $weight-thin;
                        line-height: 1.4;
                    }
                    .ja_lang & {
                        font-weight: $weight-thin;
                        line-height: 1.3
                    }
                }
            }
            a {
                &:hover {
                    color: $white;
                }
            }
            @media (max-width: $screen-sm-max) {
                .download-icon {
                    // top: 10px;
                    left: 10px;
                    right: auto;
                    .rtl & {
                        right: 10px;
                        left: auto;
                    }
                }
            }
            // @media (max-width: $screen-xs-max) {
            //     .download-icon {
            //         top: 3px;
            //     }
            // }
        }
        .social-block {
            width: 100%;
            position: relative;
            @media (max-width: $screen-xs-max) {
                // flex-direction: column;
                margin: 0 0 0 4px;
                .rtl & {
                    margin: 0 4px 0 0;
                }
            }
            .vertical-row {
                width: 100%;
                position: absolute;
            }
            .social-row {
                text-align: center;
                svg {
                    fill: $white;
                }
                .flex-wrapper {
                    min-height: 100%;
                    flex: 1;
                    @media (max-width: $screen-sm-max) {
                        flex-wrap: nowrap;
                    }
                    .flex-item {
                        flex: 1;
                        justify-content: center;
                        text-align: center;
                        padding: 0;
                        a {
                            line-height: 1;
                        }
                        &:hover {
                            svg {
                                display: none;
                            }
                            .social-details {
                                display: block;
                                @media (min-width: $screen-md-min) {
                                    .social-responsive {
                                        display: none;
                                    }
                                }
                                @media (max-width: $screen-sm-max) {
                                    a[target=_blank] {
                                        display: none;
                                    }
                                }
                            }
                        }
                        @media (max-width: $screen-sm-max) {
                            &:focus {
                                svg {
                                    display: none;
                                }
                                .social-details {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .light-link {
                    background-color: $light-blue;
                }
                .darker-link {
                    background-color: $dark-blue;
                }
                .social-details {
                    display: none;
                    cursor: default;
                    span {
                        font-size: 22px;
                        color: $white;
                        display: block;
                        &:nth-child(2) {
                            font-size: $font-xs;
                        }
                        @media (max-width: $screen-sm-max) {
                            font-size: 16px;
                        }
                        .ch_lang & {
                            font-size: 16px;
                            padding-bottom: 6px;
                        }
                    }
                }
            }
        }
    }
    // Static Right Row >>
    .main-slider {
        display: flex;
        padding-right: 0;
        padding-left: 0;
        .rtl & {
            padding-left: 0;
            padding-right: 0;
        }
        @media (width: 800px) {
            padding-left: 0;
        }
        @media (max-width: $screen-sm-max) {
            padding-right: 10px;
            padding-left: 10px;
            display: block;
            // .rtl & {
            //     margin-right: 24.5%;
            // }
        }
        @media (max-width: $screen-xs-max) {
            padding: 0;
            margin-left: 0;
            // .rtl & {
            //     margin-right: 0;
            // }
        }
        // .slick-list {
        //     @media (min-width: $screen-md-min) {
        //         padding-top: $slide-hover-move;
        //     }
        // }
        .flex-item {
            overflow: hidden;
        }
        .main-slider-wrapper {
            @media (min-width: $screen-md-min) {
                margin-left: 0;
                margin-right: -10px;
                .rtl & {
                    margin-left: -10px;
                    margin-right: 0;
                }
            }
        }
        .slick-slide {
            outline: none;
            padding: 0 10px;
            .rtl & {
                @include transform(translateX(-8px));
            }
            @media (max-width: $screen-sm-max) {
                padding: 0;
                .rtl & {
                    padding: 0;
                }
            }
            @media (width: 800px) {
                padding: 0 20px 0 0;
                .rtl & {
                    padding: 0 0 0 20px;
                }
            }
            @media (max-width: $screen-xs-max) {
                padding: 0 8px;
                @include transform(translateX(-6px));
                .rtl & {
                    padding: 0 10px;
                    @include transform(translateX(-14px));
                }
            }
            *[class*=col-] {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        // Main Slide Effect >>
        .slide-effect {
            @media (min-width: $screen-md-min) {
                @include transition(ease transform .5s);
                // width: auto!important;
                &:hover {
                    .main-slide img {
                        @include filter(blur(2px));
                    }
                }
            }
        }
        // Main Slide Effect >>
        // Main Slide >>
        .main-slide {
            overflow: hidden;
            position: relative;
            @media (max-width: $screen-sm-max) {
                height: 100%;
            }
            &:hover {
                .block-details {
                    @include transform(translateY(0));
                    .read-more {
                        @include opacity(1);
                    }
                }
            }
            .block-details {
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                padding: 0 20px 20px;
                color: $white;
                @include transition(0.5s);
                @include transform(translateY(37px));
                background: transparent;
                /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Firefox 3.6 to 15 */
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* Standard syntax */
                .rtl & {
                    @media (max-width: $screen-sm-max) {
                        direction: rtl;
                    }
                }
            }
            img {
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                .safari & {
                    min-height: inherit;
                    @media (max-width: $screen-sm-max) {
                        min-height: 100%
                    }
                }
            }
            span {
                line-height: 2em;
                display: inline-block;
                font-size: $font-sm;
                @media (max-width: $screen-sm-max) {
                    font-size: $font-xs;
                }
            }
            .title {
                font-weight: $weight-bold;
            }
            .date {
                float: right;
                .rtl & {
                    float: left;
                }
            }
            p {
                line-height: 1.1;
                font-size: $font-md;
                @media (max-width: $screen-sm-max) {
                    font-size: 21px
                }
                .ch_lang & {
                    // font-weight: $weight-thin;
                    line-height: 1.4;
                }
                .ja_lang & {
                    font-weight: $weight-light;
                    line-height: 1.25;
                }
            }
            .read-more {
                @include opacity(0);
                @include transition(0.5s);
                font-weight: $weight-bold;
                @include font-size($font-sm, $font-sm-resp);
                display: inline-block;
                &::after {
                    content: "";
                    width: 5px;
                    height: 11px;
                    background: url($white-arrow) no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 12px;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 12px;
                        @include transform(rotate(180deg));
                    }
                }
            }
            a {
                &:hover {
                    color: unset;
                }
            }
        }
        // Main Slide >>
        // News Slide >>
        .news-item {
            max-height: 350px;
            text-overflow: ellipsis;
            &:hover {
                .read-more {
                    @include opacity(1);
                }
            }
        }
        .news-media {
            margin-bottom: 20px;
            img {
                max-width: 100%;
            }
        }
        .news-block {
            text-overflow: ellipsis;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            color: $dark-smokey-grey;
            .rtl & {
                @media (max-width: $screen-sm-max) {
                    direction: rtl;
                }
            }
            p.headingP {
                margin: 0 0 5px;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-bold;
            }
            .date {
                @include font-size($font-xs, $font-xs-resp);
            }
            p {
                font-size: 19px;
                margin-top: 12px;
                padding: 0px;
                line-height: 1.1;
                -o-text-overflow: ellipsis;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                // -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                word-break: normal;
                overflow: hidden;
                width: 170px;
                @media (max-width: $screen-sm-max) {
                    font-size: 18px;
                }
                @media (max-width: $screen-xs-max) {
                    font-size: 16px;
                }
                .ch_lang & {
                    // font-weight: $weight-thin;
                    line-height: 1.4;
                }
                .ja_lang & {
                    line-height: 1.25;
                    font-weight: $weight-thin;
                }
            }
            .read-more {
                @include opacity(0);
                @include transition(0.5s);
                font-weight: $weight-bold;
                @include font-size($font-sm, $font-sm-resp);
                display: inline-block;
                &::after {
                    content: "";
                    width: 5px;
                    height: 11px;
                    background: url($grey-arrow) no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 12px;
                    .rtl & {
                        margin-left: 0;
                        margin-right: 12px;
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
        // News Slide >>
    }
}

// Main Slider Container >>
// Responsive >
@media (max-width: $screen-sm-max) {
    .rows-wraper>.row {
        margin: 0;
    }
    .main-slider {
        // width: 100%;
        position: relative;
        // padding-left: 165px;
        // margin-left: 0px;
        // .rtl & {
        //     padding-left: 0;
        //     padding-right: 165px;
        //     margin-left: 0px;
        //     margin-right: 0px;
        //     flex-direction: row-reverse;
        // }
    }
    .main-slider-wrapper {
        white-space: normal;
        flex-wrap: nowrap;
        padding-right: 7.5px;
        margin: 0;
        .rtl & {
            padding-right: 0;
            padding-left: 7.5px;
            flex-direction: row-reverse;
        }
    }
    .main-slider-item {
        padding-left: 7.5px;
        padding-right: 7.5px;
        &.glance-item {
            width: 320px;
            height: 312px;
            @media (max-width: $screen-sm-max) {
                width: 327px;
            }
            @media (width: 800px) {
                width: 350px;
            }
            @media (max-width: $screen-xs-max) {
                width: 335px;
            }
            @media(max-width: 414px) {
                width: 365px;
                margin-right: 0;
            }
            @media(max-width: 375px) {
                width: 323px;
            }
            @media (max-width: 320px) {
                width: 267px;
            }
        }
        &.news-item {
            width: 165px;
            height: 312px;
            @media (max-width: $screen-sm-max) {
                width: 180px;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .main-slider {
        padding-left: 0!important;
        padding-right: 0;
        margin-left: 0;
        .rtl & {
            padding-right: 0px!important;
            margin-right: 0;
        }
    }
    .main-slider-item {
        padding: 0;
        margin-right: 8px;
        margin-left: 0;
        .rtl & {
            padding: 0;
            margin-right: 0;
            margin-left: 0px;
        }
    }
}