aside {
    @media (max-width: $screen-sm-max) {
        margin-top: 30px;
    }
    @media (max-width: $screen-sm-max) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

.aside-content {
    top: 90px;
    z-index: 100;
    max-width: 100%;
    //   margin-left: 15px;
    @media (max-width: $screen-sm-max) {
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        width: 100% !important;
    }
    @media (min-width: $screen-md-min) {
        &.affix {
            &.abs-bottom {
                position: absolute !important;
                bottom: 0 !important;
                top: auto !important;
                margin-bottom: 20px;
            }
        }
    }
}

.for-inq {
    padding: 34px 24px;
    background-color: $lighter-grey;
    h4 {
        color: $dark-smokey-grey;
        font-weight: $weight-medium;
        @include font-size($font-sm, $font-sm-resp);
        margin-bottom: 25px;
        margin-top: 0;
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    .data-row {
        display: flex;
        flex-wrap: wrap;
        p {
            font-size: 16px;
        }
        &:first-of-type {
            margin-bottom: 15px;
            .value {
                line-height: 1;
            }
        }
        .icon {
            display: flex;
            vertical-align: middle;
            svg {
                @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    width: 22px;
                    height: 22px;
                }
            }
        }
        .value {
            display: table-cell;
            vertical-align: middle;
            padding-left: 25px;
            font-size: $font-sm;
            font-family: "karbon";
            color: $dark-moderate-blue;
            @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                font-size: 14px;
                padding-left: 20px;
                line-height: 1.4;
            }
            .rtl & {
                padding-left: 0;
                padding-right: 25px;
                direction: ltr;
                @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    padding-right: 20px;
                }
            }
        }
    }
}