.footer-nav-section {
    @extend .main-section-top-bottom-padding;
    background-color: $white-smoke;
}

.footer-right-section {
    @extend .main-section-top-bottom-padding;
    .rights {
        margin: 0 0 15px 0;
        color: $dark-smokey-grey;
        opacity: 80%;
    }
    @media (max-width: $screen-xs-max) {
        padding-left: 20px;
        padding-right: 20px;
    }
    .bottom-list {
        @extend .clear-padding,
        .clear-margin;
        .bottom-list-split {
            display: inline-block;
        }
        li {
            padding: 0 50px 0 0;
            display: inline-block;
            .rtl & {
                padding: 0 0 0 50px;
            }
            a {
                color: $dark-smokey-grey;
            }
        }
    }
    // media query for Tablets >>
    @media only screen and (max-width: $screen-sm-max) {
        .rights {
            font-size: 14px;
            margin: 0 0 40px 0;
        }
        .bottom-list {
            font-size: 14px;
            .bottom-list-split {
                width: 49%;
                display: inline-block;
            }
            li {
                padding: 0 25px 0 0;
                margin: 0 0 30px 0;
                display: block;
                .rtl & {
                    padding: 0;
                    &:first-child {
                        padding: 0;
                    }
                    &:last-child {
                        padding: 0;
                    }
                }
                &:last-child {
                    padding: 0;
                    margin: 0;
                }
                a {
                    color: $dark-smokey-grey;
                }
            }
        }
    }
    // media query for Mobiles >>
    @media only screen and (max-width: $screen-xs-max) {
        .rights {
            font-size: 12px;
            margin: 0 0 20px 0;
            padding: 0;
        }
        .custom_bottom_menu {
            padding: 0;
            .bottom-list {
                font-size: 12px;
                li {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}