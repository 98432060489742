// Variables >
$navbar-height: 69px;
// Classes >
.mCS-dir-rtl>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    .rtl & {
        margin-left: 1px;
    }
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 1px;
}

.nav-scroll-override {
    &:not(.mCS_no_scrollbar):not(.navbar-nav) {
        .mCustomScrollBox:not(.mCS_no_scrollbar_y) {
            margin-right: -24px;
            max-width: none;
            .rtl & {
                margin-left: -24px;
                margin-right: 0;
            }
            .mCSB_container:not(.mCS_no_scrollbar_y) {
                white-space: nowrap;
                margin-right: 56px;
                .rtl & {
                    margin-left: 56px;
                    margin-right: 0;
                }
            }
        }
    }
    &.navbar-nav:not(.mCS_no_scrollbar) {
        @media (max-width: $screen-sm-max) {
            .mCustomScrollBox:not(.mCS_no_scrollbar_y) {
                margin-right: -24px;
                max-width: none;
                .rtl & {
                    margin-left: -24px;
                    margin-right: 0;
                }
                .mCSB_container:not(.mCS_no_scrollbar_y) {
                    white-space: nowrap;
                    margin-right: 56px;
                    .rtl & {
                        margin-left: 56px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .mCSB_dragger_bar {
        background-color: $med-grey;
        opacity: 0.68;
    }
    .mCSB_draggerRail {
        opacity: 0;
    }
}

// Navigation Container
.main-nav-container {
    position: absolute;
    z-index: 99999;
    top: 54px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    @include opacity(0.9);
    &.affix {
        position: fixed;
        width: 100%;
        top: 0;
        background-color: $white;
    }
    @media (max-width: $screen-xs-max) {
        top: 12px;
    }
    .navbar-toggle {
        position: absolute;
        left: 37px;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0;
        .rtl-disable & {
            left: auto;
            right: 37px;
        }
    }
    .icon-bar {
        width: 32px;
        height: 3px;
        background-color: $dark-moderate-blue;
    }
}

.main-header.wide {
    height: $navbar-height;
    .main-nav-container {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: $white;
        // box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.16);
    }
}

// Navigation
.nav-holder {
    float: left;
    position: relative;
    // overflow: hidden;
    .rtl-disable & {
        float: right;
    }
    @media (max-width: $screen-sm-max) {
        overflow-x: hidden;
    }
}

.main-nav {
    height: $navbar-height;
    padding: 0px;
    border-radius: 0;
    background-color: $white;
    margin: 0;
    border: none;
    .navbar-header {
        float: right;
        .rtl-disable & {
            float: left;
        }
        .navbar-brand {
            padding: 18px 20px;
            img {
                width: 188px;
                height: 34px;
            }
            @media (max-width: $screen-sm-max) {
                padding: 20px 24px;
                img {
                    width: 133px;
                    height: 25px;
                }
            }
        }
    }
    ul.navbar-nav {
        @extend .nav-scroll-override;
        &.icons {
            @media (max-width: $screen-sm-max) {
                padding: 0;
            }
        }
        .rtl & {
            padding: 0;
        }
        li.navItem {
            height: $navbar-height;
            padding: 12px 0;
            position: relative;
            display: block;
            float: left;
            .rtl-disable & {
                float: right;
            }
            .caret {
                display: none;
            }
            &.dropdown {
                >a:after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 9px;
                    border: none;
                    margin-left: 10px;
                    background: url($lightGrey-arrow) no-repeat;
                    background-size: auto 100%;
                    .rtl-disable & {
                        margin-left: 0;
                        margin-right: 10px;
                        @include transform(rotate(180deg));
                    }
                }
                >a:before {
                    @include transform(rotate(180deg));
                }
                &:not(.languages) {
                    >a:after {
                        @media (max-width: $screen-sm-max) {
                            margin-top: 3px;
                            margin-left: 42px;
                            position: absolute;
                            .rtl & {
                                margin-left: 0;
                                margin-right: 42px;
                            }
                        }
                    }
                }
                &.open {
                    >a:after {
                        @include transform(rotate(90deg));
                    }
                    >a:before {
                        @include transform(rotate(90deg));
                    }
                    >.dropdown-menu {
                        @include animation (opacityIn 0.5s);
                    }
                }
            }
            a,
            a:focus,
            a:hover {
                background: none;
            }
            >a {
                color: $dolphin-grey;
                // font-size: $font-xs;
                @include font-size($font-xs, $font-sm-resp);
                font-weight: $weight-bold;
                padding-left: 30px;
                padding-right: 30px;
                .rtl & {
                    // padding-left: 20px;
                    // padding-right: 20px;
                    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                        padding-left: 15px;
                        padding-right: 15px;
                        font-size: $font-xs-resp;
                    }
                }
                .ch_lang &,
                .ja_lang & {
                    font-size: 13px;
                    font-weight: $weight-regular;
                    @media (max-width: $screen-sm-max) {
                        font-size: 15px;
                    }
                }
                .spanish & {
                    font-size: 12px;
                }
                @media (min-width: $screen-md-min) {
                    .french & {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                line-height: 1;
                padding-top: 15px;
                padding-bottom: 15px;
                position: relative;
                display: block;
                @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                &:hover {
                    background: none;
                    color: darken($dolphin-grey, 90%);
                }
            }
            >.dropdown-menu {
                max-height: 404px;
                overflow-y: auto;
                border: none;
                background: $white;
                padding: 10px 30px;
                border-radius: 0 0 10px 10px;
                box-shadow: 0 6px 24px rgba(0, 0, 0, 0.17);
                left: 50%;
                right: auto;
                transform: translateX(-50%);
                white-space: nowrap;
                // @include animation (opacityIn 0.5s);
                .rtl & {
                    left: auto;
                    right: 50%;
                    transform: translateX(50%);
                    direction: rtl;
                    text-align: right;
                }
                @extend .nav-scroll-override;
                li {
                    padding: 25px 0;
                    line-height: 1;
                    &:not(:last-child) {
                        border-bottom: 1px solid $light-grey;
                    }
                    a {
                        display: inline-block;
                        color: $light-blue;
                        @include font-size($font-sm, $font-sm-resp);
                        transition: 0.4s;
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        &:hover {
                            @media (min-width: $screen-md-min) {
                                color: darken($light-blue, 20%);
                            }
                        }
                    }
                    &.has-img {
                        a {
                            padding-left: 55px;
                            padding-right: 5px;
                            .rtl & {
                                padding-right: 55px;
                                padding-left: 5px;
                            }
                        }
                    }
                    img {
                        display: inline-block;
                        max-height: 27px;
                        max-width: 34px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                        .ie & {
                            width: 27px;
                            height: 34px;
                        }
                        .rtl & {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
            &.languages {
                padding: 9px 0;
                @media (max-width: $screen-sm-max) {
                    padding: 0;
                }
                svg {
                    float: left;
                    .rtl & {
                        @media (max-width: $screen-sm-max) {
                            float: right;
                        }
                    }
                }
                a {
                    @media (min-width: $screen-md-min) {
                        padding-right: 20px;
                        .ie.rtl & {
                            width: 85px;
                        }
                    }
                }
                >a:after {
                    margin-top: 5px;
                    background-image: url($blue-arrow);
                    @media (max-width: $screen-sm-max) {
                        width: 6px;
                        height: 12.5px;
                        margin-top: 7px;
                        margin-left: 17px;
                        .rtl & {
                            display: none;
                        }
                    }
                }
                >a:before {
                    .rtl & {
                        @media (max-width: $screen-sm-max) {
                            content: "";
                            display: inline-block;
                            border: none;
                            background: url($blue-arrow) no-repeat;
                            background-size: auto 100%;
                            width: 6px;
                            height: 12.5px;
                            margin-left: 0;
                            margin-top: 5px;
                            margin-right: 17px;
                        }
                    }
                }
                &.open,
                &.disabled {
                    svg {
                        opacity: 0.3;
                    }
                }
                &.open {
                    @media (min-width: $screen-md-min) {
                        box-shadow: 0 14px 21px rgba(0, 0, 0, 0.08);
                    }
                }
                .dropdown-menu {
                    border-radius: 0;
                    right: auto;
                    left: 0;
                    box-shadow: 0 14px 21px rgba(0, 0, 0, 0.08);
                    max-height: 275px;
                    padding: 0 30px 15px;
                    transform: translateX(0%);
                    @media (max-width: $screen-sm-max) {
                        position: absolute;
                        width: 750px;
                        height: calc(80vh - 100px);
                        max-height: calc(80vh - 100px);
                        right: -115px;
                        bottom: 0;
                        left: auto;
                        text-align: center;
                        box-shadow: none;
                        .rtl & {
                            right: auto;
                            left: -115px;
                        }
                        .affix & {
                            height: calc(100vh - 100px);
                            max-height: calc(100vh - 100px);
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        height: calc(80vh - 100px);
                        max-height: calc(80vh - 100px);
                        width: calc(100vW - 24px);
                        right: -65px;
                    }
                    .rtl & {
                        // right: 0;
                        // left: auto;
                        // transform: translateX(0%);
                        @media (max-width: $screen-sm-max) {
                            left: -115px;
                            right: auto;
                        }
                        @media (max-width: $screen-xs-max) {
                            left: -65px;
                            right: auto;
                        }
                    }
                    li {
                        padding: 18px 0;
                        border: none;
                        font-family: "karbon";
                        &.luma {
                            font-family: "luma";
                        }
                        &:not(.active) {
                            opacity: 0.5;
                        }
                        @media (max-width: $screen-sm-max) {
                            margin: 25px auto;
                        }
                        @media (max-width: $screen-xs-max) {
                            margin: 0 0 30px;
                        }
                        &:first-child {
                            @media (max-width: $screen-sm-max) {
                                padding-top: 0;
                                margin-top: 0;
                            }
                        }
                    }
                    a {
                        color: $moderate-blue;
                        font-weight: $weight-bold;
                        @include font-size($font-xs, $font-sm-resp);
                        @media (max-width: $screen-sm-max) {
                            color: $dark-smokey-grey;
                        }
                    }
                    .mCSB_dragger_bar {
                        .ie & {
                            display: none;
                        }
                    }
                }
            }
            &.search {
                a {
                    padding-left: 20px;
                    .rtl {
                        // padding-left: 30px;
                        // padding-right: 20px;
                        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
        &.items {
            .rtl & {
                li.navItem {
                    float: right;
                    a {
                        // padding-left: 20px;
                        // padding-right: 20px;
                        &:after {
                            margin-left: 0;
                            margin-right: 10px;
                            @include transform(rotate(180deg));
                        }
                    }
                }
            }
            @media(min-width: $screen-md-min) {
                .ja_lang & {
                    display: flex;
                    display: -webkit-flex;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
                    -webkit-flex-flow: row wrap;
                    -webkit-box-align: stretch;
                    -ms-flex-align: stretch;
                    align-items: stretch;
                    -webkit-align-items: stretch;
                    li.navItem {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                    }
                }
                .ja_lang.ie & {
                    li.navItem {
                        a {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .navbar-collapse {
        padding: 0;
        @extend .container;
        @extend .menuResp;
        @include transition(all .3s linear);
        .menuClose {
            position: absolute;
            top: 5px;
            left: 0;
            padding: inherit;
            cursor: pointer;
            display: none;
            .rtl & {
                left: auto;
                right: 0;
            }
            @media (max-width: $screen-sm-max) {
                left: 7px;
                .rtl & {
                    right: 7px;
                    left: auto;
                }
            }
            @media (max-width: $screen-xs-max) {
                left: 23px;
                .rtl & {
                    right: 23px;
                    left: auto;
                }
            }
        }
    }
}