// Global >>>>
.text-container {
    padding: 50px 0;
    .title {
        margin: 0 0 18px 0;
        color: $dark-smokey-grey;
        // @extend .font-md;
        @include font-size($font-md, $font-md-resp);
    }
    h3 {
        .ja_lang & {
            font-weight: $weight-regular;
            line-height: 1.25;
        }
    }
    h2 {
        // @extend .font-size-lg;
        @include font-size($font-lg, $font-lg-resp);
        margin: 20px 0;
        font-weight: $weight-medium;
        color: $dark-smokey-grey;
        .ja_lang & {
            font-weight: $weight-regular;
            line-height: 1.25;
        }
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    p {
        margin: 0 0 20px 0;
        color: $dark-smokey-grey;
        @include font-size($font-sm, $font-sm-resp);
        font-weight: $weight-regular;
        line-height: 1.2;
        .ch_lang & {
            line-height: 1.6;
        }
    }
    .lead-title {
        color: $bright-grey;
        // @extend .font-size-lg;
        @include font-size($font-lg, $font-lg-resp);
    }
    .lead,
    h5 {
        // @extend .font-md;
        @include font-size($font-md, $font-md-resp);
        color: $dark-smokey-grey;
        font-weight: $weight-regular;
        color: $dark-smokey-grey;
        .ch_lang & {
            line-height: 1.4;
        }
        .ja_lang & {
            font-weight: $weight-regular;
            line-height: 1.25;
        }
    }
    h4 {
        font-size: $font-md;
        font-weight: $weight-light;
        line-height: 28px;
        // .ch_lang & {
        //     font-weight: $weight-thin;
        // }
    }
    a {
        color: $dark-moderate-blue;
        font-weight: $weight-regular;
        text-decoration: none;
    }
    // .url {
    // 	display: inline-block;
    // 	color: $dark-moderate-blue;
    // 	@include font-size($font-sm, $font-sm-resp);
    // 	font-weight: $weight-medium;
    // 	margin: 0 0 35px 0;
    // }
    ol {
        padding: 0;
        margin: 20px 0;
        counter-reset: olcounter;
        li {
            // list-style-type: lower-roman;
            list-style-position: inside;
            list-style: none;
            margin-bottom: 3px;
            color: $dark-smokey-grey;
            line-height: 1.5;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 25px;
            width: 100%;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                content: counter(olcounter, lower-roman)".";
                counter-increment: olcounter;
                display: block;
                position: absolute;
                left: 0;
                .rtl & {
                    margin-left: 20px;
                    margin-right: 0;
                    left: auto;
                    right: 0;
                }
            }
            // &:before {
            // 	content: "";
            // 	width: 10px;
            // 	display: inline-block;
            // }
            ol {
                padding: 0 0 0 15px;
                margin: 20px 0;
                // li {
                // list-style-type: lower-alpha;
                // }
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
        &[type=a] {
            li {
                &::before {
                    content: counter(olcounter, lower-alpha)".";
                }
            }
        }
    }
    ul {
        margin: 20px 0;
        padding: 0;
        li {
            background: url(../images/bullet-icon.png) no-repeat 0 8px;
            list-style: none;
            margin-bottom: 3px;
            color: $dark-smokey-grey;
            line-height: 1.5;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 25px;
            width: 100%;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            .rtl & {
                background: url(../images/bullet-icon.png) no-repeat 100% 8px;
            }
            // &:before {
            // 	content: "■";
            // 	width: 20px;
            // 	height: 20px;
            // 	color: #4d88a3;
            // 	margin-right: 20px;
            // 	display: block;
            //   position: absolute;
            //   left: 0;
            // .rtl & {
            // 	margin-left: 20px;
            // 	margin-right: 0;
            // 	left: auto;
            // 	right:0;
            // }
            // }
            ul {
                padding: 0 0 0 15px;
                margin: 20px 0;
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
    }
    .text-logo {
        text-align: center;
        img {
            display: block;
            margin: 25px auto;
            max-width: 100%;
        }
    }
}

.text-background {
    background-image: url(../images/text-bg.jpg);
    background-position: 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @media (max-width: $screen-xs-max) {
        padding: 0 5px;
    }
}