// navigation Menu Media Queries >
$menuMobileSpaces: 12px;
.menuResp {
    @media (max-width: $screen-sm-max) {
        position: absolute;
        top: 0;
        width: 100%;
        height: 500px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: $white;
        .affix & {
            // height: 100vh;
        }
        .nav-holder {
            float: none;
            padding: 25px 35px;
            width: 100%;
            z-index: 10;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            .menuClose {
                display: block;
            }
        }
        ul.navbar-nav {
            margin: 0;
            &.items {
                position: absolute;
                left: 0;
                right: 0;
                top: 40px;
                bottom: 25px;
                // height: calc(90vh - 100px);
                // max-height: calc(90vh - 100px);
                text-align: center;
                padding: 0;
                .rtl & {
                    padding: 0;
                }
                .affix & {
                    // height: calc(100vh - 100px);
                    // max-height: calc(100vh - 100px);
                }
                .mCustomScrollBox {
                    margin: 0 !important;
                    .mCSB_container {
                        min-height: 100%;
                        margin: 0;
                    }
                }
            }
            li.navItem {
                text-align: center;
                float: none !important;
                .rtl & {
                    float: none !important;
                }
                &:not(.languages):not(.search) {
                    position: static;
                    margin-top: 50px;
                    margin-bottom: 70px;
                    padding: 0;
                    height: auto;
                    a {
                        padding: 0;
                        height: auto;
                        position: static;
                        color: $dark-smokey-grey;
                        .item-text {
                            color: $dark-smokey-grey;
                        }
                        img {
                            display: none;
                        }
                    }
                    &.opened_item {
                        a {
                            color: $moderate-blue;
                        }
                        >a:after {
                            background-image: url($blue-arrow);
                        }
                    }
                    .dropdown-menu {
                        position: absolute;
                        width: 100%;
                        bottom: 25px;
                        right: 0;
                        top: 80px;
                        box-shadow: none;
                        padding: 0;
                        .rtl & {
                            right: 50%;
                            left: 0;
                        }
                        .mCustomScrollBox .mCSB_container {
                            min-height: 100%;
                            margin: 0 !important;
                        }
                        li {
                            border: none;
                            text-align: center;
                            // padding-right: 10px;
                            padding-top: 45px;
                            .rtl & {
                                // padding-left: 10px;
                                padding-right: 0;
                            }
                            a {
                                padding: 0;
                                color: $dark-smokey-grey;
                            }
                            .item-icon {
                                display: none;
                            }
                        }
                        .ja_lang & {
                            top: 130px;
                        }
                    }
                }
            }
            .languages {
                position: absolute;
                top: 24px;
                right: 95px;
                margin: 0;
                padding: 0;
                .rtl & {
                    right: auto;
                    left: 88px;
                }
                a {
                    padding: 0;
                }
                svg {
                    width: 26px;
                    height: 26px;
                }
                .dropdown-menu {
                    li {
                        margin-top: 50px;
                        margin-bottom: 70px;
                        padding: 0;
                        a {
                            padding: 0;
                            margin: 0 auto;
                        }
                    }
                    .mCustomScrollBox .mCSB_container {
                        min-height: 100%;
                        margin: 0 !important;
                    }
                }
            }
            .search {
                top: 25px;
                right: 0;
                position: absolute;
                margin: 0;
                padding: 0;
                .rtl & {
                    right: auto;
                    left: 0;
                }
                a {
                    padding-top: 0;
                }
                svg {
                    width: 20px;
                    height: 24px;
                }
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        position: absolute;
        top: 0;
        width: 100%;
        height: 500px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: $white;
        .nav-holder {
            padding: 20px;
        }
        ul.navbar-nav {
            &.items {
                top: 90px;
            }
            li.navItem:not(.languages):not(.search) {
                margin-top: 50px;
                margin-bottom: 50px;
                padding: 0;
                height: auto;
                &.first-child {
                    margin-top: 0;
                }
                ul.dropdown-menu {
                    top: 70px;
                    .ja_lang & {
                        top: 130px;
                    }
                }
            }
            .languages {
                right: 75px;
                .rtl & {
                    right: auto;
                    left: 75px;
                }
                .dropdown-menu {
                    width: auto;
                }
            }
            .search {
                a {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
        }
    }
}