// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.
// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin rtl-float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: right;
  }
}

@mixin rtl-calc-grid-column($index, $class, $type) {
  @if ($type==width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      // width: percentage(($index / $grid-columns));
    }
  }
  @if ($type==push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type==push) and ($index==0) {
    .col-#{$class}-push-0 {
      right: auto;
    }
  }
  @if ($type==pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type==pull) and ($index==0) {
    .col-#{$class}-pull-0 {
      left: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-right: percentage(($index / $grid-columns));
      margin-left: 0;
    }
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin rtl-loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include rtl-calc-grid-column($i, $class, $type);
  }
}

// Create grid for specific class
@mixin rtl-make-grid($class) {
  @include rtl-float-grid-columns($class);
  @include rtl-loop-grid-columns($grid-columns, $class, width);
  @include rtl-loop-grid-columns($grid-columns, $class, pull);
  @include rtl-loop-grid-columns($grid-columns, $class, push);
  @include rtl-loop-grid-columns($grid-columns, $class, offset);
}

// Grid system
//
// Generate semantic grid columns with these mixins.
// Generate the extra small columns
@mixin rtl-make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: right;
  // width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin rtl-make-xs-column-offset($columns) {
  margin-right: percentage(($columns / $grid-columns));
}

@mixin rtl-make-xs-column-push($columns) {
  right: percentage(($columns / $grid-columns));
}

@mixin rtl-make-xs-column-pull($columns) {
  left: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin rtl-make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-sm-min) {
    float: right;
    // width: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin rtl-make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-md-min) {
    float: right;
    // width: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin rtl-make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-lg-min) {
    float: right;
    // width: percentage(($columns / $grid-columns));
  } 
}

@mixin rtl-make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin rtl-make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}

.rtl {
    direction: rtl; 
  * {
    // font-family: $arabic-bold !important;
  }
  // Columns
  //
  // Common styles for small and large grid columns
  // @include make-grid-columns;
  // Extra small grid
  //
  // Columns, offsets, pushes, and pulls for extra small devices like
  // smartphones.
  @include rtl-make-grid(xs);
  // Small grid
  //
  // Columns, offsets, pushes, and pulls for the small device range, from phones
  // to tablets.
  @media (min-width: $screen-sm-min) {
    @include rtl-make-grid(sm);
  }
  // Medium grid
  //
  // Columns, offsets, pushes, and pulls for the desktop device range.
  @media (min-width: $screen-md-min) {
    @include rtl-make-grid(md);
  }
  // Large grid
  //
  // Columns, offsets, pushes, and pulls for the large desktop device range.
  @media (min-width: $screen-lg-min) {
    @include rtl-make-grid(lg);
  }
  .pull-right {
    float: left !important;
  }
  .pull-left {
    float: right !important;
  }
  .text-right {
    text-align: left !important;
  }
  .text-left {
    text-align: right !important;
  }

  // @media (min-width: $grid-float-breakpoint) {
  //   .navbar-left {
  //     float: right !important;
  //   }
  //   .navbar-nav {
  //     float: right;
  //     padding-right: 0;
  //     > li {
  //       float: right;
  //     }
  //   }
  //   .navbar-header {
  //     float: right;
  //   }
  //   .navbar-right {
  //     float: left !important;
  //     margin-right: -$navbar-padding-horizontal;
  //     ~ .navbar-right {
  //       margin-left: 0;
  //     }
  //   }
  // }
  .media-right,
  .media > .pull-right {
    padding-left: 0;
    padding-right: 10px;
  }
  .media-left,
  .media > .pull-left {
    padding-right: 0;
    padding-left: 10px;
  }
}