// Spaces Variables >> 
$main-section-side-padding: 75px;
$main-section-top-bottom-padding: 30px;
$button-side-padding: 20px;
// Padding >>
// Page main Sections >
.clear-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0;
}

.main-section-side-padding {
    padding-right: $main-section-side-padding;
    padding-left: $main-section-side-padding;
}

.main-section-top-bottom-padding {
    padding-top: $main-section-top-bottom-padding;
    padding-bottom: $main-section-top-bottom-padding;
}

.button-side-padding {
    // submit button
    padding-right: $button-side-padding;
    padding-left: $button-side-padding;
}

// Margin
.clear-margin {
    margin: 0 !important;
}

.clear-margin-top {
    margin-top: 0 !important;
}