$screen-md-custom: 1024px;
.filteration-row {
    &.row-wraper {
        padding: 25px 0;
        border-top: 2px solid $lighter-grey;
        border-bottom: 2px solid $lighter-grey;
        .media-blocks & {
            margin-top: 35px;
        }
    }
    .row {
        form {
            .col-xs-12:nth-child(1) {
                @media (min-width: $screen-md-min) {
                    padding-right: 7.5px;
                    .rtl & {
                        padding-right: 15px;
                        padding-left: 7.5px;
                    }
                }
            }
            .col-xs-12:nth-child(2) {
                @media (min-width: $screen-md-min) {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                }
            }
            .col-xs-12:nth-child(3) {
                @media (min-width: $screen-md-min) {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                }
            }
            .col-xs-12:nth-child(4) {
                @media (min-width: $screen-md-min) {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                }
            }
        }
    }
    .input-group {
        display: flex;
        @media (max-width: $screen-sm-max) {
            margin-bottom: 15px;
        }
        // .safari & {
        //     display: block;
        // }
        .form-control {
            padding: 0 0 0 20px;
            height: 50px;
            border-right: none;
            border-color: $light-grey;
            border-radius: 9px 0 0 9px;
            box-shadow: none;
            &:focus {
                box-shadow: none;
                border-color: $light-grey;
            }
            .rtl & {
                padding: 0 20px 0 0;
            }
            .firefox & {
                padding: 0 0 0 12px;
            }
            .firefox.rtl & {
                padding: 0 12px 0 0;
            }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            @media (max-width: $screen-xs-max) {
                padding: 0 0 0 6px;
                .rtl & {
                    padding: 0 6px 0 0;
                }
            }
        }
        .input-group-addon {
            padding: 0 35px 0 0;
            border-color: $light-grey;
            border-left: none;
            background-color: $white;
            border-radius: 0 9px 9px 0;
            &:focus {
                outline: 0;
            }
            .rtl & {
                padding: 0 25px 0 35px;
                border-radius: 9px 0 0 9px;
                border-right: none;
                border-left: 1px solid $light-grey;
            }
            .firefox & {
                padding: 15px 30px 0 0;
            }
            .firefox.rtl & {
                padding: 15px 0 0 30px;
            }
            .firefox.ja_lang & {
                padding-top: 0;
            }
            .calendar-icon {
                &:before {
                    content: '';
                    background: url($form-icon) no-repeat;
                    top: 0;
                    width: 16px;
                    height: 12px;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    margin-top: 4px;
                    // .safari & {
                    //     margin-top: 20px;
                    // }
                }
            }
            .glyphicon {
                top: -2px;
                .ie & {
                    top: -9px;
                }
            }
        }
        // .form-icon {
        //     .rtl & {
        //         padding: 0 0 0 20px;
        //     }
        // }
         ::-webkit-input-placeholder {
            @include font-size($font-sm, $font-sm-resp);
            color: $dark-grey;
            @media (max-width: $screen-sm-max) {
                font-size: $font-sm;
            }
            @media (max-width: $screen-xs-max) {
                font-size: $font-xs-resp;
            }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            .ja_lang & {
                @include font-size(11px, $font-sm-resp);
            }
        }
         ::-moz-placeholder {
            @include font-size($font-sm, $font-sm-resp);
            color: $dark-grey;
            @media (max-width: $screen-sm-max) {
                font-size: $font-sm;
            }
            @media (max-width: $screen-xs-max) {
                font-size: $font-xs-resp;
            }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            .ja_lang & {
                @include font-size(11px, $font-sm-resp);
            }
        }
         :-ms-input-placeholder {
            @include font-size($font-sm, $font-sm-resp);
            color: $dark-grey;
            @media (max-width: $screen-sm-max) {
                font-size: $font-sm;
            }
            @media (max-width: $screen-xs-max) {
                font-size: $font-xs-resp;
            }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            .ja_lang & {
                @include font-size(11px, $font-sm-resp);
            }
        }
         :-moz-placeholder {
            @include font-size($font-sm, $font-sm-resp);
            color: $dark-grey;
            @media (max-width: $screen-sm-max) {
                font-size: $font-sm;
            }
            @media (max-width: $screen-xs-max) {
                font-size: $font-xs-resp;
            }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            .ja_lang & {
                @include font-size(11px, $font-sm-resp);
            }
        }
        .btn-default {
            // padding-left: 20px;
            color: $white;
            border: none;
            background-color: $dark-moderate-blue;
            width: 80px;
            padding: 0px!important;
            text-align: center;
            // .input-group & {
            //     display: block;
            // }
            @media (max-width: $screen-md-custom) {
                .rtl & {
                    font-size: $font-xs-resp;
                }
            }
            .ie & {
                width: 60px;
                height: 50px;
            }
        }
        .search-input {
            .rtl & {
                border-right: 1px solid $light-grey;
                border-radius: 0px 9px 9px 0;
                @media (max-width: $screen-md-custom) {
                    font-size: $font-xs-resp;
                }
            }
            .firefox & {
                padding: 0 0 0 20px;
            }
            .firefox.rtl & {
                padding: 0 20px 0 0;
            }
            .press-releases-listing & {
                .firefox.ja_lang & {
                    @media (min-width: $screen-md-min) {
                        width: 216px;
                    }
                }
            }
            .prespectives-listing & {
                .firefox.ja_lang & {
                    @media (min-width: $screen-md-min) {
                        width: 216px;
                    }
                }
            }
            .press-packs-listing & {
                .firefox.ja_lang & {
                    @media (min-width: $screen-md-min) {
                        width: 216px;
                    }
                }
            }
            .media-listing-media-center & {
                .firefox.ja_lang & {
                    @media (min-width: $screen-md-min) {
                        width: 216px;
                    }
                }
            }
        }
    }
    // .input-group.date {
    // .safari & {
    //     display: initial;
    // }
    // }
    input[type=text] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .date-wrapper {
        .date {
            width: 50%;
            float: left;
            .rtl & {
                float: right;
            }
            &:first-child {
                .input-group-addon {
                    border-radius: 0;
                    border-right: none;
                }
                .rtl & {
                    .form-control {
                        border-radius: 0 9px 9px 0;
                        border-left: none;
                        border-right: 1px solid $light-grey;
                    }
                }
            }
            &:last-child {
                .form-control {
                    border-radius: 0;
                    .rtl & {
                        border-left: none;
                    }
                }
            }
            &:first-child {
                @media (max-width: $screen-xs-max) {
                    .bootstrap-datetimepicker-widget.dropdown-menu.pull-right {
                        right: -120px !important;
                        .rtl & {
                            left: -120px !important;
                            right: 0 !important;
                        }
                        &:after {
                            right: 130px;
                            .rtl & {
                                left: 133px;
                            }
                        }
                        &:before {
                            right: 129px;
                            .rtl & {
                                left: 132px;
                            }
                        }
                    }
                }
            }
            .list-unstyled {
                .rtl & {
                    padding-right: 0;
                }
            }
            .glyphicon-chevron-left,
            .glyphicon-chevron-right {
                .rtl & {
                    @include transform(rotate(180deg));
                }
            }
            .firefox.ja_lang & {
                display: inline-table;
            }
        }
        .input-group-addon {
            padding-top: 15px;
            .rtl & {
                padding-top: 15px;
                padding-right: 0;
            }
            .ie & {
                height: 50px;
                width: 100%;
            }
            // .safari & {
            //     padding-top: 0;
            // }
        }
    }
    input.form-control {
        box-shadow: none !important;
        .ie & {
            width: 220px;
        }
    }
    select {
        width: 100%;
        option {
            padding: 25px 20px;
        }
    }
    .select2-container {
        border-color: $light-grey;
        font-size: $font-sm;
        color: $dark-grey;
        max-width: 100%;
        min-width: 100%;
        @media (max-width: $screen-sm-max) {
            margin-bottom: 15px;
        }
        @media (max-width: $screen-md-custom) {
            .rtl & {
                font-size: $font-xs-resp;
            }
        }
        .select2-selection.select2-selection--single {
            padding: 24px 20px;
            border: 1px solid $light-grey;
            border-radius: 9px;
            &:focus {
                outline: 0;
            }
            .select2-selection__rendered {
                position: absolute;
                top: 12px;
                left: 0;
                right: auto;
                padding-left: 20px;
                padding-right: 0;
                @include font-size($font-sm, $font-sm-resp);
                color: $dark-grey;
                &:focus {
                    outline: 0;
                }
                .rtl & {
                    right: 0;
                    left: auto;
                    padding-right: 20px;
                    padding-left: 0;
                }
                @media (max-width: $screen-xs-max) {
                    padding-left: 6px;
                    font-size: $font-xs-resp;
                    .rtl & {
                        padding-right: 6px;
                        padding-left: 0;
                    }
                }
            }
            .select2-selection__arrow {
                padding: 14px 25px;
                top: 0;
                &:focus {
                    outline: 0;
                }
                &:before {
                    content: '';
                    background: url($blue-arrow) no-repeat;
                    width: 16px;
                    height: 15px;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    .rtl & {
                        @include transform(rotate(180deg));
                    }
                }
                b {
                    display: none;
                }
                .rtl & {
                    width: unset;
                    left: 14px;
                    right: auto;
                    padding-left: 0;
                }
            }
        }
    }
}