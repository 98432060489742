.press-packs-details {
    .listing-container {
        position: relative;
        padding: 24px 0 54px;
    }
    .flex-item {
        padding: 0;
    }
    .media-block {
        margin-top: 30px;
    }
    .press-packs-container {
        margin-top: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 2px solid $lighter-grey;
        border-top: 2px solid $lighter-grey;
        .press-packs-name {
            display: inline-block;
            margin: 0;
            font-size: 32px;
            font-weight: $weight-medium;
            color: #6a676a;
            @media (min-width: $screen-sm-min) {
                width: 63%;
            }
            .name-num {
                margin-left: 5px;
                .rtl & {
                    margin-left: 0;
                    margin-right: 5px;
                }
            }
        }
        .svg-wraper {
            margin-top: 5px;
            float: right;
            .rtl & {
                float: left;
            }
            @media (max-width:$screen-xs-max) {
                float: none;
                margin-top: 10px;
            }
            svg {
                margin-right: 15px;
                .rtl & {
                    margin-right: 0;
                    margin-left: 15px;
                }
            }
            a {
                font-size: 16px;
                font-weight: $weight-medium;
                color: $dark-moderate-blue;
            }
        }
    }
    .news-block {
        padding: 30px 0 60px;
        .media-left {
            @media (max-width: $screen-xs-max) {
                padding: 0;
                display: block;
            }
            img.media-object {
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .news-logo {
            img {
                width: 100%;
            }
        }
        .news-body {
            padding-left: 0;
            padding-right: 0;
            .media-img {
                position: absolute;
                right: 0;
                top: 0;
                bottom: auto;
                left: auto;
                img {
                    min-width: 100%;
                }
                .rtl & {
                    left: 0;
                    right: auto;
                }
            }
            .news-details {
                margin: 0 0 5px 0;
                .news-date {
                    position: static;
                    display: inline-block;
                    font-weight: $weight-medium;
                }
            }
            p {
                margin-bottom: 10px;
            }
            .block-options {
                bottom: -5px;
                @media (max-width: $screen-sm-max) {
                    display: block;
                }
                @media(max-width: $screen-xs-max) {
                    position: static;
                    margin-top: 20px;
                }
                .svg-wraper {
                    &:nth-child(1) {
                        border-right: 2px solid #dcdcdc;
                        border-left: none;
                        .rtl & {
                            border-left: 2px solid #dcdcdc;
                            border-right: none;
                        }
                        @media (max-width: $screen-sm-max) {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        border-right: none;
                        border-left: none;
                        @media (max-width: $screen-sm-max) {
                            padding-left: 4px;
                            .rtl & {
                                padding-right: 0;
                                padding-left: 30px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        height: 31px;
                        vertical-align: bottom;
                        border-right: none;
                        border-left: 2px solid #dcdcdc;
                        .rtl & {
                            border-left: none;
                            border-right: 2px solid #dcdcdc;
                        }
                    }
                    .social_share {
                        margin-top: 4px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .aside-content {
        padding-top: 30px;
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
}

body {
    .modal.modal-press-packs {
        padding-right: 0!important;
        .modal-dialog {
            padding: 50px;
            margin-top: 5%;
            box-shadow: 0px 1px 10px 0px;
            background-color: $white;
            @media (max-width: $screen-sm-max) {
                margin-top: 15%;
            }
            @media (max-width: $screen-xs-max) {
                padding: 35px 15px;
                margin-top: 30%;
            }
            .modal-content {
                margin: auto;
                border: none;
                box-shadow: none;
                .close {
                    opacity: 1;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                    left: auto;
                    &:hover {
                        opacity: 1;
                    }
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 35px;
                        font-weight: 500;
                        color: $dark-moderate-blue;
                        &:focus {
                            outline: 0;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        right: -5px
                    }
                }
                .modal-body {
                    padding: 0;
                    position: relative;
                    .item {
                        .media-img {
                            width: 100%;
                            overflow: hidden;
                            position: relative;
                            top: 0;
                            z-index: 1;
                            img {
                                min-width: 100%;
                                @media (min-width: $screen-md-min) {
                                    height: 277px;
                                }
                            }
                            svg {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                overflow: visible;
                            }
                        }
                        .details {
                            h3 {
                                font-weight: 500;
                                font-size: 20px;
                                padding-bottom: 25px;
                                margin: 25px 0 20px;
                                color: $dark-moderate-blue;
                                border-bottom: 1px solid $light-grey;
                                @media (max-width: $screen-xs-max) {
                                    font-size: 18px;
                                }
                            }
                            p {
                                font-size: 16px;
                                margin-bottom: 0;
                                margin-top: 20px;
                            }
                        }
                        img {
                            max-width: 100%;
                        }
                        .block-options {
                            padding-top: 0;
                            padding-bottom: 20px;
                            border-bottom: 1px solid $light-grey;
                            @media (max-width: $screen-sm-max) {
                                display: block;
                            }
                            .svg-wraper {
                                margin-left: -3px;
                                display: inline-block;
                                padding: 0 30px;
                                border-right: 2px solid $light-grey;
                                max-height: 35px;
                                .rtl & {
                                    margin-left: 0;
                                    border-right: none;
                                }
                                &:nth-child(3) {
                                    padding-right: 0;
                                    border-right: none;
                                    .rtl & {
                                        margin-left: 0;
                                        padding-left: 0;
                                        padding-right: 30px;
                                        border-right: 2px solid $light-grey;
                                    }
                                }
                                &:first-child {
                                    margin-left: 0;
                                    padding-left: 0;
                                    .rtl & {
                                        padding-right: 0;
                                        padding-left: 30px;
                                    }
                                    @media (max-width: $screen-sm-max) {
                                        display: none;
                                    }
                                    &:nth-child(2) {
                                        @media (max-width: $screen-sm-max) {
                                            padding-left: 4px;
                                            .rtl & {
                                                padding-right: 0;
                                                padding-left: 30px;
                                            }
                                        }
                                    }
                                }
                                svg {
                                    margin-top: 15%;
                                    &:focus,
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                            .social_share {
                                margin-top: 10px;
                                img {
                                    margin-top: -10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        h4 {
            font-size: 32px;
            font-weight: 100;
            line-height: 1.2;
        }
        img {
            // display: block;
            max-width: 100%;
            // height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
}