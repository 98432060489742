.stretch {
    width: 100%;
}

.card-nav {
    .select2-container--default {
        .select2-selection--single {
            border-color: #e0e1e3;
            height: auto;
            .select2-selection__rendered {
                padding: 6px 20px;
            }
            .select2-selection__arrow {
                height: 40px;
                width: 40px;
                b {
                    display: none;
                }
                &:after {
                    -webkit-transition: all 300ms ease;
                    -moz-transition: all 300ms ease;
                    -ms-transition: all 300ms ease;
                    -o-transition: all 300ms ease;
                    transition: all 300ms ease;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 10;
                    margin: auto;
                    width: 40px;
                    height: 40px;
                    border: none;
                    content: ' ';
                    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NSAxMzguNjYiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojNDU3Yzk2O308L3N0eWxlPjwvZGVmcz48dGl0bGU+Y2hldjwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNTIwLjA2LDQ2NGwtNTIuNjUtNjMuMTZhNi4xNyw2LjE3LDAsMCwwLTkuNDgsNy45TDUwNy4yOSw0NjhsLTQ5LjM2LDU5LjJhNi4xNyw2LjE3LDAsMCwwLDkuNDgsNy45MUw1MjAuMDYsNDcyQTYuMTYsNi4xNiwwLDAsMCw1MjAuMDYsNDY0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ1Ni41IC0zOTguNjcpIi8+PC9zdmc+);
                    background-size: 6px;
                    background-position: center;
                    background-repeat: no-repeat;
                    .rtl & {
                        webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }
        }
        &.select2-container--open {
            .select2-selection__arrow {
                &:after {
                    webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }
    }
}