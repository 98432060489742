$slide-hover-move: 15px;
$screen-md-custom: 1024px;
// Main Slider Container >>
.news-slider-container {
    position: relative;
    margin-bottom: 20px;
    .featured-heading {
        font-size: $font-lg;
        font-weight: $weight-medium;
        padding: 50px 0;
        margin: 0;
        color: $dark-grey;
        .rtl & {
            text-align: right;
        }
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    .news-slider {
        margin-left: -10px;
        margin-right: -10px;
        @media (max-width: $screen-md-custom) {
            svg {
                fill: $white;
            }
        }
        @media (max-width: $screen-sm-max) {
            // margin-left: -7.5px;
            // margin-right: -7.5px;
            padding: 0 20px;
        }
        // Slider Arrows >>
        .general-news-arrow {
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            z-index: 15;
            &:hover {
                cursor: pointer;
            }
        }
        .news-slider-arrow-next {
            right: -25px;
            @media (max-width: $screen-md-max) {
                right: 15px;
            }
            @media (max-width: 1024px) {
                right: -7px;
            }
            @media (max-width: $screen-sm-max) {
                right: 0;
            }
        }
        .news-slider-arrow-prev {
            left: -25px;
            @media (max-width: $screen-md-max) {
                left: 15px;
            }
            @media (max-width: 1024px) {
                left: -7px;
            }
            @media (max-width: $screen-sm-max) {
                left: 0;
            }
        }
        // Slider Arrows >>
        .slider-item {
            padding-left: 10px;
            padding-right: 10px;
            @media (max-width: $screen-sm-max) {
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
        }
        .slick-slide {
            outline: none;
        }
        // News Slide >>
        .main-slide {
            overflow: hidden;
            position: relative;
            @media (min-width: $screen-md-min) {
                @include transition(ease transform .5s);
            }
            &:hover {
                img {
                    @include filter(blur(2px));
                }
                .block-details {
                    @include transform(translateY(0));
                    .read-more {
                        @include opacity(1);
                    }
                }
            }
            .block-details {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0 20px 20px;
                color: $white;
                @include transition(0.5s);
                @include transform(translateY(37px));
                background: transparent;
                /* For browsers that do not support gradients */
                background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* For Firefox 3.6 to 15 */
                background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
                /* Standard syntax */
            }
            img {
                min-width: 100%;
                min-height: 100%;
                // width: 100%;
                height: 100%;
                .safari & {
                    min-height: inherit;
                }
            }
            span {
                line-height: 2em;
                display: inline-block;
                @include font-size($font-sm, $font-sm-resp);
                margin-bottom: 8px;
            }
            .title {
                font-weight: $weight-bold;
            }
            .date {
                float: right;
                margin-bottom: 8px;
                .rtl & {
                    float: left;
                }
            }
            p {
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
                line-height: 1.1;
            }
            .read-more {
                @include opacity(0);
                @include transition(0.5s);
                font-weight: $weight-bold;
                @include font-size($font-sm, $font-sm-resp);
                display: inline-block;
                &::after {
                    content: "";
                    width: 5px;
                    height: 11px;
                    background: url($white-arrow) no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    display: inline-block;
                    margin-left: 12px;
                    .rtl & {
                        margin-left: 0px;
                        margin-right: 12px;
                        @include transform(rotate(180deg));
                    }
                }
            }
            a {
                &:hover {
                    color: unset;
                }
            }
        }
    }
}

// News Slider Container >>