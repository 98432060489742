.footer-nav-section {
    font-size: $font-xs;
    color: $dark-smokey-grey;
    li {
        margin: 0 0 6px;
        &:nth-child(4) {
            margin: 0;
            .firefox &,
            .ie & {
                margin: 0 0 6px;
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        padding-right: 5px;
        padding-left: 5px;
    }
    .footer-list {
        @extend .clear-padding;
    }
    .business-list {
        width: 100%;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
        @media only screen and (max-width: $screen-sm-max) {
            width: 100%;
        }
    }
    .right-side-menu {
        margin-top: 5px;
        li {
            font-weight: $weight-medium;
            // .ch_lang & {
            //     font-weight: 400;
            // }
        }
    }
    /// Custom media query for Tablets
    @media only screen and (max-width: $screen-sm-max) {
        h6 {
            // font-size: 30px;
            margin: 0 0 20px 0;
        }
        li {
            // font-size: $font-md;
            margin: 0 0 20px 0;
        }
        .footer-right-menu {
            padding-left: 0;
            .right-side-menu {
                li {
                    // font-size: 30px;
                }
            }
        }
        .footer-social {
            padding-left: 0;
            // @media (max-width: $screen-xs-max) {
            //     margin-top: -55px;
            // }
        }
        .right-list {
            li {
                &:first-child {
                    display: none;
                }
            }
            span {
                display: none;
            }
        }
    }
    /// Custom media query for Mobiles
    @media only screen and (max-width: $screen-xs-max) {
        h6 {
            font-size: $font-sm-resp;
            margin: 0 0 10px 0;
        }
        li {
            font-size: $font-xs-resp;
            margin: 0 0 10px 0;
        }
        .footer-right-menu {
            padding-left: 0;
            .right-side-menu {
                li {
                    font-size: $font-sm-resp;
                }
            }
        }
    }
}