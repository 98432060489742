.download-item[data-icon] {
    &::before {
        content: "";
        height: 0;
        display: none;
    }
}

.press-kit {
    padding: 23px 24px;
    margin-bottom: 20px;
    background-color: $lighter-grey;
    .kit-title {
        color: $dark-moderate-blue;
        font-weight: $weight-bold;
        font-size: $font-md-resp;
        margin-bottom: 30px;
        margin-top: 5px;
        text-transform: capitalize;
        .rtl & {
            font-size: 15px;
        }
        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
            font-size: 15px;
        }
        .french &,
        .spanish & {
            font-size: 13px;
            @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                font-size: 11px;
            }
        }
        .kit-icon {
            svg {
                vertical-align: middle;
                @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    width: 22px;
                    height: 23px;
                }
            }
            &:after {
                content: "";
                width: 10px;
                display: inline-block;
                @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
                    width: 2px;
                }
            }
        }
        .kit-count {
            margin-left: -6px;
            &:before {
                content: "";
                width: 7px;
                display: inline-block;
            }
        }
    }
    .kit-files-list {
        padding: 0;
        max-height: 300px;
        overflow: auto;
        // .ch_lang &, .ja_lang &, .rtl & {
        // 	overflow: hidden;
        // }
        // .rtl & {
        //     max-height: initial;
        // }
        .mCSB_dragger_bar {
            background-color: $dark-moderate-blue;
            opacity: 0.68;
            &:hover {
                background-color: $dark-moderate-blue;
            }
        }
        .mCSB_draggerRail {
            opacity: 0;
        }
        .kit-item {
            position: relative;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            padding-right: 18px;
            .rtl & {
                padding-right: 0;
                padding-left: 18px;
            }
            &:not(:first-child) {
                margin-top: 10px;
            }
            .kit-item-icon {
                min-width: 36px;
                margin-right: 10px;
                margin-top: 7px;
                display: flex;
                float: left;
                flex: 1;
                .rtl & {
                    margin-right: 0px;
                    margin-left: 10px;
                    float: right;
                }
                img {
                    max-width: 30px;
                    max-height: 30px;
                    .ie & {
                        height: 30px;
                        width: 30px;
                    }
                }
                &:after {
                    content: "";
                    display: inline-block;
                    width: 12px;
                }
            }
            .kit-item-name {
                @include font-size($font-sm, $font-sm-resp);
                display: -webkit-box;
                float: left;
                line-height: 1.2;
                flex: 12;
                -ms-flex: 12;
                -webkit-box-flex: 12;
                -webkit-box-orient: vertical;
                padding-top: 5px;
                .rtl & {
                    line-height: 1.3;
                }
            }
            .kit-item-remove {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid $dark-moderate-blue;
                position: absolute;
                right: 0;
                top: 17px;
                cursor: pointer;
                .rtl & {
                    right: auto;
                    left: 0;
                }
                @include transform(translateY(-50%));
                svg {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                // &::before{
                // 	content:"";
                // 	position: absolute;
                //     height: 1px;
                //     width: 60%;
                //     top: 50%;
                //     left: 0;
                //     margin-top: -1px;
                //     background-color:$dark-moderate-blue;
                // 	@include transform(rotate(45deg));
                // }
                // &::after{
                // 	content:"";
                // 	position: absolute;
                //     height: 1px;
                //     width: 60%;
                //     top: 50%;
                //     left: 0;
                //     margin-top: -1px;
                // 	background-color:$dark-moderate-blue;
                // 	@include transform(rotate(-45deg));
                // }
            }
        }
    }
    button.kit-download {
        margin-top: 10px;
        height: 50px;
        width: 100%;
        background-color: $dark-moderate-blue;
        color: $white;
        font-weight: $weight-bold;
        font-size: $font-md-resp;
        line-height: 1;
    }
}