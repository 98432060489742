/// Tags  >
/// Body >
html {
    overflow-x: hidden;
    height: 100%;
}

h4 {
    .ja_lang &,
    .ch_lang & {
        font-weight: 400 !important;
    }
}

a {
    .ja_lang &,
    .ch_lang & {
        font-weight: 400 !important;
    }
}

.p1-style {
    .ja_lang &,
    .ch_lang & {
        font-weight: 400 !important;
    }
}

body {
    // height: 100%;
    margin: 0;
    padding: 0!important;
    overflow-x: hidden;
    @include opacity(0);
    // display: none;
    // background: none;
    // @include animation (opacityIn 2s);
    &.rtl {
        @extend .luma;
    }
    &.ja_lang {
        @extend .axisStd;
    }
    &.ch_lang {
        @extend .mfyueHei;
    }
    &.body-fade {
        @include opacity(1);
        @include transition(1s);
    }
}

img {
    &.alignleft {
        float: right;
    }
}

div.p1-style {
    margin-top: 0;
    margin-bottom: 10px;
    @include font-size($font-md, 21px);
    font-weight: 100;
    line-height: 28px;
    .ch_lang & {
        font-weight: $weight-thin;
    }
}

// p {
//   line-height: 20px !important;
// }
// Links >
a {
    cursor: pointer;
    @include transition(0.3s);
    @include opacity(0.9);
    &:hover {
        @include opacity(1);
    }
}

input[type=text]::-ms-clear {
    display: none;
}

blockquote {
    font-size: 20px;
    background: #b1b1b2;
    color: $white;
    font-weight: 600;
    border-left: 15px solid #c7c9cb;
    padding: 30px;
    margin: 25px 0;
    display: block;
    @media(max-width: $screen-xs-max) {
        font-size: 18px;
    }
    p {
        font-size: 20px!important;
        line-height: 28px!important;
        @media(max-width: $screen-xs-max) {
            font-size: 18px!important;
        }
    }
}

.modal-open {
    overflow-y: visible;
}

// lists >
// li {
// 	list-style: none;
// }
// Classes >
.no-padding-left {
    @media (min-width: $screen-md-min) {
        padding-left: 0;
    }
    .rtl & {
        @media (min-width: $screen-md-min) {
            padding-left: inherit;
            padding-right: 0;
        }
    }
}

.no-padding-right {
    @media (min-width: $screen-md-min) {
        padding-right: 0;
    }
    .rtl & {
        @media (min-width: $screen-md-min) {
            padding-right: inherit;
            padding-left: 0;
        }
    }
}

.no-padding {
    padding: 0;
}

.font-xs {
    font-size: $font-xs;
    @media (max-width: $screen-sm-max) {
        font-size: $font-xs-resp;
    }
}

.font-md {
    font-size: $font-md;
    @media (max-width: $screen-sm-max) {
        font-size: $font-md-resp;
    }
}

.font-size-sm {
    font-size: $font-sm;
    @media (max-width: $screen-sm-max) {
        font-size: $font-sm-resp;
    }
}

.font-sm {
    font-size: $font-sm;
    @media (max-width: $screen-sm-max) {
        font-size: $font-sm-resp;
    }
}

.font-size-lg {
    font-size: $font-lg;
    @media (max-width: $screen-sm-max) {
        font-size: $font-lg-resp;
    }
}

.font-lg {
    font-size: $font-lg;
    @media (max-width: $screen-sm-max) {
        font-size: $font-lg-resp;
    }
    @media (max-width: $screen-xs-max) {
        font-size: 24px;
    }
}

.font-xl {
    font-size: $font-xl;
    @media (max-width: $screen-sm-max) {
        font-size: $font-xl-resp;
    }
}

.font-hg {
    font-size: $font-hg;
    @media (max-width: $screen-sm-max) {
        font-size: $font-hg-resp;
    }
}

.container-fluid {
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        padding-right: 12px;
        padding-left: 12px;
    }
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
        width: 90%;
        max-width: 750px;
    }
    @media (min-width: $screen-sm-min) {
        padding: 0px;
    }
    @media (min-width: $screen-lg-min) {
        min-width: 90%;
        width: 1140px;
    }
    @media (min-width: $screen-lg-min) {
        min-width: 0;
    }
    @media (max-width: $screen-xs-max) {
        padding-right: 0px;
        padding-left: 0px;
        width: auto;
        margin-right: 12px;
        margin-left: 12px;
    }
}

.row,
.clearfix {
    &:before {
        content: none;
    }
    &:after {
        content: '';
    }
}

.overlay-black {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.56);
    @include transition(0.8s);
    display: none;
    opacity: 0;
    @media (min-width: $screen-md-min) {
        display: none !important;
    }
}

.section-heading-3 {
    // @extend .font-size-lg;
    @include font-size($font-lg, $font-lg-resp);
    color: $salt-box-grey;
}

.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
}

.flex-item {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.vertical-row {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .vertical-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.download-icon {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 21px;
    height: 20px;
    z-index: 2;
    .rtl & {
        left: auto;
        right: 20px;
    }
}

.select2-container--default .select2-results>.select2-results__options {
    .rtl & {
        direction: rtl;
    }
}

////////////
.text-area {
    p {
        @include font-size($font-sm, $font-sm-resp);
        font-weight: $weight-regular;
        // .ie & {
        // height: 100%!important;
        //   overflow: hidden;
        // }
    }
    .lead,
    h5 {
        // @extend .font-md;
        @include font-size($font-md, $font-md-resp);
        font-weight: $weight-regular;
        .ja_lang & {
            font-weight: $weight-thin;
        }
    }
    h3 {
        // @extend .font-size-lg;
        @include font-size($font-lg, $font-lg-resp);
        font-weight: $weight-regular;
    }
    h4 {
        // @extend .font-md;
        @include font-size($font-md, 21px);
        margin-top: 0;
        font-weight: $weight-light;
        line-height: 28px;
        .ja_lang & {
            font-weight: $weight-thin;
        }
        .ch_lang.chrome & {
            font-weight: $weight-thin;
            line-height: 1.4;
        }
    }
    ol {
        padding: 0;
        margin: 20px 0;
        counter-reset: olcounter;
        li {
            // list-style-type: lower-roman;
            list-style-position: inside;
            list-style: none; //
            color: $dark-smokey-grey;
            line-height: 1.2;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 25px;
            width: 100%;
            position: relative;
            &:before {
                content: counter(olcounter, lower-roman)".";
                counter-increment: olcounter;
                display: block;
                position: absolute;
                left: 0;
                .rtl & {
                    margin-left: 20px;
                    margin-right: 0;
                    left: auto;
                    right: 0;
                }
            }
            // &:before {
            // 	content: "";
            // 	width: 10px;
            // 	display: inline-block;
            // }
            ol {
                padding: 0 0 0 15px;
                margin: 20px 0;
                // li {
                //   // list-style-type: lower-alpha;
                // }
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
        &[type=a] {
            li {
                &::before {
                    content: counter(olcounter, lower-alpha)".";
                }
            }
        }
    }
    ul {
        margin: 20px 0;
        padding: 0;
        li {
            list-style: none;
            color: $dark-smokey-grey;
            line-height: 1.2;
            @include font-size($font-sm, $font-sm-resp);
            font-weight: $weight-regular;
            display: block;
            padding: 0 35px;
            width: 100%;
            position: relative;
            // &:before {
            //   content: "■";
            //   width: 20px;
            //   height: 20px;
            //   color: #4d88a3;
            //   margin-right: 20px;
            //   display: block;
            //   position: absolute;
            //   left: 0;
            //   .rtl & {
            //     margin-left: 20px;
            //     margin-right: 0;
            //     left: auto;
            //     right: 0;
            //   }
            // }
            ul {
                padding: 0 0 0 15px;
                margin: 20px 0;
                .rtl & {
                    padding: 0 15px 0 0;
                }
            }
        }
    }
    a {
        color: $dark-moderate-blue;
        display: inline;
        white-space: nowrap;
        @include font-size($font-sm, $font-sm-resp);
        cursor: pointer;
    }
    strong a {
        color: $dark-moderate-blue;
        font-weight: $weight-medium;
        display: inline-block;
        white-space: nowrap;
        @include font-size($font-sm, $font-sm-resp);
        cursor: pointer;
        .ch_lang & {
            font-weight: 400;
        }
    }
    a {
        font-weight: $weight-bold;
    }
}

/// Remove Google map copyright! >>
a[href^="http://maps.google.com/maps"] {
    display: none !important
}

a[href^="https://maps.google.com/maps"] {
    display: none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
    display: none;
}

.gmnoprint div {
    background: none !important;
}

/// Cookies Table {
table {
    background: $white;
    margin-bottom: 1.25rem;
    border: solid 1px #ddd;
    width: 100%;
    tbody {
        border-top: 1px solid #ccc;
        tr {
            &:first-child {
                border-bottom: 3px solid #ccc;
            }
            &:nth-of-type(even) {
                background: #f9f9f9;
            }
            th {
                font-family: inherit;
                color: #616163;
                padding: 9px 10px;
                color: #222222;
                text-align: left;
                .rtl & {
                    text-align: right;
                }
            }
            td {
                color: #616163;
                display: table-cell;
                line-height: 1.1;
                padding: 9px 10px;
                text-align: left;
                .rtl & {
                    text-align: right;
                }
                a {
                    font-weight: 700;
                }
            }
        }
    }
}

.player .video-wrapper,
.player .video-wrapper .telecine,
.player .video-wrapper object,
.player .video-wrapper video {
    width: auto!important;
}

figure {
    max-width: 100%;
}

.embed-responsive {
    @media (min-width: $screen-md-min) {
        height: 100%!important
    }
}

a {
    &:focus {
        outline: 0;
    }
}