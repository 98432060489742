.alm-listing {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
}

.news-blocks {
    .news-listing-container {
        padding: 54px 0;
        position: relative;
    }
    .news-heading {
        // @extend .font-size-lg;
        @include font-size($font-lg, $font-lg-resp);
        font-weight: $weight-medium;
        padding: 0 0 50px 0;
        margin: 0;
        border-bottom: 2px solid $lighter-grey;
        color: $dark-grey;
        .rtl & {
            text-align: right;
        }
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    .news-block {
        position: relative;
        padding: 30px 0 35px;
        border-bottom: 2px solid $lighter-grey;
        .news-logo {
            padding: 0px;
            @media (max-width: $screen-xs-max) {
                display: block;
            }
            .media-object {
                display: inline-block;
                position: absolute;
                left: 0;
                top: 5px;
                right: auto;
                max-width: 100%;
                .rtl & {
                    right: 0;
                    left: auto;
                }
                @media (max-width: $screen-xs-max) {
                    margin: auto;
                    position: static;
                    width: 55px;
                    height: 50px;
                }
            }
        }
        .news-body {
            color: $dark-smokey-grey;
            @media (max-width: $screen-xs-max) {
                padding: 30px 0 0 10px;
                .rtl & {
                    padding: 30px 10px 0 0;
                    text-align: right;
                }
            }
            @media (min-width: $screen-sm-min) {
                padding: 0 0 0 20px;
                .rtl & {
                    padding: 0 20px 0 0;
                }
            }
            h3 {
                width: 80%;
                display: inline-block;
                margin: 0 0 5px 0;
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
                font-weight: $weight-regular;
                line-height: 1.2;
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .news-date {
                position: absolute;
                right: 0;
                left: auto;
                top: 5px;
                bottom: auto;
                @include font-size($font-sm, $font-sm-resp);
                .rtl & {
                    left: 0;
                    right: auto;
                }
                @media (max-width: $screen-xs-max) {
                    top: -2px;
                    left: 25px;
                    right: auto;
                    font-size: $font-md-resp;
                    .rtl & {
                        right: 25px;
                        left: auto;
                    }
                }
            }
            // p {
            //   @include font-size($font-sm, $font-sm-resp);
            //   margin: 5px 0 30px;
            //   @media (max-width: $screen-xs-max) {
            //     margin: 5px 0 0;
            //   }
            // }
            h2 {
                @include font-size(32px, $font-lg-resp);
                margin: 20px 0;
                font-weight: $weight-medium;
                .ja_lang & {
                    font-weight: $weight-regular;
                    line-height: 1.25;
                }
                // .ch_lang & {
                //     font-weight: 400;
                // }
            }
            h4 {
                font-size: 24px;
                font-weight: $weight-light;
                line-height: 28px;
                margin-bottom: 0;
                // .ch_lang & {
                //     font-weight: $weight-thin;
                // }
            }
            p {
                margin: 10px 0 20px 0;
                @include font-size(16px, $font-sm-resp);
                font-weight: $weight-regular;
                line-height: 1.2;
                .ch_lang & {
                    line-height: 1.6;
                }
            }
            .news-address {
                display: block;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-medium;
                // .ch_lang & {
                //     font-weight: 400;
                // }
                p {
                    margin: 0;
                }
                @media (max-width: $screen-xs-max) {
                    margin-bottom: 15px;
                }
            }
            .url {
                display: inline-block;
                color: $dark-moderate-blue;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-medium;
                // .ch_lang & {
                //     font-weight: 400;
                // }
                @media (max-width: $screen-xs-max) {
                    margin-top: 15px;
                }
            }
            .block-options {
                position: absolute;
                bottom: -7px;
                right: 0;
                left: auto;
                display: inline-block;
                @media (max-width: $screen-sm-max) {
                    display: none;
                }
                .svg-wraper {
                    margin-left: -3px;
                    display: inline-block;
                    padding: 0 30px;
                    border-right: 2px solid $light-grey;
                    .rtl & {
                        margin-left: 0;
                        border-right: none;
                        border-left: 2px solid $light-grey;
                    }
                    &:last-child {
                        padding-right: 0px;
                        border-right: none;
                        .rtl & {
                            padding-left: 0;
                            padding-right: 30px;
                            border-right: none;
                            border-left: none;
                        }
                    }
                    &:first-child {
                        padding-left: 0;
                        .rtl & {
                            padding-right: 0;
                            padding-left: 30px;
                            border-left: 2px solid $light-grey;
                        }
                    }
                    svg {
                        margin-top: 28%;
                    }
                }
                .rtl & {
                    left: 0px;
                    right: auto;
                }
            }
        }
    }
}