.press-packs-listing {
    .news-listing-container {
        padding: 24px 0px 54px 0;
    }
    div.flex-item {
        @media(min-width: $screen-md-min) {
            min-height: 1200px;
        }
    }
    .flex-item {
        @media(max-width: $screen-xs-max) {
            padding: 0px;
        }
    }
    .news-block {
        padding: 30px 0;
        .media-left {
            @media (max-width: $screen-xs-max) {
                padding: 0;
                display: block;
            }
            img.media-object {
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .news-logo {
            img {
                width: 100%;
            }
        }
        .news-body {
            .media-img {
                position: absolute;
                right: 0;
                top: 0;
                bottom: auto;
                left: auto;
                .rtl & {
                    left: 0;
                    right: auto;
                }
                @media (max-width: $screen-xs-max) {
                    top: 30px;
                    left: 0;
                    right: auto;
                    .rtl & {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .news-details {
                margin: 0 0 5px 0;
                .news-date {
                    position: static;
                    display: inline-block;
                    font-weight: 500;
                    padding-right: 10px;
                    border-right: 1px solid #616163;
                    .rtl & {
                        padding-right: 0;
                        padding-left: 10px;
                        border-right: none;
                        border-left: 1px solid #616163;
                    }
                    @media (max-width: $screen-xs-max) {
                        font-size: 12px;
                    }
                }
                .news-number {
                    display: inline-block;
                    padding-left: 7px;
                    font-weight: 500;
                    .rtl & {
                        padding-right: 7px;
                        padding-left: 0;
                    }
                    @media (max-width: $screen-xs-max) {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                    .num {
                        margin-left: 3px;
                        .rtl & {
                            margin-left: 0;
                            margin-right: 3px;
                        }
                    }
                }
            }
            .url {
                margin-top: 20px;
                @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                }
            }
            .block-options {
                bottom: -5px;
                @media (max-width: $screen-sm-max) {
                    display: block;
                }
                @media(max-width: $screen-xs-max) {
                    position: static;
                    margin-top: 20px;
                }
                .svg-wraper {
                    &:nth-child(1) {
                        border-right: 2px solid #dcdcdc;
                        border-left: none;
                        .rtl & {
                            border-left: 2px solid #dcdcdc;
                            border-right: none;
                        }
                        @media (max-width: $screen-sm-max) {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        border-right: none;
                        border-left: none;
                        @media (max-width: $screen-sm-max) {
                            padding-left: 4px;
                            .rtl & {
                                padding-right: 0;
                                padding-left: 30px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        height: 31px;
                        vertical-align: bottom;
                        border-right: none;
                        border-left: 2px solid #dcdcdc;
                        .rtl & {
                            border-left: none;
                            border-right: 2px solid #dcdcdc;
                        }
                    }
                    .social_share {
                        margin-top: 4px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .aside-content {
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
}