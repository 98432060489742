.header-slider-inner {
    position: relative;
    &.slick-slider {
        svg {
            fill: $white;
        }
        .slider-arrow {
            position: absolute;
            top: 50%;
            z-index: 20;
            cursor: pointer;
        }
        .slider-arrow-next {
            text-align: right;
            right: 2%;
            .rtl {
                text-align: left;
            }
        }
        .slider-arrow-prev {
            left: 2%;
        }
        .slick-prev {
            left: 0;
            top: auto;
            bottom: 0;
            height: 20px;
            width: 55px;
            @media (max-width: $screen-xs-max) {
                left: 0;
            }
        }
        .slick-next {
            right: 100px;
            top: auto;
            bottom: 0;
            height: 20px;
            width: 55px;
            @media (max-width: $screen-xs-max) {
                right: 0;
            }
        }
        .slick-next:before,
        .slick-prev:before {
            font-size: 55px;
            opacity: 1;
            color: #7d7a76;
            line-height: 0.3;
            @media (max-width: $screen-xs-max) {
                font-size: 40px;
            }
        }
        .slick-item-icon {
            img {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    .slick-slide {
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        .slide-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            animation: zoombackground 16s ease 3s infinite alternate;
        }
        >img {
            // min-width: 100%;
            animation: zoombackground 16s ease 3s infinite alternate;
            @media (max-width: $screen-sm-max) {
                height: 670px;
                position: relative;
                // left: 50%;
                transform: translateX(-50%);
            }
            .firefox & {
                max-height: 100%;
            }
        }
        &::after {
            content: "";
            z-index: 10;
            position: absolute;
            top: -2px;
            left: -2px;
            width: 100%;
            height: 100%;
            background: url(../images/header-slider-overlay.svg) no-repeat;
            background-size: 100% auto;
        }
        .slide-content-container {
            position: absolute;
            top: 125px;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 11;
            .slide-content {
                width: 100%;
                position: absolute;
                top: auto;
                bottom: 125px;
                padding: 0;
                .ch_lang & {
                    bottom: 155px;
                }
                @media only screen and (max-width: $screen-sm-max) {
                    bottom: 165px
                }
                @media only screen and (max-width: $screen-xs-max) {
                    bottom: 150px;
                }
            }
            // .text-custom-position {
            //   @media only screen and (max-width: $screen-sm-max) {
            //     top: 60px;
            //     bottom: auto;
            //   }
            //   @media only screen and (max-width: $screen-xs-max) {
            //     top: 95px;
            //     bottom: auto;
            //   }
            // }
        }
        &.slide404 {
            h3,
            h4 {
                font-weight: $weight-light;
                // .ch_lang & {
                //     font-weight: $weight-thin;
                // }
            }
            h3 {
                font-size: 54px;
                @media (max-width: $screen-sm-max) {
                    font-size: 36px
                }
            }
            h4 {
                font-size: 36px;
                @media (max-width: $screen-sm-max) {
                    font-size: 28px
                }
            }
        }
    }
    &.slick-slider.slick-dotted {
        margin-bottom: 0;
        .slick-dots {
            @extend .container;
            left: 0;
            right: 0;
            bottom: 80px;
            text-align: left;
            @media only screen and (max-width: $screen-sm-max) {
                bottom: 125px;
            }
            .rtl & {
                text-align: right;
            }
            li {
                margin: 0 10px;
                &:first-child {
                    margin-left: 0;
                }
                .rtl & {
                    margin: 0 10px;
                    &:first-child {
                        margin-right: 0;
                    }
                }
                button {
                    &:before {
                        content: "";
                        width: 16px;
                        height: 16px;
                        background-color: $white;
                        opacity: 1;
                        border-radius: 50%;
                    }
                }
            }
            li.slick-active {
                button {
                    &:before {
                        font-size: 45px;
                        background-color: #6b676a;
                        border: 1px solid $white;
                        border-radius: 50%;
                        opacity: 0.5;
                    }
                }
            }
        }
        // .list-custom-position {
        //   @media only screen and (max-width: $screen-sm-max) {
        //     top: 310px;
        //     bottom: auto;
        //   }
        // }
        @media only screen and (max-width: $screen-sm-max) {
            .slider-arrow.slick-arrow {
                visibility: hidden;
            }
        }
    }
    .embed-responsive .embed-responsive-item,
    .embed-responsive embed,
    .embed-responsive iframe,
    .embed-responsive object,
    .embed-responsive video {
        top: auto!important;
    }
}

.header-slider-inner-list {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .25);
    .slide-list {
        position: relative;
        margin: auto;
        left: 0;
        right: 0;
        padding: 15px 0;
        @media(max-width: $screen-sm-max) {
            max-height: 335px;
            overflow-y: auto;
        }
        .list-item {
            padding: 0 40px 0 0;
            display: inline-block;
            margin: 0;
            font-size: $font-xs;
            font-weight: $weight-bold;
            color: $white;
            .rtl & {
                padding-left: 40px;
                padding-right: 0;
                &:last-child {
                    padding-left: 0;
                }
            }
            &:last-child {
                padding-right: 0;
            }
            svg {
                position: absolute;
                fill: $white;
                display: none;
                cursor: pointer;
                @media only screen and (max-width: $screen-sm-max) {
                    display: block;
                    position: absolute;
                    right: 75px;
                    top: 41px;
                    transform: rotate(-90deg);
                    .rtl & {
                        left: 75px;
                        right: auto;
                    }
                }
                @media only screen and (max-width: $screen-xs-max) {
                    right: 25px;
                    left: auto;
                    .rtl & {
                        left: 25px;
                        right: auto;
                    }
                }
                .rtl & {
                    transform: rotate(90deg);
                }
            }
            .list-open {
                transform: rotate(0deg);
                .rtl & {
                    transform: rotate(0deg);
                }
            }
            @media only screen and (max-width: $screen-sm-max) {
                padding: 20px 0;
                font-size: $font-sm-resp;
            }
            .list-link {
                color: $white;
                @include opacity(0.6);
                &:hover {
                    @media (min-width: $screen-md-min) {
                        @include opacity(1);
                    }
                }
                &:focus {
                    @include opacity(1);
                }
            }
            &.active {
                .list-link {
                    @include opacity(1);
                }
            }
        }
    }
}

.slide-title {
    position: absolute;
    bottom: 120px;
    color: white;
    font-size: 64px;
    font-weight: 100;
    margin: 0px auto;
    width: 100%;
    // .ch_lang & {
    //     font-weight: $weight-thin;
    // }
    @media only screen and (max-width: $screen-sm-max) {
        bottom: 160px;
    }
    @media only screen and (max-width: $screen-xs-max) {
        bottom: 150px;
    }
    .main-slide-title {
        color: $white;
        font-size: $font-lg;
        font-weight: $weight-medium;
        margin-bottom: 0;
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    .sub-slide-title {
        color: $white;
        font-size: 64px;
        font-weight: 100;
        margin-top: -5px;
        margin-bottom: 0;
        // .ch_lang & {
        //     font-weight: $weight-thin;
        // }
        @media only screen and (max-width: $screen-xs-max) {
            font-size: $font-xl-resp;
        }
    }
    &.not-found {
        bottom: -25px;
        @media only screen and (max-width: $screen-sm-max) {
            bottom: -110px;
        }
        @media only screen and (max-width: $screen-xs-max) {
            bottom: -125px;
        }
    }
}