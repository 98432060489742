.main-footer {
    font-weight: 400!important;
    h6 {
        margin: 7px 0 10px 0px;
        font-weight: $weight-medium;
        font-size: $font-xs;
        color: $dark-smokey-grey;
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    li {
        list-style: none;
    }
    /// >> footer grid classes
    .footer-about-menu {
        @extend .col-md-push-3;
    }
    .footer-right-menu {
        @extend .col-md-push-8;
    }
    .footer-social {
        @extend .col-md-pull-4;
    }
    .footer-business-menu {
        @extend .col-md-pull-2;
    }
    .rtl & {
        .footer-social {
            @extend .col-md-pull-0;
        }
        .footer-business-menu {
            @extend .col-md-pull-0;
        }
    }
}