.prespectives-details {
    position: relative;
    .listing-container {
        position: relative;
        padding: 24px 0 54px;
    }
    .article-content {
        .cover-img {
            margin-bottom: 30px;
        }
        .article-text {
            p {
                line-height: 20px;
            }
            h4 {
                font-size: 24px;
            }
        }
        p.box-paragraph {
            background-color: $light-grayish-blue;
            padding: 30px;
            font-size: 22px;
            color: $dark-smokey-grey;
            margin: 40px 0;
        }
        .date {
            span {
                display: block;
            }
        }
    }
    .flex-item {
        padding: 0;
    }
    .author-details-top {
        margin-bottom: 25px;
        @media(max-width: $screen-sm-max) {
            margin: 70px 0;
        }
        .img-author {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-size: cover;
            background-position: center 0;
            @media(max-width: $screen-sm-max) {
                width: 135px;
                height: 135px;
            }
            @media(max-width: $screen-xs-max) {
                width: 100px;
                height: 100px;
            }
        }
        .url-holder {
            margin-top: 8px;
            margin-left: -45px;
            .rtl & {
                margin-left: 0;
                margin-right: -45px;
            }
            @media(max-width: $screen-sm-max) {
                margin-top: 0;
                margin-left: -50px;
                .rtl & {
                    margin-left: 0;
                    margin-right: -50px;
                }
            }
            @media(max-width: $screen-xs-max) {
                padding-right: 0;
                padding-left: 30px;
                margin-left: 0;
                .rtl & {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 30px;
                }
            }
            .sub-url {
                // font-weight: 400;
                display: block;
                color: #51859a;
                // font-size: 16px;
                @media(max-width: $screen-sm-max) {
                    font-size: 24px;
                }
                &:last-child {
                    @media(max-width: $screen-xs-max) {
                        font-size: 18px;
                    }
                }
            }
            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
            h4 {
                font-size: 24px;
                line-height: 28px;
                font-weight: 100;
            }
        }
    }
    .author-details-bottom {
        @media(max-width: $screen-sm-max) {
            margin: 70px 0;
        }
        .author-header {
            font-size: 32px;
            color: #6b676a;
            margin: 50px 0 25px;
        }
        .img-author {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            @media(max-width: $screen-sm-max) {
                width: 135px;
                height: 135px;
            }
            @media(max-width: $screen-xs-max) {
                width: 100px;
                height: 100px;
            }
        }
        .url-holder {
            margin-top: 8px;
            margin-left: -80px;
            .rtl & {
                margin-left: 0;
                margin-right: -80px;
            }
            @media(max-width: 1024px) {
                margin-left: -55px;
                .rtl & {
                    margin-left: 0;
                    margin-right: -80px;
                }
            }
            @media(max-width: $screen-sm-max) {
                margin-top: 0;
                margin-left: -50px;
                .rtl & {
                    margin-left: 0;
                    margin-right: -50px;
                }
            }
            @media(max-width: $screen-xs-max) {
                padding-right: 0;
                padding-left: 30px;
                margin-left: 0;
                .rtl & {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 30px;
                }
            }
            .sub-url {
                // font-weight: 400;
                display: block;
                color: #51859a;
                // font-size: 16px;
                @media(max-width: $screen-sm-max) {
                    font-size: 24px;
                }
                &:last-child {
                    @media(max-width: $screen-xs-max) {
                        font-size: 18px;
                    }
                }
            }
            .url-paragraph {
                margin: 15px 0 0 0;
                font-size: 16px;
                @media(max-width: $screen-sm-max) {
                    font-size: 24px;
                }
                @media(max-width: $screen-xs-max) {
                    font-size: 18px;
                }
            }
            p {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
            h4 {
                font-size: 24px;
                line-height: 28px;
                font-weight: 100;
            }
        }
    }
    .news-block {
        padding: 30px 0 60px;
        .media-left {
            @media (max-width: $screen-xs-max) {
                padding: 0;
                display: block;
            }
            img.media-object {
                @media (max-width: $screen-xs-max) {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .news-logo {
            img {
                width: 100%;
            }
        }
        .news-body {
            padding-left: 0;
            padding-right: 0;
            .media-img {
                position: absolute;
                right: 0;
                top: 0;
                bottom: auto;
                left: auto;
                .rtl & {
                    left: 0;
                    right: auto;
                }
            }
            .news-details {
                margin: 0 0 5px 0;
                .news-date {
                    position: static;
                    display: inline-block;
                    font-weight: 500;
                    padding-right: 10px;
                    border-right: 1px solid #616163;
                    .rtl & {
                        padding-right: 0;
                        padding-left: 10px;
                    }
                }
                .news-source {
                    display: inline-block;
                    padding-left: 7px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #4e869f;
                    .rtl & {
                        padding-right: 7px;
                        padding-left: 0;
                    }
                }
            }
            p {
                margin-bottom: 10px;
            }
            .sub-url {
                font-weight: 400;
                display: block;
                color: #51859a;
                font-size: 16px;
            }
            .block-options {
                bottom: -30px;
                .svg-wraper {
                    border-right: none;
                    &:nth-child(2) {
                        border-right: 2px solid #dcdcdc;
                        border-left: 2px solid #dcdcdc;
                    }
                }
            }
        }
    }
    .aside-content {
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
    .modal {
        padding-right: 0!important;
        .modal-dialog {
            padding: 50px;
            margin-top: 5%;
            box-shadow: 0px 1px 10px 0px;
            background-color: $white;
            @media (max-width: $screen-sm-max) {
                margin-top: 15%;
            }
            @media (max-width: $screen-xs-max) {
                padding: 35px 15px;
                margin-top: 30%;
            }
            .modal-content {
                margin: auto;
                border: none;
                box-shadow: none;
                .close {
                    opacity: 1;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                    left: auto;
                    &:hover {
                        opacity: 1;
                    }
                    &:focus {
                        outline: 0;
                    }
                    span {
                        font-size: 35px;
                        font-weight: 500;
                        color: $dark-moderate-blue;
                        &:focus {
                            outline: 0;
                        }
                    }
                    @media (max-width: $screen-xs-max) {
                        right: -5px
                    }
                }
                .modal-body {
                    padding: 0;
                    .carousel {
                        .carousel-inner {
                            position: relative;
                            .item {
                                .media-img {
                                    // position: absolute;
                                    top: 0;
                                    z-index: 1;
                                    // &:focus,
                                    // &:hover {
                                    //     cursor: pointer;
                                    // }
                                }
                                .details {
                                    h3 {
                                        font-weight: 500;
                                        font-size: 20px;
                                        padding-bottom: 25px;
                                        margin: 25px 0 20px;
                                        color: $dark-moderate-blue;
                                        border-bottom: 1px solid $light-grey;
                                        @media (max-width: $screen-xs-max) {
                                            font-size: 18px;
                                        }
                                    }
                                    p {
                                        font-size: 16px;
                                        margin-bottom: 0;
                                        margin-top: 20px;
                                    }
                                }
                                img {
                                    max-width: 100%;
                                }
                                .block-options {
                                    padding-top: 0;
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid $light-grey;
                                    @media (max-width: $screen-sm-max) {
                                        display: none;
                                    }
                                    .svg-wraper {
                                        margin-left: -3px;
                                        display: inline-block;
                                        padding: 0 30px;
                                        border-right: 2px solid $light-grey;
                                        max-height: 35px;
                                        .rtl & {
                                            margin-left: 0;
                                            border-right: none;
                                        }
                                        &:last-child {
                                            padding-right: 0;
                                            border-right: none;
                                            .rtl & {
                                                margin-left: 0;
                                                padding-left: 0;
                                                padding-right: 30px;
                                                border-right: 2px solid $light-grey;
                                            }
                                        }
                                        &:first-child {
                                            margin-left: 0;
                                            padding-left: 0;
                                            .rtl & {
                                                padding-right: 0;
                                                padding-left: 30px;
                                            }
                                        }
                                        svg {
                                            margin-top: 15%;
                                            &:focus,
                                            &:hover {
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .arrow-media {
                position: absolute;
                top: 175px;
                @media (max-width: $screen-xs-max) {
                    top: 120px;
                }
                .slider-arrow {
                    fill: #88888a;
                }
            }
            .arrow-media-right {
                right: 15px;
                left: auto;
                .rtl & {
                    left: 15px;
                    right: auto;
                }
            }
            .arrow-media-left {
                left: 15px;
                right: auto;
                .rtl & {
                    right: 15px;
                    left: auto;
                }
            }
        }
        h4 {
            font-size: 32px;
            font-weight: 100;
            line-height: 1.2;
        }
        img {
            // display: block;
            max-width: 100%;
            // height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
}