.newsletter {
    background-color: $white-smoke;
    form {
        margin-left: -1px;
        .rtl & {
            margin-left: 0;
            margin-right: -1px;
        }
    }
    .newsletter-form {
        font-size: $font-xs;
        .form-control {
            .rtl & {
                float: right;
            }
        }
        .footer-input {
            border-top: none;
            border-bottom: none;
            border-left-color: $white-smoke;
            border-right-color: $white-smoke;
            @media (min-width: $screen-md-min) {
                width: 156px;
            }
            .rtl & {
                &:first-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
            // .ja_lang.ie & {
            //   width: 199px;
            // }
            &:focus {
                box-shadow: none;
                padding: 6px 12px;
            }
        }
        .footer-btn {
            @extend .button-side-padding;
            background-color: $dark-moderate-blue;
            border-color: $dark-moderate-blue;
            color: $white;
            font-weight: $weight-bold;
            outline: 0;
            min-width: 80px;
            .rtl & {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        // select2 in Footer
        .select2.select2-container.select2-container--default,
        .select2.select2-container.select2-container--default.select2-container--below.select2-container--open,
        .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
        .select2.select2-container.select2-container--default.select2-container--above.select2-container--open,
        .select2.select2-container.select2-container--default.select2-container--above.select2-container--focus {
            width: 156px!important;
            margin-left: 1px;
            top: 1px;
            &:focus {
                outline: 0;
            }
            .rtl & {
                margin-left: 0;
                margin-right: 1px;
            }
            @media (max-width: $screen-sm-max) {
                top: 0;
                height: 85px!important;
                width: 100%!important;
                margin-top: 25px;
                margin-bottom: 25px;
            }
            @media (max-width: $screen-xs-max) {
                height: 43px!important;
                width: 100%!important;
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }
        .select2-selection__rendered {
            color: #A5A5A5!important;
            line-height: 33px!important;
            padding: 0 12px;
            @media (max-width: $screen-sm-max) {
                padding: 10px 15px;
                font-size: 24px;
                line-height: 62px!important;
            }
            @media (max-width: $screen-xs-max) {
                padding: 10px 15px;
                font-size: 12px;
                line-height: 25px!important;
            }
        }
        .select2-selection.select2-selection--single {
            border: none;
            border-radius: 0;
            height: 33px!important;
            width: 156px!important;
            &:focus {
                outline: 0;
            }
            @media (max-width: $screen-sm-max) {
                height: 85px!important;
                width: 100%!important;
                border-radius: 4px;
            }
            @media (max-width: $screen-xs-max) {
                height: 43px!important;
                width: 100%!important;
                border-radius: 4px;
            }
        }
        .select2-selection__arrow {
            top: 6px;
            right: 5px;
            height: 30px!important;
            .rtl & {
                top: 0;
                left: 5px;
                right: auto;
                @include transform(rotate(180deg));
            }
            &:before {
                content: "";
                background: url('../images/right_arrow.png') no-repeat center center;
                width: 16px;
                height: 15px;
                background-size: contain;
                vertical-align: middle;
                display: inline-block;
                @include transition(0.3s);
            }
            &.arrow_rotate {
                &:before {
                    @include transform(rotate(-90deg));
                    .rtl & {
                        @include transform(rotate(90deg));
                    }
                    @media (max-width: $screen-xs-max) {
                        @include transform(rotate(90deg));
                    }
                }
            }
            @media (max-width: $screen-sm-max) {
                top: 27px;
                right: 10px;
                .rtl & {
                    left: 10px;
                    right: auto;
                }
            }
            @media (max-width: $screen-xs-max) {
                top: 5px;
            }
            b {
                display: none!important;
            }
        }
    }
    // media query for Tablets >>
    @media only screen and (max-width: $screen-sm-max) {
        margin-top: 30px;
        .form-header {
            margin: 10px 0px;
        }
        .input-group {
            width: 100%;
            .footer-input {
                font-size: $font-md;
                padding: 20px 15px;
                text-align: left;
                margin: 25px 0 0 0;
                border-radius: 0;
                height: 85px;
                width: 50%;
                &:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    .rtl & {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
                // &:nth-child(2) {
                // 	// border-top-left-radius: 0;
                // 	// border-bottom-left-radius: 0;
                // 	.rtl & {
                // 		border-top-left-radius: 7px;
                // 		border-bottom-left-radius: 7px;
                // 		border-top-right-radius: 0;
                // 		border-bottom-right-radius: 0;
                //    }
                // }
            }
            .block-input {
                width: 100%;
            }
            .input-group-btn {
                display: inline-block;
                width: 100%;
                .footer-btn {
                    font-size: $font-md;
                    margin: 25px 0 0 0;
                    height: 85px;
                    width: 100%;
                    border-radius: 4px;
                    &:first-child {
                        border-left: 0;
                    }
                }
                &:not(:first-child):not(:last-child) {
                    border-radius: 4px;
                }
            }
            .form-control {
                &:not(:first-child):not(:last-child) {
                    border-radius: 4px;
                    &:nth-child(2) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        .rtl & {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }
                }
            }
        }
        // .input-group-addon:not(:first-child):not(:last-child),
        // .input-group-btn:not(:first-child):not(:last-child),
        // .input-group .form-control:not(:first-child):not(:last-child) {
        // 	border-radius: 7px;
        // }
    }
    // media query for Mobiles >>
    @media only screen and (max-width: $screen-xs-max) {
        margin-top: 20px;
        .form-header {
            margin: 5px 0;
        }
        .input-group {
            .footer-input {
                margin: 12px 0 0 0;
                font-size: $font-xs-resp;
                height: 43px;
            }
            .input-group-btn {
                display: inline-block;
                width: 100%;
                .footer-btn {
                    margin: 0;
                    font-size: $font-xs-resp;
                    height: 43px;
                }
            }
        }
    }
}

.select2-container--open .select2-dropdown--above {
    border: 1px solid #51859a;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    left: -1px;
}

.mc4wp-response {
    .mc4wp-response {
        p {
            margin: 12px 0 0 0;
        }
    }
}