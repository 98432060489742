.search-container {
    position: relative;
    padding: 54px 0;
}

.search-result-form {
    padding: 30px 0;
    margin-bottom: 5px;
    position: relative;
    .search-page-input {
        width: 100%;
        box-shadow: none;
        outline: none;
        border: 1px solid $light-grey;
        border-radius: 0;
        height: 51px;
        padding: 0px 25px;
        color: $dark-smokey-grey;
        background: url(images/sprite/search.svg);
        @include font-size($font-sm, $font-sm-resp);
    }
    .searchIcon {
        position: absolute;
        display: block;
        top: 50%;
        right: 20px;
        line-height: 0;
        @include transform(translateY(-50%));
        .rtl & {
            right: auto;
            left: 20px;
        }
    }
}

.serach-result-count {
    display: block;
    width: 100%;
    margin-top: 50px;
    @include font-size($font-sm, $font-sm-resp);
    color: $dark-smokey-grey;
    font-weight: $weight-bold;
    text-align: right;
    .rtl & {
        text-align: left;
    }
    @media (max-width: $screen-sm-max) {
        margin-top: 0px;
        margin-bottom: 35px;
        text-align: center;
        .rtl & {
            text-align: center;
        }
    }
}

.search-element {
    padding: 32px;
    >.col-xs-12 {
        padding: 0;
    }
    &:nth-child(odd) {
        background-color: $lighter-grayish-blue;
    }
    .search-element-img {
        min-width: 143px;
        vertical-align: middle;
        text-align: center;
        img {
            max-width: 143px;
            display: inline-block;
        }
        @media (max-width: $screen-xs-max) {
            display: block;
            text-align: center;
            padding: 0;
            margin: 0;
            img {
                display: inline-block;
            }
        }
    }
    .search-element-details {
        padding: 15px 18% 0 38px;
        .rtl & {
            padding: 15px 38px 0 18%;
        }
        @media (max-width: $screen-sm-max) {
            padding: 15px 22px 0 22px;
            .rtl & {
                padding: 15px 22px 0 22px;
            }
        }
        @media (max-width: $screen-xs-max) {
            padding: 20px;
            text-align: center;
        }
        h3 {
            font-size: $font-xs-resp;
            font-weight: $weight-medium;
            margin: 0 0 6px;
            color: $dark-smokey-grey;
            // .ch_lang & {
            //     font-weight: 400;
            // }
        }
        h2 {
            // @extend .font-md;
            @include font-size($font-md, $font-md-resp);
            margin: 0 0 12px;
            font-weight: $weight-regular;
            color: $dark-smokey-grey;
        }
        p {
            @include font-size($font-sm, $font-sm-resp);
            text-overflow: ellipsis;
            margin: 0 0 12px;
            color: $dark-smokey-grey;
            overflow: hidden;
            line-height: 0!important;
            // .ie & {
            //   @media (min-width: $screen-md-min) {
            //     width: 95%;
            //   }
            // }
        }
        a {
            @include font-size($font-sm, $font-sm-resp);
            color: $light-moderate-blue;
            font-weight: $weight-medium;
            white-space: nowrap;
            // .ch_lang & {
            //     font-weight: 400;
            // }
        }
    }
}

.load-more-section {
    // margin:54px auto;
    // position: relative;
    &.more-border-top {
        border-top: 1px solid $light-grey;
        margin-top: 5px;
        padding-top: 50px;
    }
    .alm-listing.alm-ajax {
        margin-left: 0;
        margin-right: 0;
    }
    .alm-reveal {
        width: 100%;
    }
    .alm-btn-wrap {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    .more-button {
        width: 108px;
        height: 54px;
        color: $med-grey;
        border: 1px solid $med-grey;
        font-weight: $weight-medium;
        // @extend .font-xs;
        @include font-size($font-xs, $font-xs-resp);
        display: block;
        margin: 50px auto 5px;
        outline: none;
        background: none;
        // .ch_lang & {
        //     font-weight: 400;
        // }
    }
    button.done {
        display: none;
    }
}

.scrollTopButton {
    position: absolute;
    width: 36px;
    height: 35px;
    right: 0;
    bottom: 10px;
    border: 1px solid $dark-moderate-blue;
    cursor: pointer;
    @media (max-width: $screen-sm-max) {
        display: none;
    }
    // &.no-more {
    //   // bottom: -40px;
    // }
    .rtl & {
        right: auto;
        left: 0px;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 7px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 2px;
        right: 0;
        margin: auto;
        @include transform(rotate(-90deg));
        background: url($blue-arrow) no-repeat;
        background-size: contain;
    }
}

.no-res {
    width: 100%;
    // @extend .font-md;
    @include font-size($font-md, $font-md-resp);
    text-align: center;
    margin: 60px auto 245px;
    padding-left: 15px;
    padding-right: 15px;
}