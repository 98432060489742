.footer-social {
    .social-icons {
        display: block;
        margin-top: 15px;
        .rtl & {
            margin: 0;
        }
        .social-block {
            display: inline-block;
            @media only screen and (max-width: $screen-sm-max) {
                display: block;
                &:first-child {
                    margin-bottom: 40px;
                }
            }
            @media only screen and (max-width: $screen-xs-max) {
                &:first-child {
                    margin-bottom: 30px;
                }
            }
            a {
                margin: 0 25px 0 0;
                display: inline-block;
                @include opacity(0.35);
                svg {
                    fill: $dark-smokey-grey;
                }
                .rtl & {
                    margin: 0 0 0 25px;
                }
                &:hover {
                    @media (min-width: $screen-md-min) {
                        @include opacity(1);
                    }
                }
            }
        }
        /// Custom media query for Tablets >>>
        @media only screen and (max-width: $screen-sm-max) {
            display: inline-block;
            margin: 15px 0 35px 0;
            .social-block {
                a {
                    margin: 1px 60px 0 0;
                    // .ja_lang &,
                    // .ch_lang & {
                    //     margin: 0 100px 0 0;
                    //     &:last-child {
                    //         margin: 0;
                    //     }
                    // }
                    &:last-child {
                        margin: 0;
                    }
                    svg {
                        fill: $dark-smokey-grey;
                        opacity: 0.9;
                    }
                    .rtl & {
                        margin: 0 0 0 100px;
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
                // .linkedin-icon {
                //   svg {
                //     width: 41px;
                //     height: 40px;
                //   }
                // }
                // .facebook-icon {
                //   svg {
                //     width: 33px;
                //     height: 39px;
                //   }
                // }
                // .instagram-icon {
                //   svg {
                //     width: 41px;
                //     height: 40px;
                //   }
                // }
                // .googleplus-icon {
                //   svg {
                //     width: 60px;
                //     height: 38px;
                //   }
                // }
            }
        }
        /// Custom media query for Mobiles >>>
        @media only screen and (max-width: $screen-xs-max) {
            .social-block {
                a {
                    margin: 0 50px 0 0;
                    .ja_lang &,
                    .ch_lang & {
                        margin: 0 50px 0 0;
                        &:last-child {
                            margin: 0;
                        }
                    }
                    &:last-child {
                        margin: 0;
                    }
                    .rtl & {
                        margin: 0 0 0 50px;
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
                // .linkedin-icon {
                //   svg {
                //     width: 21px;
                //     height: 20px;
                //   }
                // }
                // .facebook-icon {
                //   svg {
                //     width: 20px;
                //     height: 19px;
                //   }
                // }
                // .instagram-icon {
                //   svg {
                //     width: 21px;
                //     height: 20px;
                //   }
                // }
                // .googleplus-icon {
                //   svg {
                //     width: 30px;
                //     height: 19px;
                //   }
                // }
            }
        }
    }
}