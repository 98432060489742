$icon-font-path: '../fonts/'; 

//// Colors >>> 
$white: #fff;
$black: #000;

$white-smoke: #EFEFEF;
$off-white: #f0edea;
$light-grey: #dcdcdc; // used as menu dropdown bottom border color
$med-grey: #bfbfbf; 
$cloud: #c2baaf; 
$topaz-grey: #7e7d80; 
$dolphin-grey: #717173;  
$dark-smokey-grey: #616163;
$salt-box-grey: #6b676a;
$bright-grey: #56565b;
$dark-grey: #58585b;
$darker-grey: #58585b;
$lighter-grey: #f4f4f4;

$light-grayish-blue: #e2eaf0; // used in sections backgrounds
$lighter-grayish-blue: #eaeeec; // used in search result elements BG
$moderate-blue: #4e88a4; // main moderate blue color 
$light-blue: #447c93;
$light-moderate-blue : #4e859e; //  
$pale-moderate-blue: #417b97; // used as social boxes pale moderate blue color
$dark-moderate-blue: #51859a; //
$light-blue: #4e88a4;  // home main slider social background colors
$dark-blue: #417b97;   // home main slider social background colors
$pattens-blue: #e0e9ee;   // business slider background colors 
 

// Arrows >>>
$grey-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjcwLjAwNHB4IiBoZWlnaHQ9IjU3Ni4wMDZweCIgdmlld0JveD0iMzY1Ljk5NiAxMDMuOTk3IDI3MC4wMDQgNTc2LjAwNiINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzNjUuOTk2IDEwMy45OTcgMjcwLjAwNCA1NzYuMDA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiM1NjU2NUIiIGQ9Ik02MzAuMDMyLDM3NS41NzZsLTIxOC43MDQtMjYyLjM2Yy05LjA0OC0xMC44NzItMjUuMjMyLTEyLjM0NC0zNi4wOTYtMy4yNjQNCgkJYy0xMC44ODksOS4wOTYtMTIuMzYsMjUuMjE2LTMuMjk3LDM2LjA5Nkw1NzYuOTY4LDM5MkwzNzEuOTM2LDYzNy45MzZjLTkuMDQ4LDEwLjkwNC03LjU3NSwyNy4wMzIsMy4yOTcsMzYuMTEyDQoJCWMxMC44NTUsOS4wOCwyNy4wNDgsNy42MDgsMzYuMDk2LTMuMjY0TDYzMC4wMzIsNDA4LjQ0QzYzNC4wMTYsNDAzLjY3Miw2MzYsMzk3LjgzMiw2MzYsMzkyDQoJCUM2MzYsMzg2LjE2LDYzNC4wMTYsMzgwLjMyOCw2MzAuMDMyLDM3NS41NzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==';
$white-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjcwLjAwNHB4IiBoZWlnaHQ9IjU3Ni4wMDZweCIgdmlld0JveD0iMTkzLjk5NiAxMzMuOTk3IDI3MC4wMDQgNTc2LjAwNiINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxOTMuOTk2IDEzMy45OTcgMjcwLjAwNCA1NzYuMDA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00NTguMDMyLDQwNS41NzZsLTIxOC43MDQtMjYyLjM2Yy05LjA0OC0xMC44NzItMjUuMjMyLTEyLjM0NC0zNi4wOTYtMy4yNjQNCgkJYy0xMC44ODksOS4wOTYtMTIuMzYsMjUuMjE2LTMuMjk3LDM2LjA5Nkw0MDQuOTY4LDQyMkwxOTkuOTM2LDY2Ny45MzZjLTkuMDQ4LDEwLjkwNC03LjU3NSwyNy4wMzIsMy4yOTcsMzYuMTEyDQoJCWMxMC44NTUsOS4wOCwyNy4wNDgsNy42MDgsMzYuMDk2LTMuMjY0TDQ1OC4wMzIsNDM4LjQ0QzQ2Mi4wMTYsNDMzLjY3Miw0NjQsNDI3LjgzMiw0NjQsNDIyDQoJCUM0NjQsNDE2LjE2LDQ2Mi4wMTYsNDEwLjMyOCw0NTguMDMyLDQwNS41NzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==';
$lightGrey-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjcwLjAwNHB4IiBoZWlnaHQ9IjU3Ni4wMDZweCIgdmlld0JveD0iMzY1Ljk5NiAxMDMuOTk3IDI3MC4wMDQgNTc2LjAwNiINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzNjUuOTk2IDEwMy45OTcgMjcwLjAwNCA1NzYuMDA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiM3MTcxNzMiIGQ9Ik02MzAuMDMyLDM3NS41NzZsLTIxOC43MDQtMjYyLjM2Yy05LjA0OC0xMC44NzItMjUuMjMyLTEyLjM0NC0zNi4wOTYtMy4yNjQNCgkJYy0xMC44ODksOS4wOTYtMTIuMzYsMjUuMjE2LTMuMjk3LDM2LjA5Nkw1NzYuOTY4LDM5MkwzNzEuOTM2LDYzNy45MzZjLTkuMDQ4LDEwLjkwNC03LjU3NSwyNy4wMzIsMy4yOTcsMzYuMTEyDQoJCWMxMC44NTUsOS4wOCwyNy4wNDgsNy42MDgsMzYuMDk2LTMuMjY0TDYzMC4wMzIsNDA4LjQ0QzYzNC4wMTYsNDAzLjY3Miw2MzYsMzk3LjgzMiw2MzYsMzkyDQoJCUM2MzYsMzg2LjE2LDYzNC4wMTYsMzgwLjMyOCw2MzAuMDMyLDM3NS41NzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==';
$blue-arrow: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjcwLjAwNHB4IiBoZWlnaHQ9IjU3Ni4wMDZweCIgdmlld0JveD0iMzY1Ljk5NiAxMDMuOTk3IDI3MC4wMDQgNTc2LjAwNiINCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAzNjUuOTk2IDEwMy45OTcgMjcwLjAwNCA1NzYuMDA2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiM1QjkxQUIiIGQ9Ik02MzAuMDMyLDM3NS41NzZsLTIxOC43MDQtMjYyLjM2Yy05LjA0OC0xMC44NzItMjUuMjMyLTEyLjM0NC0zNi4wOTYtMy4yNjQNCgkJYy0xMC44ODksOS4wOTYtMTIuMzYsMjUuMjE2LTMuMjk3LDM2LjA5Nkw1NzYuOTY4LDM5MkwzNzEuOTM2LDYzNy45MzZjLTkuMDQ4LDEwLjkwNC03LjU3NSwyNy4wMzIsMy4yOTcsMzYuMTEyDQoJCWMxMC44NTUsOS4wOCwyNy4wNDgsNy42MDgsMzYuMDk2LTMuMjY0TDYzMC4wMzIsNDA4LjQ0QzYzNC4wMTYsNDAzLjY3Miw2MzYsMzk3LjgzMiw2MzYsMzkyDQoJCUM2MzYsMzg2LjE2LDYzNC4wMTYsMzgwLjMyOCw2MzAuMDMyLDM3NS41NzZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==';

// Form Icon
$form-icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMTYgMTIiPgogIDxpbWFnZSB4bGluazpocmVmPSJkYXRhOmltZy9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFCQUFBQUFNQ0FNQUFBQmNPYzJ6QUFBQUJHZEJUVUVBQUxHUEMveGhCUUFBQUNCalNGSk5BQUI2SmdBQWdJUUFBUG9BQUFDQTZBQUFkVEFBQU9wZ0FBQTZtQUFBRjNDY3VsRThBQUFBU0ZCTVZFVUFBQUJVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo5VWlKOVVpSjlVaUo4QUFBQW9DbGgzQUFBQUZuUlNUbE1BL3ZyODl1MzRzREN5b05yUXUyREV2M0RLNWNEYkl5TWl4UUFBQUFGaVMwZEVBSWdGSFVnQUFBQUpjRWhaY3dBQUxpTUFBQzRqQVhpbFAzWUFBQUJKU1VSQlZBalhaY3RCRW9BZ0RFUFJVRUZVUkZIVSt4L1ZyREk2Zll1LzZLUUlSb01TRUorZjZBK1dhRlFNZWFKWnlWaEEzNVNWa2xMOG9tNjBLeFh0b0ZOcC9xVmZkQ3Y5QmZVRUI3MkFiTWNtQUFBQUFFbEZUa1N1UW1DQyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEyIi8+Cjwvc3ZnPgo=';