.video-container {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Video Section
.video-wide {
    position: relative;
    // background-attachment: fixed;
    // background-size: cover;
    // background-position: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: auto;
    .vwide-video {
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
    .vwide-cover {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        // overflow: hidden;
        @media (max-width: $screen-sm-max) {
            position: relative;
            height: 400px;
            overflow-x: visible;
        }
        @media (max-width: $screen-xs-max) {
            height: 300px;
        }
        .vwide-cover-img {
            min-width: 100%;
            // max-width: 100%;
            height: 100%;
            @media (max-width: $screen-sm-max) {
                // max-width: 100%;
                position: relative;
                left: 50%;
                overflow-x: hidden;
                @include transform(translateX(-50%));
                .rtl & {
                    left: auto;
                    right: 50%;
                    overflow-x: hidden;
                    @include transform(translateX(50%))
                }
            }
            @media (max-width: $screen-xs-max) {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
            }
        }
        .vwide-overlay {
            position: absolute;
            top: 50%;
            // width: 100%;
            @include transform(translateY(-50%));
            margin: auto;
            display: table;
            //   margin-left: 27px;
            //   .rtl & {
            //     // margin-left: 0;
            //     // margin-right: 27px;
            //   }
            .ie & {
                @media (min-width: $screen-sm-min) {
                    display: block;
                }
            }
            @media (max-width: $screen-xs-max) {
                margin: inherit;
            }
            .playIcon {
                margin-right: 22px;
                margin-top: 7px;
                vertical-align: middle;
                float: left;
                display: table-cell;
                .rtl & {
                    // @include transform(rotateY(180deg));
                    float: right;
                    margin-right: 0;
                    margin-left: 22px;
                    margin-top: 12px;
                }
                .safari & {
                    float: none;
                    margin-top: -8px;
                }
                .safari.rtl & {
                    float: none;
                    margin-top: -8px;
                }
                @media (max-width: $screen-xs-max) {
                    margin-top: -3px;
                    margin-right: 0;
                    position: absolute;
                    top: 8px;
                    .rtl & {
                        margin-top: 3px;
                        margin-left: 0;
                    }
                    .safari & {
                        margin-right: 0;
                        position: absolute;
                        top: 8px;
                    }
                }
            }
            // @media (max-width: $screen-xs-max){
            //     padding:0 12px;
            //     width: 50%;
            //     margin: auto;
            //     position: relative;
            // //     text-align: center;
            // }
            .video-options {
                margin-top: 4px;
                display: table-cell;
                width: 100%;
                .firefox & {
                    margin-top: 0px;
                    display: block;
                }
                .ie & {
                    display: block;
                }
                @media (max-width: $screen-xs-max) {
                    padding-left: 60px;
                    .rtl & {
                        padding-left: 0px;
                        padding-right: 60px;
                    }
                }
                @media (max-width: $screen-sm-max) {
                    .ie & {
                        .dropdown,
                        .dropup {
                            position: static;
                        }
                    }
                }
            }
            .watch-full {
                // @extend .font-md;
                @include font-size($font-md, $font-md-resp);
                color: $white;
                margin-top: 10px;
                a {
                    vertical-align: middle;
                    &:hover,
                    &:focus,
                    &:active {
                        color: $white;
                    }
                    // .ja_lang & {
                    //     font-weight: $weight-light;
                    // }
                }
                @media (max-width: $screen-sm-max) {
                    margin-top: 13px;
                    text-align: left;
                    .rtl & {
                        margin-top: 16px;
                        text-align: right;
                    }
                }
            }
            .languages-dropdown {
                font-size: $font-sm;
                display: inline-block;
                margin-top: 20px;
                .dropdown-toggle {
                    cursor: pointer;
                    color: transparentize($white, 0.3);
                    width: auto;
                    display: inline-block;
                    .firefox & {
                        margin-left: 66px;
                        @media (max-width: $screen-sm-max) {
                            margin-left: 0px;
                        }
                        @media (max-width: $screen-xs-max) {
                            margin-left: 0;
                        }
                    }
                    .firefox.rtl & {
                        margin-left: 0;
                        margin-right: 66px;
                        @media (max-width: $screen-sm-max) {
                            margin-right: 0;
                        }
                        @media (max-width: $screen-xs-max) {
                            margin-right: 0;
                        }
                    }
                    .ie & {
                        padding-left: 65px;
                    }
                    .ie.rtl & {
                        padding-right: 65px;
                        padding-left: 0;
                    }
                }
                .caret {
                    background: url($white-arrow) no-repeat;
                    width: 5px;
                    height: 9px;
                    border: none;
                    margin-left: 15px;
                    background-size: auto 100%;
                    @include transform-origin(center 3px);
                    @include opacity(0.7);
                    .rtl & {
                        margin-left: 0;
                        margin-right: 15px;
                        margin-top: 5px;
                        @include transform(rotate(180deg));
                    }
                }
                &.open {
                    .dropdown-toggle {
                        color: transparentize($white, 0);
                    }
                    .caret {
                        @include opacity(1);
                    }
                    @media (max-width: $screen-sm-max) {
                        .caret {
                            @include transform(rotate(90deg));
                        }
                    }
                    @media (min-width: $screen-md-min) {
                        .caret {
                            margin-left: 25px;
                            .rtl & {
                                margin-left: 0;
                                margin-right: 25px;
                            }
                        }
                    }
                    // .caret.rotate-responsive {
                    //   @media (max-width: $screen-xs-max) {
                    //     @include transform(rotate(90deg));
                    //   }
                    // }
                }
                @media (min-width: $screen-md-min) {
                    display: block;
                }
                .dropdown-menu {
                    width: 197px;
                    max-height: 286px;
                    margin-top: 20px;
                    border-radius: 0;
                    border: none;
                    box-shadow: none;
                    padding: 0px 40px;
                    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.06);
                    @media (max-width: $screen-sm-max) {
                        max-height: none;
                    }
                    .rtl & {
                        right: 0;
                    }
                    li {
                        padding: 0;
                        margin: 30px 0px;
                        font-family: "karbon";
                        text-align: center;
                        &.luma {
                            font-family: "luma";
                        }
                        @include transition(0.3s);
                        .rtl & {
                            direction: rtl;
                            text-align: right;
                        }
                        &:not(.active) {
                            opacity: 0.5;
                        }
                        &:hover {
                            opacity: 1;
                        }
                        a {
                            color: $moderate-blue;
                            font-weight: $weight-bold;
                            font-size: $font-xs;
                            background-color: transparent;
                            text-align: center;
                        }
                    }
                    @media (min-width: $screen-md-min) {
                        width: auto;
                        right: 0;
                        left: 180px;
                        top: -2px;
                        margin: 0;
                        padding: 0;
                        background-color: transparent;
                        box-shadow: none;
                        .rtl & {
                            top: -2px;
                            right: 180px;
                            left: 0;
                        }
                        .firefox & {
                            left: 230px;
                        }
                        .firefox.rtl & {
                            left: 0;
                            right: 250px;
                        }
                        .ie & {
                            left: 235px;
                            right: auto;
                            // top: 66px;
                        }
                        .ie.rtl & {
                            right: 235px;
                            left: auto;
                        }
                        .ch_lang.firefox & {
                            left: 270px;
                        }
                        li {
                            display: inline-block;
                            margin: 2px 58px 15px 0;
                            .rtl & {
                                float: right;
                                margin: 2px 0 15px 58px;
                            }
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
            .curr-lang {
                // @extend .font-xs;
                @include font-size($font-xs, $font-xs-resp);
                font-weight: $weight-bold;
                color: $white;
                margin-left: 25px;
                display: inline-block;
                .rtl & {
                    margin-right: 25px;
                    margin-left: 0;
                }
            }
        }
    }
    .embed-responsive-16by9 {
        padding-bottom: 40%;
        iframe {
            height: 152%;
            // margin-top: -19%;
            top: 50%;
            transform: translateY(-50%);
        }
        // .Vid.scrolled-video {
        //   transform: translateY(-20%);
        // }
    }
    .embed-responsive .embed-responsive-item,
    .embed-responsive embed,
    .embed-responsive iframe,
    .embed-responsive object,
    .embed-responsive video {
        top: -22%!important;
    }
    .loader {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 11;
        width: 100%;
        background-color: rgba(0, 0, 0, .6);
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -20px;
            margin-top: -20px;
            @include animation(rotate 0.8s linear infinite);
            @include keyframes(rotate) {
                100% {
                    @include transform(rotate(360deg));
                }
            }
        }
    }
}

// Video Modal
#videoModal {
    .modal-dialog {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 95%;
        max-width: 1000px;
        .close {
            opacity: 1;
            margin: 0;
            line-height: 0;
            top: -18px;
            right: 18px;
            position: absolute;
            outline: 0;
            .safari & {
                line-height: 0.5;
                right: 12px;
            }
            span {
                color: rgb(177, 177, 177);
                font-size: 45px;
                font-weight: 100;
                text-shadow: none;
                outline: 0;
                // .ch_lang & {
                //     font-weight: $weight-thin;
                // }
            }
            .rtl & {
                right: auto;
                left: 18px;
            }
        }
        .modal-content {
            position: absolute;
            top: 50%;
            left: 0px;
            right: 0px;
            margin: auto;
            @include transform(translateY(-50%));
            background-color: transparent;
            border: none;
            box-shadow: none;
            .embed-responsive-16by9 {
                // padding-bottom: 52.25%;
                // margin: 0 30px;
            }
        }
    }
    // #customVideoModal1,
    // #customVideoModal2 {
    //   #player {
    //     .video-wrapper {
    //       height: auto!important;
    //     }
    //   }
    // }
    .player .video-wrapper {
        background: transparent!important;
    }
}

.embed-responsive {
    video {
        height: auto;
        .scrolled-video {
            top: -25%;
        }
    }
}

.modal-backdrop.in {
    @include opacity(0.8);
}

.rotate-resp {
    transform: rotate(90deg);
}