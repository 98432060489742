.factsheets {
    .listing-container {
        position: relative;
        padding: 24px 0 54px;
    }
    div.flex-item {
        @media(min-width: $screen-md-min) {
            min-height: 1200px;
        }
    }
    .news-block {
        padding: 30px 0px 60px;
        .news-logo {
            .media-object {
                top: 0;
                @media (max-width: $screen-xs-max) {
                    position: absolute;
                    // top: 20px;
                }
            }
        }
        .news-body {
            @media (max-width: $screen-xs-max) {
                padding-top: 0;
            }
            .news-date {
                display: block;
                position: static;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .url {
                display: block;
            }
            .block-options {
                bottom: -30px;
                @media (max-width: $screen-sm-max) {
                    display: block;
                }
                .svg-wraper {
                    &:nth-child(1) {
                        border-right: 2px solid #dcdcdc;
                        border-left: none;
                        .rtl & {
                            border-left: 2px solid #dcdcdc;
                            border-right: none;
                        }
                        @media (max-width: $screen-sm-max) {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        border-right: none;
                        border-left: none;
                        @media (max-width: $screen-sm-max) {
                            padding-left: 4px;
                            .rtl & {
                                padding-right: 0;
                                padding-left: 30px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        height: 31px;
                        vertical-align: bottom;
                        border-right: none;
                        border-left: 2px solid #dcdcdc;
                        .rtl & {
                            border-left: none;
                            border-right: 2px solid #dcdcdc;
                        }
                    }
                    .social_share {
                        margin-top: 4px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .aside-content {
        padding-top: 30px;
        .for-inq {
            .data-row {
                margin: 0;
                .value {
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: $dark-smokey-grey;
                    a {
                        color: $light-moderate-blue;
                    }
                }
            }
        }
    }
}