.people-bio {
    padding: 80px 0;
    @media(max-width: $screen-xs-max) {
        padding: 80px 25px;
    }
    .bio-img {
        img {
            // width: 100%;
            @media(max-width: $screen-sm-max) {
                margin-bottom: 40px;
            }
        }
        svg {
            margin-top: 25px;
            margin-left: 10px;
            .rtl & {
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }
    .bio-text {
        .bio-header {
            margin-bottom: 30px;
            p {
                margin: 0;
            }
        }
        .person-name {
            margin-top: 0;
            color: $dark-smokey-grey;
            font-size: 32px;
            font-weight: $weight-medium;
        }
        .box-paragraph {
            background-color: $light-grayish-blue;
            padding: 30px;
            font-size: 22px;
            color: $dark-smokey-grey;
            margin: 40px 0;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            margin: 0 0 20px 0;
        }
        h4 {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
        img {
            // display: block;
            max-width: 100%;
            height: 100%;
            // width: 100%;
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        figure {
            &.alignleft {
                float: left;
                padding-right: 15px;
            }
            &.alignright {
                float: right;
                padding-left: 15px;
            }
            &.aligncenter {
                display: block;
                margin: auto;
            }
            &.alignnone {
                float: none;
            }
        }
        ol {
            padding: 0;
            margin: 20px 0;
            counter-reset: olcounter;
            li {
                // list-style-type: lower-roman;
                list-style-position: inside;
                list-style: none; //
                color: $dark-smokey-grey;
                line-height: 1.2;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:before {
                    content: counter(olcounter, lower-roman)".";
                    counter-increment: olcounter;
                    display: block;
                    position: absolute;
                    left: 0;
                    .rtl & {
                        margin-left: 20px;
                        margin-right: 0;
                        left: auto;
                        right: 0;
                    }
                }
                // &:before {
                //  content: "";
                //  width: 10px;
                //  display: inline-block;
                // }
                ol {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    li {
                        // list-style-type: lower-alpha;
                    }
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
            &[type=a] {
                li {
                    &::before {
                        content: counter(olcounter, lower-alpha)".";
                    }
                }
            }
        }
        ul {
            margin: 20px 0;
            padding: 0px;
            li {
                background: url(../images/bullet-icon.png) no-repeat 0 8px;
                list-style: none;
                margin-bottom: 3px;
                color: $dark-smokey-grey;
                line-height: 1.5;
                @include font-size($font-sm, $font-sm-resp);
                font-weight: $weight-regular;
                display: block;
                padding: 0 25px;
                width: 100%;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                .rtl & {
                    background-position: 100% 8px;
                }
                ul {
                    padding: 0 0 0 15px;
                    margin: 20px 0;
                    .rtl & {
                        padding: 0 15px 0 0;
                    }
                }
            }
        }
        a {
            font-weight: $weight-medium;
            color: $dark-moderate-blue;
        }
        .p1-style {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    table {
        border: none;
        tbody {
            border-top: none;
            tr:first-child {
                border-bottom: none;
            }
        }
    }
}